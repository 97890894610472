
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Space, Table, Tag, Typography } from "antd";

import { getAllStory } from "../Requests";
import { getAccessAuthrorization } from "src/components/access";
import StoryViewModal from "../Modal/ShowStoryDetails";
import ManageStoryModal from "../Modal/ManageStory";


interface DataType {
  id: number;
  name: any;
}
// type DataIndex = keyof DataType;
const StoryList: React.FC = () => {

  let accessAuth = getAccessAuthrorization("story");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["story"], () => getAllStory(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_: any, text: any) => (
        <Typography.Text strong>{text.title}</Typography.Text>
      ),
    },
    {
      title: "Parent",
      dataIndex: "storyId",
      key: "storyId",
      render: (_: any, text: any) =>
        text?.storyId ? (
          <Typography.Text>{text?.storyId.title}</Typography.Text>
        ) : (
          <Typography.Text
            style={{ fontWeight: "lighter" }}
            type="warning"
            code
          >
            No Parent
          </Typography.Text>
        ),
    },
    {
      title: "Work Title/Type",
      dataIndex: "workId",
      key: "workId",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Typography.Text
              style={{
                fontWeight: "bold",
              }}
            >
              {i?.workId?.title}
            </Typography.Text>
            <Typography.Text
              type="danger"
              code
              style={{ width: 125, overflow: "auto", height: 25 }}
            >
              {i?.workId?.workType}
            </Typography.Text>
          </Space.Compact>
        </Space>
      ),
    },

    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    // },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <StoryViewModal id={id} />
          {/* <ManageStoryModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <ManageStoryModal id={id} />}
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default StoryList;
