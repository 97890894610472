import * as React from "react";
import {



  Card,
  Tabs,
} from "antd";
import StoryListByWorkId, {
  StoryTaskList,
} from "../Component/StoryListByWorkId";
import { useQuery } from "react-query";
import { getWorkByIdDetail } from "../Requests";
import parse from "html-react-parser";



const ShowWorkDetailsForm: React.FC<{ id: string }> = (props) => {
  const { data: res } = useQuery(
    ["workDetails", props.id],
    async () => getWorkByIdDetail(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );
  return (
    <>
      <Tabs
        centered
        // tabPosition="left"
        defaultActiveKey="2"
        // type="card"
        items={[
          {
            key: "1",
            label: <>Description/Details</>,
            children: (
              <Card style={{ width: "100%" }}>
                {res[0].description && parse(res[0].description)}
              </Card>
            ),
          },
          {
            key: "2",
            label: <>Tasks List</>,
            children: (
              <Card style={{ width: "100%" }}>
                <StoryTaskList data={res[0].tasks} />
              </Card>
            ),
          },
          {
            key: "3",
            label: <>Story List</>,
            children: (
              <Card style={{ width: "100%" }}>
                {" "}
                {res[0].story.length > 0 && (
                  <StoryListByWorkId data={res[0].story} />
                )}{" "}
              </Card>
            ),
          },
        ]}
      />
    </>
  );
};

export default ShowWorkDetailsForm;
