import * as React from "react";
import {
  Form,
  Button,
  message,
  Input,
  Row,
  Typography,
  Space,
  Select,
  Avatar,
  Tag,
  DatePicker,
  Col,
  Divider,
  List,
  Upload,
  Tooltip,
  UploadFile,
} from "antd";
import imLogo from "src/assets/img/imlogo.png";

import { request } from "../../../utils/services";
import { useMutation, useQueries } from "react-query";
import {
  InfoCircleOutlined,
  BankOutlined,
  HomeOutlined,
  MobileOutlined,
  GlobalOutlined,
  MailOutlined,
  AuditOutlined,
  UserOutlined,
  LoadingOutlined,
  PlusOutlined,
  SlackOutlined,
  CalendarOutlined,
  TagsOutlined,
  MinusCircleFilled,
  UsergroupAddOutlined,
  UploadOutlined,
  FireOutlined,
  FireFilled,
} from "@ant-design/icons";
import { queryClient } from "../../..";
import { ManageTaskModalContext } from "../Modal/ManageTask";
import { useQuery } from "react-query";
import { getTaskById, getTaskTableById } from "../Requests";

import LocalStorageService from "../../../utils/local-storage";
import { getAllActivePersonnel } from "src/modules/HR/Personnel/Requests";
import { getAllActiveTaskType } from "src/modules/TaskType/Requests";
import { getAllActiveWork } from "src/modules/Work/Requests";
import {
  deleteFileToAWS,
  s3Path,
  uploadFileToAWS,
} from "src/utils/s3Config/s3upload";
import dayjs from "dayjs";
import CreateTaskTypeModal from "src/modules/TaskType/Modal/CreateTaskType";
import { Editor } from "@tinymce/tinymce-react";
import { tinyMCE } from "src/utils/keys";
import { getAllLead } from "src/modules/Leads/Requests";
import { getReplyByTask } from "src/modules/Reply/Requests";
import ReactTimeAgo from "react-time-ago";
import parse from "html-react-parser";
import ManageReplyForm from "src/modules/Reply/Form/ManageReplyForm";
import { FireFill } from "antd-mobile-icons";

const ManageTaskTableForm: React.FC<{ id: string }> = (props) => {
  const [user, setUser] = React.useState(LocalStorageService.getAccount());

  const modalContext = React.useContext(ManageTaskModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const inputRefs = React.useRef<(HTMLInputElement | any)[]>([]); // Ref array for inputs
  // const [remarks, setRemarks] = React.useState<string>();
  const [isReply, setIsReply] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState<string[]>([]);

  const ManageTaskTableMutation = useMutation(
    (values: any) => request("admin.task.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("task");
        queryClient.invalidateQueries("taskByPersonnel");
      },
    }
  );
  const { data: task } = useQuery(
    ["task", props.id],
    async () => getTaskTableById(props.id),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      onSuccess: (data) => {
        setImageUrl(data?.attachment || []);
      },
    }
  );

  // console.log(task)

  const { data: reply } = useQuery(
    ["reply", props.id],
    async () => getReplyByTask(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );
  // console.log(reply)
  const results = useQueries([
    {
      queryKey: ["personnel"],
      queryFn: getAllActivePersonnel,
    },
    {
      queryKey: ["taskType"],
      queryFn: getAllActiveTaskType,
    },
    {
      queryKey: ["work"],
      queryFn: getAllActiveWork,
    },
    {
      queryKey: ["lead"],
      queryFn: getAllLead,
    },
  ]);
  // Access results
  const [personnel, tag, work, lead] = results;

  const onEnter = async (values: any) => {
    values.updatedBy = LocalStorageService.getAccount().id;
    // values.attachment = imageUrl;
    // values.remarks =remarks

    ManageTaskTableMutation.mutateAsync(values)
      .then(() => {
        // modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        // setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const handleEditorChange = (content: any, editor: any) => {
    // console.log("Content was updated:", content);
    content && onEnter({ ...task, remarks: content });
  };

  const [form] = Form.useForm();
  const [fileList, setFileList] = React.useState<UploadFile[]>(
    task.attachment &&
      task.attachment.map((ele: any, i: any) => {
        return {
          uid: i,
          name: ele,
          status: "done",
          url: s3Path + ele,
        };
      })
  );
  // console.log(fileList)

  return (
    <Space direction="vertical">
  
        <Input
          placeholder="New Task"
          size="middle"
          style={{
            textDecoration: task?.isChecked ? "line-through" : "none",
            fontSize: 24,
            fontWeight: 600,
            borderRadius: 5,
            width: "30vw",
          }}
          variant="borderless"
          defaultValue={task.title}
          // addonBefore={task.seq}
          // ref={(ref) => (inputRefs.current[task.title] = ref)} // Assign ref to the input
          onBlur={(e) => {
            e.target.value && onEnter({ ...task, title: e.target.value });
          }}
        />
       

        {/* <Button type="primary" onClick={showDrawer}>
Open
</Button> */}

    
      <br />
      <Space size={10}>
        <Space direction="horizontal">
          <Typography.Text>
            <UserOutlined />
            Assignee{" "}
          </Typography.Text>
          <Select
            placeholder="Select Assignee"
            variant="borderless"
            defaultValue={task.assignedPersonId}
            //   virtual={false}
            suffixIcon={<></>}
            //   mode="multiple"

            onChange={(value) => {
              value && onEnter({ ...task, assignedPersonId: value });
              // You can also update a state or trigger an action with the selected value
            }}
            //   onSelect={(e)=>{console.log(e)}}
            size="large"
          >
            {personnel.data &&
              personnel.data?.map((item: any, i: number) => {
                return (
                  <Select.Option value={item?.id} key={i}>
                    <Tag
                      bordered={false}
                      style={{
                        backgroundColor: "lightgrey",
                        fontSize: 14,
                        borderRadius: 10,
                      }}
                    >
                      <Avatar
                        size={"small"}
                        src={
                          <img
                            src={
                              item?.imageUrl ? s3Path + item?.imageUrl : imLogo
                            }
                            // alt={item?.personName}
                          />
                        }
                      />{" "}
                      {item?.partyId?.personName}
                    </Tag>
                  </Select.Option>
                );
              })}
          </Select>
        </Space>
      </Space>
      <Space direction="horizontal">
        <Typography.Text>
          <SlackOutlined />
          Status{" "}
        </Typography.Text>
        <Select
          defaultValue={task?.status}
          placeholder={"Select Status"}
          variant="borderless"
          suffixIcon={<></>}
          onChange={(value) => {
            value && onEnter({ ...task, status: value });
            // You can also update a state or trigger an action with the selected value
          }}
          options={[
            {
              label: (
                <Tag
                  bordered={false}
                  style={{
                    backgroundColor: "lightgrey",
                    fontSize: 14,
                    borderRadius: 10,
                  }}
                  //   color={"volcano"}
                >
                  To Do
                </Tag>
              ),
              value: "To Do",
            },
            {
              label: (
                <Tag
                  bordered={false}
                  color={"blue"}
                  style={{ fontSize: 14, borderRadius: 10 }}
                >
                  In Progress
                </Tag>
              ),
              value: "In Progress",
            },
            {
              label: (
                <Tag
                  bordered={false}
                  // color={"yellow"}
                  style={{
                    fontSize: 14,
                    borderRadius: 10,
                    backgroundColor: "lightyellow",
                  }}
                >
                  On Hold
                </Tag>
              ),
              value: "On Hold",
            },
            {
              label: (
                <Tag
                  bordered={false}
                  color={"orange"}
                  style={{ fontSize: 14, borderRadius: 10 }}
                >
                  In Review
                </Tag>
              ),
              value: "In Review",
            },
            {
              label: (
                <Tag
                  bordered={false}
                  // color={"green"}
                  style={{
                    fontSize: 14,
                    borderRadius: 10,
                    backgroundColor: "lightgreen",
                  }}
                >
                  Done
                </Tag>
              ),
              value: "Done",
            },
          ]}
        />
      </Space>

      <Space>
      <Typography.Text>
          <FireOutlined />{" "}
          Priority{" "}
        </Typography.Text>
      <Select
          defaultValue={task?.priority}
          placeholder={"Select priority"}
          variant="borderless"
          suffixIcon={false}
          style={{width:150}}
          onChange={(value) => {
            value && onEnter({ ...task, priority: value });
            // You can also update a state or trigger an action with the selected value
          }}
          //   labelRender={(props:any)=>{return(
          //     props.label[0]
          //   )}}
          options={[
            {
              label: (
                <Tag  color={"red-inverse"}>
                  Urgent
                </Tag>
              ),
              value: "Urgent",
            },
            {
              label: (
                <Tag color={"orange"}>
                  High
                </Tag>
              ),
              value: "High",
            },
            {
              label: (
                <Tag  color={"purple"}>
                  Medium
                </Tag>
              ),
              value: "Medium",
            },
            {
              label: (
                <Tag  color={"cyan"}>
                  Low
                </Tag>
              ),
              value: "Low",
            },
            {
              label: (
                <Tag color={"lime"}>
                  Trivial
                </Tag>
              ),
              value: "Trivial",
            },
          ]}
        />
      </Space>
      <Space>
        <Typography.Text>
          <CalendarOutlined /> Due{" "}
        </Typography.Text>
        <DatePicker
          variant="filled"
          onChange={(value) => {
            console.log(value);
            value && onEnter({ ...task, dueDate: value });
            // You can also update a state or trigger an action with the selected value
          }}
          defaultValue={dayjs(task.dueDate)}
          format={"MMM DD, YYYY"}
        />
      </Space>
      <Space>
        <Typography.Text>
          <TagsOutlined rotate={270} />
          Tag{" "}
        </Typography.Text>
        <Space size={10}>
          <Select
            placeholder="Select tag"
            variant="borderless"
            defaultValue={task.type}
            //   virtual={false}
            dropdownRender={(menu) => (
              <>
                <Row justify={"end"}>
                  <CreateTaskTypeModal />
                </Row>
                {menu}
              </>
            )}
            suffixIcon={<></>}
            mode="multiple"
            onChange={(value) => {
              value && onEnter({ ...task, type: value });
            }}
            size="large"
            style={{ width: "24vh" }}
          >
            {tag.data &&
              tag.data?.map((item: any, i: number) => {
                return (
                  <Select.Option value={item?.id} key={i}>
                    <Tag
                      color={item.color}
                      bordered={false}
                      style={{ borderRadius: 10, fontWeight: "bold" }}
                    >
                      {" "}
                      {item?.title}
                    </Tag>
                  </Select.Option>
                );
              })}
          </Select>
          {/* <Avatar
                  src={
                    <img
                      src={
                        i?.assignedPersonId?.partyId?.imageUrl
                          ? s3Path + i?.assignedPersonId?.partyId?.imageUrl
                          : imLogo
                      }
                      alt={i?.assignedPersonId?.partyId?.personName[0]}
                    />
                  }
                />
                <Tag>{i?.assignedPersonId?.partyId?.personName}</Tag> */}
        </Space>
      </Space>
      <Space>
        <Typography.Text>
          <MinusCircleFilled /> Work{" "}
        </Typography.Text>
        <Space size={10}>
          <Select
            placeholder="Select Work"
            variant="borderless"
            defaultValue={task.workId}
            //   virtual={false}
            suffixIcon={<></>}
            //   mode="multiple"

            onChange={(value) => {
              value && onEnter({ ...task, workId: value });
              // You can also update a state or trigger an action with the selected value
            }}
            //   onSelect={(e)=>{console.log(e)}}
            size="large"

            // style={{overflow:"visible"}}
          >
            {work.data &&
              work.data?.map((item: any, i: number) => {
                return (
                  <Select.Option value={item?.id} key={i}>
                    <Tag
                      bordered={false}
                      style={{
                        backgroundColor: "wheat",
                        fontSize: 14,
                        borderRadius: 10,
                      }}
                    >
                      {/* <Avatar 
                  size={"small"}
                  src={
                    <img
                      src={
                        item?.imageUrl
                          ? s3Path + item?.imageUrl
                          : imLogo
                      }
                      // alt={item?.personName}
                    />
                  }
                />  */}
                      {item?.title}
                    </Tag>
                  </Select.Option>
                );
              })}
          </Select>
        </Space>
      </Space>
      <Space>
        <Typography.Text>
          <UsergroupAddOutlined /> Lead{" "}
        </Typography.Text>

        <Select
          placeholder="Select Lead"
          variant="borderless"
          defaultValue={task.leadId}
          //   virtual={false}
          suffixIcon={<></>}
          //   mode="multiple"

          onChange={(value) => {
            value && onEnter({ ...task, leadId: value });
            // You can also update a state or trigger an action with the selected value
          }}
          //   onSelect={(e)=>{console.log(e)}}
          size="large"

          // style={{width:"24vh"}}
        >
          {lead.data &&
            lead.data?.map((item: any, i: number) => {
              return (
                <Select.Option value={item?.id} key={i}>
                  <Tag
                    bordered={false}
                    style={{
                      backgroundColor: "wheat",
                      fontSize: 14,
                      borderRadius: 10,
                    }}
                  >
                    {/* <Avatar
            size={"small"}
            src={
              <img
                src={
                  item?.imageUrl
                    ? s3Path + item?.imageUrl
                    : imLogo
                }
                // alt={item?.personName}
              />
            }
          />  */}
                    {item?.title}
                  </Tag>
                </Select.Option>
              );
            })}
        </Select>
      </Space>
      <Upload
        accept="application/pdf,application/msword, image/png, image/jpeg, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        maxCount={4}
        multiple
        listType="picture-card"
        fileList={fileList}
        customRequest={async (e) => {
          const response: any = await uploadFileToAWS(e.file, "task").catch(
            (err: any) => message.error(err?.toString())
          );
          setImageUrl([...imageUrl, response.key]);
          setFileList([
            ...fileList,
            {
              uid: response.key,
              name: response.key,
              status: "done",
              url: s3Path + response.key,
            },
          ]);
          onEnter({ ...task, attachment: [...imageUrl, response.key] });
        }}
        beforeUpload={(fileList) => {
          console.log("FILE", fileList.size);
          
          return fileList.size / 1024 / 1024 <= 2 ? true : false;
        }}
        onRemove={(e) => {
          // console.log(e);
          if (e?.name) {
            deleteFileToAWS(e.name)
              .then((value) => message.success(value))
              .catch((e) => message.error(e));
            // var file = imageUrl.filter((item) => item !== e.url);
            let tempList = imageUrl;
            const index = imageUrl.indexOf(e.name);

            tempList.splice(index, 1);
            // console.log(tempList,index)
            setFileList(
              tempList.map((ele: any, i: any) => {
                return {
                  uid: i,
                  name: ele,
                  status: "done",
                  url: s3Path + ele,
                };
              })
            );
            //  setImageUrl(imageUrl)

            // // console.log(imageUrl);
            // setImageUrl(imageUrl);
            onEnter({ ...task, attachment: tempList });
          }
        }}
      >
        {imageUrl.length < 4 && <UploadOutlined style={{fontSize:25}} />}
      </Upload>

      {/* <upload></upload> */}

      <Editor
        // key={editorkey}
        apiKey={tinyMCE.apiKey}
        // onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          plugins: tinyMCE.plugins,
          toolbar: tinyMCE.toolbar,
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          // mergetags_list: [
          //   { value: "First.Name", title: "First Name" },
          //   { value: "Email", title: "Email" },
          // ],

          paste_data_images: true,

          ai_request: (request: any, respondWith: any) =>
            respondWith.string(() =>
              Promise.reject("See docs to implement AI Assistant")
            ),
        }}
        initialValue={task.remarks}
        // onEditorChange={handleEditorChange}
        onBlur={(evt, editor) => {
          const content = editor.getContent();
          onEnter({ ...task, remarks: content }); // Replace with your save logic
        }}
        // outputFormat="html"
      />

      {reply?.length>0 && (
        <List
          size="small"
          // style={{width:"30vw"}}
          dataSource={reply}
          renderItem={(item: any, index: any) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    size={"default"} // size={"small"}
                    src={
                      <img
                        src={s3Path + item?.createdBy?.partyId?.imageUrl}
                        alt={"R"}
                      />
                    }
                  />
                }
                title={
                  <Typography.Text strong>
                    {item?.createdBy?.name === user.name
                      ? "Me/MySelf "
                      : item?.createdBy?.name}
                    <Tag>
                      <ReactTimeAgo
                        date={item?.createdAt}
                        locale="en-IN"
                        // timeStyle="twitter"
                      />
                    </Tag>
                  </Typography.Text>
                }
                description={
                  <Row justify={"space-between"}>
                    <Typography.Text>
                      {item?.comment && parse(item?.comment)}{" "}
                    </Typography.Text>
                    {/* { console.log(item[0]?.tagto[0]?.partyId?.personName,"hi")} */}

                    {item?.tagTo && (
                      <Avatar.Group>
                        {item.tagTo?.map((data: any, index: any) => (
                          <>
                            {/* { console.log(data.partyId.personName)} */}
                            <Tooltip
                              title={data?.partyId?.personName}
                              key={index}
                            >
                              <Avatar
                                // size={"small"}
                                src={
                                  <img src={s3Path + data.imageUrl} alt="T" />
                                }
                              />
                            </Tooltip>
                          </>
                        ))}
                      </Avatar.Group>
                    )}
                  </Row>
                }
              />
            </List.Item>
          )}
        />
      )}
      {/* <Row justify={"end"}>
        <Button onClick={() => setIsReply(!isReply)}>Comment/Reply</Button>
      </Row> */}
      <ManageReplyForm taskId={props.id} />
    </Space>
  );
};

export default ManageTaskTableForm;
