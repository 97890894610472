import * as React from "react";
import {
  Form,
  Button,
  message,
  Select,
  Row,

  Input,
  Col,
  Switch,

  Upload,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";

import { queryClient } from "../../..";
import {
  InfoCircleOutlined,

  MailOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import { useQuery } from "react-query";

import LocalStorageService from "../../../utils/local-storage";
import { CreateCandidateModalContext } from "../Modal/CreateCandidate";

import { deleteFileToAWS, uploadFileToAWS } from "src/utils/s3Config/s3upload";

import { getAllJob } from "src/modules/Job/Requests";
import { InputRules } from "src/utils/InputRules";



const CreateCandidate: React.FC = () => {
  const modalContext = React.useContext(CreateCandidateModalContext);
  const [formLoading, setFormLoading] = React.useState(false);


  const [imageUrl, setImageUrl] = React.useState<string[]>([]);

  const createCandidateMutation = useMutation(
    (values: any) => request("admin.candidate.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("candidate");
      },
    }
  );
  const { data: job } = useQuery(["job"], async () => getAllJob(), {
    refetchOnWindowFocus: false,
  });

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;

    values.fileUrl = imageUrl;

    setFormLoading(true);

    createCandidateMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Candidate has been successfully created.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Row gutter={24}>
        <Col flex={2} span={8}>
          <Form.Item
            label="First Name"
            name="firstName"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "firstName",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter first Name",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input
              placeholder="Enter First Name"
            // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
        <Col flex={2} span={8}>
          <Form.Item
            label="Last Name"
            name="lastName"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "lastName",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <Input
              placeholder="Enter Last Name"
            // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
        <Col flex={2} span={8}>
          <Form.Item
            label="Job"
            style={{ fontWeight: "bold" }}
            name="jobId"
            tooltip={{
              title: "job",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Select Job",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select Job"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA!.children as unknown as string)
                  .toLowerCase()
                  .localeCompare(
                    (optionB!.children as unknown as string).toLowerCase()
                  )
              }
            >
              {job &&
                job?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.id} key={i}>
                      {item?.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col flex={3} span={8}>
          <Form.Item
            label="Phone No"
            name="phone"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "phone No",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }, ...InputRules.phoneNo]}
            hasFeedback
          >
            <Input
              placeholder="Enter Phone No"
              addonBefore="+91-"
              maxLength={10}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={8}>
          <Form.Item
            label="Email"
            name="email"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "email",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                type: "email",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input
              placeholder="Enter Email"
              addonBefore={<MailOutlined />}
              type="email"
              maxLength={40}
            // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={8}>
          <Form.Item
            label="Verified"
            name="verified"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "verified",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <Select
              placeholder="Select Verified or Not"
              options={[
                {
                  value: "Yes",
                  label: "Yes",
                },
                {
                  value: "No",
                  label: "No",
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col flex={3} span={12}>
          <Form.Item
            label="UTM Source"
            name="utmSource"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "utmSource",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <Input
              placeholder="Enter UTM Source"
            // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="UTM Medium"
            name="utmMedium"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "utmMedium",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <Input
              placeholder="Enter UTM Medium"
            // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row justify={"space-between"}>
        <Form.Item
          label="Resume/CV  "
          style={{ fontWeight: "bold" }}
          name="fileUrl"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "Please Upload CV",
            },
          ]}
        >
          <Upload
            accept="application/pdf,application/msword, image/png, image/jpeg, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            listType="picture"
            maxCount={4}
            multiple
            action={async (file) => {
              const response: any = await uploadFileToAWS(
                file,
                "candidate"
              ).catch((err: any) => message.error(err?.toString()));
              setImageUrl([...imageUrl, response.key]);
              return response.key;
            }}
            beforeUpload={(fileList) => {
              console.log("FILE", fileList.size);
              return fileList.size / 1024 / 1024 <= 2 ? true : false;
            }}
            onRemove={(e) => {
              if (e?.error?.url) {
                deleteFileToAWS(e.error.url).then((value) =>
                  message.success(value)
                );
                var file = imageUrl.filter((item) => item !== e.error.url);
                setImageUrl(file);
              }
            }}
          >
            {imageUrl.length < 4 && (
              <Button icon={<UploadOutlined />}>Upload Resume/CV</Button>
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="Status"
          style={{ fontWeight: "bold" }}
          name="isActive"
          tooltip={{
            title: "status",
            icon: <InfoCircleOutlined />,
          }}
          valuePropName="checked"
        // rules={[{ required: true }]}
        >
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            defaultChecked
          />
        </Form.Item>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateCandidate;
