import { Tag } from "antd";

import * as React from "react";
import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Table } from "antd";

import { getAllUser } from "../../Requests";
// import { getAccessAuthrorization } from "src/components/access";

interface DataType {
  firstname: string;
  lastname: string;
  email: any;
  roles: any;
}
// type DataIndex = keyof DataType;
const UserList: React.FC = () => {

  // let accessAuth = getAccessAuthrorization("user");

  const {
    isLoading,
    isError,
    data: user,
  } = useQuery(["user"], () => getAllUser(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }

  const column: ColumnsType<DataType> = [
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
      render: (text) =>
        text.map((item: any, i: any) => {
          return <Tag>{item?.name}</Tag>;
        }),
    },

    // {
    //   title: "Action",
    //   dataIndex: "id",
    //   key: "id",
    //   render: (id: any) => (
    //     <Space size={10}>
    //       <UserViewModal id={id} />
    //       {/* <UserManageModal id={id} /> */}
    //       {accessAuth[0].includes("Edit") && <UserManageModal id={id} />}
    //     </Space>
    //   ),
    // },
  ];

  return <Table size="small" columns={column} dataSource={user} />;
};

export default UserList;
