import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Divider,
  List,
  Modal,
  Popover,
  Row,
  Tag,
  Tooltip,
  Typography,
} from "antd";

import * as React from "react";
import {  CommentOutlined, RightSquareOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";
import ReplyManageModal from "../../Modal/ManageReply";

import { Space, Table } from "antd";

import { getAllReply, getReplyByTask } from "../../Requests";
import { getAccessAuthrorization } from "src/components/access";
import moment from "moment";
import { s3Path } from "src/utils/s3Config/s3upload";
import ReactTimeAgo from "react-time-ago";
import ManageReplyForm from "../../Form/ManageReplyForm";
import LocalStorageService from "src/utils/local-storage";
import parse from "html-react-parser";
import { MessageOutline } from "antd-mobile-icons";




const ReplyListByTaskId: React.FC<{ taskId: string; replyId?: string }> = (
  props
) => {
  const [user, setUser] = React.useState(LocalStorageService.getAccount());

  const [isReply, setIsReply] = React.useState(false);
    const [isModalVisible, setIsModalVisible] = React.useState(false);
  
    const showModal = () => setIsModalVisible(true);
  
    const handleOk = () => setIsModalVisible(false);
  
    const handleCancel = () => setIsModalVisible(false);
  let accessAuth = getAccessAuthrorization("reply");

  const {
    isError,
    isLoading,
    data: reply,
  } = useQuery(
    ["reply", props.taskId],
    async () => getReplyByTask(props.taskId),
    {
      refetchOnWindowFocus: false,
    }
  );
  // console.log({reply})
  // console.log(reply[0]?.tagto[0]?.partyId?.personName,"hi")

  const content =(
    <>
     {/* <Row> */}
    {reply&& <List
size="small"
    dataSource={reply}
    renderItem={(item:any, index:any) => (
      <List.Item
style={{width:"30vw"}}
      
      >
        <List.Item.Meta
          avatar={  <Avatar
            size={"default"}  // size={"small"}
            src={
              <img
                src={
                  s3Path + item?.createdBy?.partyId?.imageUrl
                }
                alt={"R"}
              />
            }
          />}
          title={  <Typography.Text strong>
            {item?.createdBy?.name === user.name
              ? "Me/MySelf "
              : item?.createdBy?.name}
            <Tag>
              <ReactTimeAgo
                date={item?.createdAt}
                locale="en-IN"
                // timeStyle="twitter"
              />
            </Tag>
          </Typography.Text>}
          description=  {<Row justify={"space-between"}><Typography.Text>{item?.comment && parse(item?.comment)} </Typography.Text>
                       {/* { console.log(item[0]?.tagto[0]?.partyId?.personName,"hi")} */}
       
           {item?.tagTo&&<Avatar.Group>
                      {item.tagTo?.map((data: any ,index:any) => (
                        <>
                       {/* { console.log(data.partyId.personName)} */}
                       <Tooltip title={data?.partyId?.personName} key={index}>
                          <Avatar
                          // size={"small"}
                            src={<img src={s3Path + data?.imageUrl} alt="T" />}
                          />
                          </Tooltip>
                        </>
                      ))}
                    </Avatar.Group>}
         </Row>}
          />
      </List.Item>
    )}
  />}
        {/* {reply &&
          reply?.map((element: any, i: any) => {
            return (
              <>
                <Row align="top" style={{ width: "100%" }}>
                  <Col span={1}>
                    <Avatar
                      size={"large"}
                      src={
                        <img
                          src={
                            s3Path + element?.createdBy?.partyId?.imageUrl
                          }
                          alt={"R"}
                        />
                      }
                    />
                  </Col>
                  <Col style={{ paddingLeft: 8 }} span={18}>
                    <Space.Compact direction="vertical">
                      <Typography.Text strong>
                        {element?.createdBy?.name === user.name
                          ? "Me/MySelf "
                          : element?.createdBy?.name}
                        <Tag>
                          <ReactTimeAgo
                            date={element?.createdAt}
                            locale="en-IN"
                            // timeStyle="twitter"
                          />
                        </Tag>
                      </Typography.Text>
                      <Typography.Text>
                        {element?.comment && parse(element?.comment)}
                      </Typography.Text>
                    </Space.Compact>
                  </Col>
                  <Col span={5}>
                    <Avatar.Group>
                      {element.tagto?.map((data: any) => (
                        <>
                          <Avatar
                            src={<img src={s3Path + data.imageUrl} alt="T" />}
                          />
                        </>
                      ))}
                    </Avatar.Group>
                  </Col>
                </Row>
                <Divider />
              </>
            );
          })} */}
      {/* </Row> */}
      {/* <Row justify={"end"}>
        <Button size="small" onClick={() => setIsReply(!isReply)}>Comment</Button>
      </Row> */}
       <ManageReplyForm taskId={props?.taskId} />
    </>
  )

//   React.useEffect(() => {
//     window.addEventListener('error', e => {
//         if (e.message === 'ResizeObserver loop limit exceeded') {
//             const resizeObserverErrDiv = document.getElementById(
//                 'webpack-dev-server-client-overlay-div'
//             );
//             const resizeObserverErr = document.getElementById(
//                 'webpack-dev-server-client-overlay'
//             );
//             if (resizeObserverErr) {
//                 resizeObserverErr.setAttribute('style', 'display: none');
//             }
//             if (resizeObserverErrDiv) {
//                 resizeObserverErrDiv.setAttribute('style', 'display: none');
//             }
//         }
//     });
// }, []);

  if (isLoading || isError) {
    return <div>Loading</div>;
  }

  return (
    <>
   {reply.length>0&& <Popover content={content} trigger="click" placement="rightTop"  >
    {/* <Tooltip title="Comment"> */}
        <Button
        
          type="text"
          // shape="round"
          // icon={<CommentOutlined />}
          // color="red"
          // variant="filled"
          // style={{backgroundColor:"gray"}}
          size="small"
        ><CommentOutlined />{reply?.length}</Button>
      {/* </Tooltip> */}
    </Popover>}
{/* 

    <Tooltip title="Comment">
        <Button
          onClick={showModal}
          type="text"

          //shape="circle"
          // icon={<EditOutlined />}
        >
          <CommentOutlined />{reply.length}
        </Button>
      </Tooltip>
      <Modal
        title="Comment"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={400}
      >
        {content}
      </Modal> */}
    </>
  );
};

export default ReplyListByTaskId;
