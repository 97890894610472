import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,

  Input,
  Col,

  Switch,

  Card,
  Space,
  Tag,

  Descriptions,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,

} from "@ant-design/icons";
import { queryClient } from "src";




import LocalStorageService from "src/utils/local-storage";
import { CreateTaskTemplateModalContext } from "../Modal/CreateTaskTemplate";

import { getAllTaskTemplate } from "../Requests";
import ManageTaskTemplateModal from "../Modal/ManageTaskTemplate";

import Table, { ColumnsType } from "antd/es/table";

import { Editor } from "@tinymce/tinymce-react";
import { tinyMCE } from "src/utils/keys";
import parse from "html-react-parser";

interface DataType {
  id: number;
  name: any;
}
interface DataType {
  key: React.Key;
  id: number;
  template: any;
  title: any;
}
// type Color = Exclude<GetProp<ColorPickerProps, "value">, string>;

const CreateTaskTemplate: React.FC = () => {
  const modalContext = React.useContext(CreateTaskTemplateModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const [remarks, setRemarks] = React.useState<string>();

  const [editorkey, seteditorkey] = React.useState(4);

  const createTaskTemplateMutation = useMutation(
    (values: any) => request("admin.taskTemplate.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("taskTemplate");
      },
    }
  );
  // const { data: party } = useQuery(["party"], async () => getAllActiveParty(), {
  //   refetchOnWindowFocus: false,
  // });
  const {

    data: response,
  } = useQuery(["taskTemplate"], () => getAllTaskTemplate(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    values.template = remarks;

    // values.partyId = party[values.partyId].id;


    setFormLoading(true);

    createTaskTemplateMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The TaskTemplate has been successfully created.");
        form.resetFields();
        cleareditor();
        modalContext.setIsModalVisible(false);
        // setImageUrl(undefined);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [form] = Form.useForm();

  const handleEditorChange = (content: any, editor: any) => {
    // console.log("Content was updated:", content);
    setRemarks(content);
  };

  const cleareditor = () => {
    const newkey = editorkey * 43;
    seteditorkey(newkey);
  };

  const column: ColumnsType<DataType> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          {/* <TaskTemplateViewModal id={id} /> */}
          <ManageTaskTemplateModal id={id} />
          {/* {accessAuth[0].includes("Edit") && <ManageTaskTemplateModal id={id} />} */}
        </Space>
      ),
    },
  ];
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Row gutter={22}>
        <Col flex={5} span={8}>
          <h3>Add TaskTemplate</h3>
          <Card style={{ backgroundColor: "whitesmoke" }} bordered hoverable>
            <Form.Item
              label="Title"
              name="title"
              style={{
                fontWeight: "bold",
              }}
              tooltip={{
                title: "title",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Enter title",
                },
                { whitespace: true },
                { min: 3 },
              ]}
              hasFeedback
            >
              <Input placeholder="Enter Title" />
            </Form.Item>
            <Editor
              key={editorkey}
              apiKey={tinyMCE.apiKey}
              // onInit={(evt, editor) => (editorRef.current = editor)}
              init={{
                plugins: tinyMCE.plugins,
                toolbar: tinyMCE.toolbar,
                tinycomments_mode: "embedded",
                tinycomments_author: "Author name",
                // mergetags_list: [
                //   { value: "First.Name", title: "First Name" },
                //   { value: "Email", title: "Email" },
                // ],

                paste_data_images: true,

                ai_request: (request: any, respondWith: any) =>
                  respondWith.string(() =>
                    Promise.reject("See docs to implement AI Assistant")
                  ),
              }}
              // initialValue="Welcome to TinyMCE!"
              onEditorChange={handleEditorChange}
            // outputFormat="html"
            />
            <Row gutter={20}>
              <Col flex={2} span={12}>
                <Form.Item
                  label="Status"
                  style={{ fontWeight: "bold" }}
                  name="isActive"
                  tooltip={{
                    title: "status",
                    icon: <InfoCircleOutlined />,
                  }}
                  valuePropName="checked"
                // rules={[{ required: true }]}
                >
                  <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    defaultChecked
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item style={{ textAlign: "center" }}>
              <Button
                size="large"
                loading={formLoading}
                type="primary"
                htmlType="submit"
                shape="round"
              >
                Submit
              </Button>
            </Form.Item>
          </Card>
        </Col>
        <Col flex={5} span={16}>
          <h3>TaskTemplate List</h3>

          <Table
            rowKey={(record) => record.id}
            size="small"
            columns={column}
            dataSource={response}
            expandable={{
              expandedRowRender: (record) => (
                <>
                  <Descriptions
                    bordered
                    layout="vertical"
                    size="small"
                    style={{ backgroundColor: "beige" }}
                  >
                    <Descriptions.Item label="Template">
                      {record.template && parse(record.template)}
                    </Descriptions.Item>
                  </Descriptions>
                </>
              ),
              rowExpandable: (record) => record?.title !== "Not Expandable",
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default CreateTaskTemplate;
