import {
  Avatar,
  Typography,
  Badge,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Segmented,
  Tooltip,
} from "antd";
import * as React from "react";
import {
  SlackOutlined,
  UserOutlined,
  CalendarOutlined,
  TagsOutlined,
  MinusCircleFilled,
} from "@ant-design/icons";
import { useMutation, useQueries, useQuery } from "react-query";
import { request } from "src/utils/services";
import { queryClient } from "src";
import LocalStorageService from "src/utils/local-storage";
import {
  getTaskAssignedBy,
  getTaskByPersonnel,
  getTaskTableByPersonnel,
} from "src/modules/Task/Requests";
// import moment from 'moment';
import { ColumnsType } from "antd/es/table";
// import { title } from 'process';
import { s3Path } from "src/utils/s3Config/s3upload";
import imLogo from "src/assets/img/imlogo.png";
import { getAllActivePersonnel } from "src/modules/HR/Personnel/Requests";
// import { values } from 'lodash';
import { getAllActiveTaskType } from "src/modules/TaskType/Requests";
import dayjs from "dayjs";
import CreateTaskTypeModal from "src/modules/TaskType/Modal/CreateTaskType";
// import { getAllActiveWork } from 'src/modules/Work/Requests';
import { getAllLead } from "src/modules/Leads/Requests";
import { getAllActiveWork } from "src/modules/Work/Requests";
import parse from "html-react-parser";
import TaskManageForMeModal from "src/modules/Task/Modal/ManageTaskForMe";
import ReplyListByTaskId from "src/modules/Reply/Component/Home/ListByTaskId";

interface TaskFiels {
  isChecked: boolean;
  title: string;
}

const TasksForPersonnel: React.FC = () => {
  const [formLoading, setFormLoading] = React.useState(false);
  const [user, setUser] = React.useState(LocalStorageService.getAccount());
  const inputRefs = React.useRef<(HTMLInputElement | any)[]>([]); // Ref array for inputs
  const [isCheck, setIsCheck] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [selectedFilter, setSelectedFilter] = React.useState<string>("To Do");

  const [filteredTByP, setFilteredTByP] = React.useState<[] | any>([]);

  const createTaskMutation = useMutation(
    (values: any) =>
      request(
        values.id ? "admin.task.update" : "admin.task.create",
        values.id ? { id: values.id } : {},
        values
      ),

    {
      onSuccess: () => {
        queryClient.invalidateQueries("task");

        queryClient.invalidateQueries("taskByPersonnel");
        refetch();
      },
    }
  );

  const {
    isLoading,
    isError,
    data: taskForPersonnel,
    refetch,
  } = useQuery(
    ["taskForPersonnel", user.personnel.id],
    () => getTaskTableByPersonnel(user.personnel.id),
    {
      // refetchOnWindowFocus: true,

      keepPreviousData: true,
      staleTime: 5000,
      onSuccess: (data) => {
        const activeTasks = data.filter((task: any) => task.isActive);
        setFilteredTByP([...activeTasks, { title: "" }]); // Add a blank entry at the end
      },
    }
  );
  // console.log(user);

  // console.log(personnel.data)
  // console.log(work.data)

  // Save data
  const onEnter = async (values: any) => {
    values.updatedBy = user.id;

    createTaskMutation
      .mutateAsync(values)
      .then(() => {})
      .catch((e: any) => {
        // setFormLoading(false);
        message.error(e);
      });
  };
  //Status option
  const statusOption = [
    {
      label: (
        <Tag
          bordered={false}
          style={{
            backgroundColor: "lightgrey",
            fontSize: 14,
            borderRadius: 10,
          }}
          //   color={"volcano"}
        >
          To Do
        </Tag>
      ),
      value: "To Do",
    },
    {
      label: (
        <Tag
          bordered={false}
          color={"blue"}
          style={{ fontSize: 14, borderRadius: 10 }}
        >
          In Progress
        </Tag>
      ),
      value: "In Progress",
    },
    {
      label: (
        <Tag
          bordered={false}
          // color={"yellow"}
          style={{
            fontSize: 14,
            borderRadius: 10,
            backgroundColor: "lightyellow",
          }}
        >
          On Hold
        </Tag>
      ),
      value: "On Hold",
    },
    {
      label: (
        <Tag
          bordered={false}
          color={"orange"}
          style={{ fontSize: 14, borderRadius: 10 }}
        >
          In Review
        </Tag>
      ),
      value: "In Review",
    },
    {
      label: (
        <Tag
          bordered={false}
          // color={"green"}
          style={{
            fontSize: 14,
            borderRadius: 10,
            backgroundColor: "lightgreen",
          }}
        >
          Done
        </Tag>
      ),
      value: "Done",
    },
  ];

  const column: ColumnsType<any> = [
    {
      title: "A",
      dataIndex: "isChecked",
      key: "isChecked",
      // fixed: 'left',
      width: 25,

      render: (text: any, rec: any) => {
        setIsCheck(text);
        return (
          <Space>
            <Checkbox
              checked={text ? text : isCheck}
              style={{ fontSize: 16 }}
              onChange={(e) => {
                setIsCheck(!isCheck);
                onEnter({ ...rec, isChecked: e.target.checked });
              }}
            />
          </Space>
        );
      },
    },
    Table.EXPAND_COLUMN,
    {
      title: "Task Title ",
      dataIndex: "title",
      key: "title",
      // fixed: 'left',
      width: "25%",

      render: (text: any, rec: any, i: any) => (
        <>
          <Space.Compact>
            <Tag
              color={
                rec?.priority === "Urgent"
                  ? "red-inverse"
                  : rec?.priority === "High"
                  ? "orange"
                  : rec?.priority === "Medium"
                  ? "purple"
                  : rec?.priority === "Low"
                  ? "cyan"
                  : "lime"
              }
              style={{
                fontSize: 13,

                fontVariant: "ruby",
              }}
            >
              {rec?.priority && rec?.priority[0]}
            </Tag>
            <Tag
              style={{
                textDecoration: rec?.isChecked ? "line-through" : "none",
                fontSize: 14,
              }}
            >
              {rec?.title}
            </Tag>

            {rec?.id && <TaskManageForMeModal id={rec?.id} />}
            {rec?.id && <ReplyListByTaskId taskId={rec.id}/>}

          </Space.Compact>
        </>
      ),
      filters: [
        { text: "Urgent", value: "Urgent" },
        { text: "High", value: "High" },
        { text: "Medium", value: "Medium" },
        { text: "Low", value: "Low" },
        { text: "Trivial", value: "Trivial" },
      ], // Define filters for priority
      filterSearch: true, // Enables search functionality in filter dropdown
      onFilter: (value, record) => record.priority === value, // Filter logic
    },
    Table.SELECTION_COLUMN,
    {
      title: (
        <>
          <SlackOutlined /> Status
        </>
      ),
      dataIndex: "status",
      key: "status",

      width: 120,

      render: (_: any, rec: any, i: any) => (
        <Select
          defaultValue={rec?.status}
          //  placeholder={"Select Status"}
          variant="borderless"
          virtual={true}
          suffixIcon={false}
          onChange={(value) => {
            value && onEnter({ ...rec, status: value });
            // You can also update a state or trigger an action with the selected value
          }}
          options={statusOption}
        />
      ),
    },

    {
      title: (
        <>
          <UserOutlined /> Assigned By
        </>
      ),
      dataIndex: "assignedPersonId",
      key: "assignedPersonId",

      width: 150,
      filters: taskForPersonnel
      ? taskForPersonnel.map((item: any) => ({
          text: item?.createdBy?.partyId?.personName, // Display name
          value: item?.createdBy?.partyId?._id, // Unique identifier for filtering
        }))
      : [],
    filterSearch: true, // Enable search in the filter dropdown
    onFilter: (value: any, record: { assignedPersonId: any; }) => record?.assignedPersonId === value, // Filtering logic
      

      render: (_: any, rec: any) => (
        // <Tooltip title={rec?.createdBy?.partyId?.personName}>
        // <Avatar
        //   size={55}
        //   src={
        //     <img
        //       // width={50}
        //       src={
        //         rec?.createdBy?.partyId?.imageUrl
        //           ? s3Path + rec?.createdBy?.partyId?.imageUrl
        //           : imLogo
        //       }
        //       alt="img"
        //     />
            
        //   }
        // />

        // </Tooltip>
        <Tag
        bordered={false}
        style={{
          backgroundColor: "lightgrey",
          fontSize: 14,
          borderRadius: 10,
        }}
      >
        <Avatar
          size={"small"}
          src={
            <img
            src={
              rec?.createdBy?.partyId?.imageUrl
                ? s3Path + rec?.createdBy?.partyId?.imageUrl
                : imLogo
            }
              alt={"img"}
            />
          }
        />{" "}
      {rec?.createdBy?.partyId?.personName}
      </Tag>
      ),
    },

    {
      title: (
        <>
          <CalendarOutlined /> Due
        </>
      ),
      dataIndex: "dueDate",
      key: "dueDate",
      width: 150,
      render: (text: any, rec: any) => (
        <Tag 
        style={{
          backgroundColor: "lightgrey",
          fontSize: 14,
          // borderRadius: 10,
        }}
        bordered
        >

          {dayjs(text).format("MMM DD, YYYY")}
        </Tag>
      ),
    },

    {
      title: (
        <>
          <TagsOutlined rotate={270} /> Tag
        </>
      ),
      dataIndex: "type",
      key: "type",
      width: 150,

      render: (_: any, rec: any) =>
        rec.type &&
        rec.type?.map((item: any, i: number) => {
          return (
            <Typography.Text>
              <Tag
                color={item.color}
                bordered={false}
                style={{ borderRadius: 10, fontWeight: "bold" }}
              >
                {" "}
                {item?.title}
              </Tag>
            </Typography.Text>
          );
        }),
    },

    {
      title: (
        <>
          <MinusCircleFilled /> Work
        </>
      ),
      dataIndex: "workId",
      key: "workId",
      width: 200,

      filters: taskForPersonnel
      ? taskForPersonnel?.map((item: any) => ({
          text: item?.workId?.title, // Display name
          value: item?.workId?._id, // Unique identifier for filtering
        }))
      : [],
    filterSearch: true, // Enable search in the filter dropdown
    onFilter: (value: any, record: { workId: any; }) => record?.workId === value, // Filtering logic
      
      render: (_: any, rec: any) => (
        <Typography.Text>
          <Tag
            bordered={false}
            style={{ backgroundColor: "wheat", fontSize: 14, borderRadius: 10 }}
          >
            {rec?.workId?.title}
          </Tag>
        </Typography.Text>
      ),
    },

    {
      title: "Lead",
      dataIndex: "leadId",
      key: "leadId",
      filters: taskForPersonnel
      ? taskForPersonnel?.map((item: any) => ({
          text: item?.leadId?.title, // Display name
          value: item?.leadId?._id, // Unique identifier for filtering
        }))
      : [],
    filterSearch: true, // Enable search in the filter dropdown
    onFilter: (value: any, record: { leadId: any; }) => record?.leadId === value, // Filtering logic
      
      render: (_: any, rec: any) => (
        <Tag
          bordered={false}
          style={{ backgroundColor: "thistle", fontSize: 14, borderRadius: 10 }}
        >
          {rec?.leadId?.title}
        </Tag>
      ),
    },
  ];
   const statusLabels = ["New", "To Do", "In Progress", "On Hold", "In Review", "Done"] as const;
 
 // Assuming these are your filtered counts for each status
 const activeData =  taskForPersonnel?.filter((task: any) => task?.isActive)
 
 const statusOptions = statusLabels.map((status) => ({
   label: (
     <Badge size="small" count={ activeData.filter((item: any) => item.status === status).length
     }>
       <span>{status}</span>
     </Badge>
   ),
   value: status,
 }));

  const filteredData = filteredTByP.filter(
    (item: any) => item.status === selectedFilter
  );

  const operations = (
    <>
      <Segmented
        options={statusOptions}
        value={selectedFilter}
        onChange={(value) => setSelectedFilter(value as string)}
      />
    </>
  );
  React.useEffect(() => {
    const activeTasks = taskForPersonnel.filter((task: any) => task.isActive);
    setFilteredTByP([...activeTasks, { title: "" }]);
  }, [isDelete]);
  if (isLoading || isError) {
    return <div>Loading</div>;
  }

  return (
    <>
      <Row justify={"end"}>{operations}</Row>
      <br />
      <Table
        size="small"
        rowKey={(record) => record.id}
        dataSource={filteredData}
        columns={column}
        scroll={{ y: "70vh", x: "max-content" }}
        // expandable={{
        //   expandedRowRender: (record) => <p style={{ margin: 0 }}>{record?.remarks && parse(record?.remarks)}</p>,
        //   rowExpandable: (record) => !!record.remarks, // Hide icon if description is null or empty

        // }}
        pagination={false}
      />
    </>
  );
};

export default TasksForPersonnel;
