import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import {
  Avatar,
  Button,
  Col,
  Descriptions,
  Result,
  Row,
  Space,
  Table,
  Tabs,
  Tag,
  Typography,
} from "antd";

import { getAllWork } from "../Requests";
import WorkViewModal from "../Modal/ShowWorkDetails";
import ManageWorkModal from "../Modal/ManageWork";
import { getAccessAuthrorization } from "src/components/access";
import CreateCredentialModal from "src/modules/Credential/Modal/CreateCredential";
import CreateDeploymentModal from "src/modules/Deployment/Modal/CreateDeployment";
import CreateStoryModal from "src/modules/Story/Modal/CreateStory";
import CreateTaskModal from "src/modules/Task/Modal/CreateTask";
import DeploymentViewModal from "src/modules/Deployment/Modal/ShowDeploymentDetails";
import { s3Path } from "src/utils/s3Config/s3upload";

import CreateSEOModal from "src/modules/SEO/Modal/CreateSEO";
import CreateCaseStudyModal from "src/modules/Case Study/Modal/CreateCaseStudy";
import CreateWorkFollowupModal from "../Modal/CreateWorkFollowup";

interface DataType {
  key: React.Key;
  id: number;
  name: any;
  title: any;
  description: any;
  attachment: any;
  teamId: any;
  technologyId: any;
  workStatus: any;
  startDate: any;
  endDate: any;
}
// type DataIndex = keyof DataType;
const { Text } = Typography;

const WorkList: React.FC = () => {

  // let accessAuth = getAccessAuthrorization("work");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["work"], () => getAllWork(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }

  let productList = response.filter(
    (element: any) => element.workType === "Product"
  );
  let projectList = response.filter(
    (element: any) => element.workType === "Project"
  );
  let otherWork = response.filter(
    (element: any) => element.workType === "Other"
  );

  const items = [
    {
      label: "Project",
      key: "1",
      children: <WorkTable data={projectList} />,
    },
    {
      label: "Product",
      key: "2",
      children: <WorkTable data={productList} />,
    },
    {
      label: "Demo/Others",
      key: "3",
      children: <WorkTable data={otherWork} />,
    },

    {
      label: "All Work List",
      key: "4",
      children: <WorkTable data={response} />,
    },
  ];

  return !isError ? (
    <Tabs defaultActiveKey="1" items={items} type="card" />
  ) : (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, something went wrong."
      extra={<Button type="primary">Back Home</Button>}
    />
  );
};

export default WorkList;

const WorkTable: React.FC<{ data: any }> = ({ data }) => {
  let accessAuth = getAccessAuthrorization("work");

  const column: ColumnsType<DataType> = [
    {
      title: " Work Title/Type",
      dataIndex: "title",
      key: "title",
      render: (_: any, i: any) => (
        <Space.Compact direction="vertical">
          <Text>{i?.title}</Text>
          <Text type="danger" code>
            {i?.workType}
          </Text>
        </Space.Compact>
      ),
    },
    {
      title: "Party Detail",
      dataIndex: "partyId",
      key: "partyId",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Text
              style={{
                fontWeight: "bold",
              }}
            >
              {i?.partyId?.personName}
            </Text>
            <Text
              type="danger"
              code
              style={{ width: 125, overflow: "auto", height: 25 }}
            >
              {i?.partyId?.companyName}
            </Text>
            <Text code>+91-{i?.partyId?.phoneNo}</Text>
          </Space.Compact>
        </Space>
      ),
    },
    // {
    //   title: "Date",
    //   dataIndex: "date",
    //   key: "date",
    //   render: (_: any, i: any) => (
    //     <Space>
    //       <Space.Compact direction="vertical">
    //         <Text code>{moment(i?.startDate).format("DD-MMM-YYYY")}</Text>to
    //         <Text code>{moment(i?.endDate).format("DD-MMM-YYYY")}</Text>
    //       </Space.Compact>
    //     </Space>
    //   ),
    // },
    // {
    //   title: "Technology",
    //   dataIndex: "technologyId",
    //   key: "technologyId",
    //   render: (data) => (
    //     <Avatar.Group>
    //       {data?.map((item: any) => (
    //         <>
    //           <Avatar src={<img src={s3Path + item.icon} alt="T" />} />
    //         </>
    //       ))}
    //     </Avatar.Group>
    //   ),
    // },

    {
      title: "Add Detail",
      dataIndex: "id",
      key: "x",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <CreateWorkFollowupModal workId={i.id} />

            <CreateCredentialModal id={i?.id} />
            {i?.deploymentId ? (
              <DeploymentViewModal id={i?.id} />
            ) : (
              <CreateDeploymentModal id={i?.id} />
            )}
          </Space.Compact>
        </Space>
      ),
    },
    {
      title: "Add Story",
      dataIndex: "id",
      key: "y",
      render: (id: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <CreateStoryModal id={id} />
            <CreateTaskModal workId={id} />
          </Space.Compact>
        </Space>
      ),
    },
    {
      title: "Add SEO/Case Study",
      dataIndex: "id",
      key: "x",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <CreateSEOModal id={i.id} />
            <CreateCaseStudyModal id={i.id} />
          </Space.Compact>
        </Space>
      ),
    },
    {
      title: "Team",
      dataIndex: "teamId",
      key: "teamId",
      render: (data) => (
        <Avatar.Group>
          {data?.map((item: any) => (
            <>
              <Avatar src={<img src={s3Path + item.imageUrl} alt="T" />} />
            </>
          ))}
        </Avatar.Group>
      ),
    },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (_: any, i: any) => (
        <Space.Compact direction="vertical">
          <Tag color="red">{i?.workStatus}</Tag>
          {i.isActive ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Inactive</Tag>
          )}
        </Space.Compact>
      ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "z",
      render: (id: any) => (
        <Space size={10}>
          <WorkViewModal id={id} />
          {/* <ManageWorkModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <ManageWorkModal id={id} />}
        </Space>
      ),
    },
  ];
  return (
    <Table
      rowKey={(record) => record.id}
      size="small"
      columns={column}
      dataSource={data}
      expandable={{
        expandedRowRender: (record) => (
          <>
            <Descriptions
              bordered
              layout="vertical"
              size="small"
              style={{ backgroundColor: "beige" }}
            >
              <Descriptions.Item label="Teams Working on" key={record.teamId}>
                <Col>
                  {record?.teamId?.map((item: any) => (
                    <>
                      <Row>
                        <Text>{item.partyId.personName}</Text>
                        <Text type="success" code>
                          {item.partyId.partyType}
                        </Text>
                      </Row>
                    </>
                  ))}
                </Col>
              </Descriptions.Item>
              <Descriptions.Item label="Technology uses">
                <Row style={{ margin: "2" }}>
                  {record?.technologyId?.map((item: any) => (
                    <>
                      <Row>
                        <Tag>{item.title}</Tag>
                      </Row>
                    </>
                  ))}
                </Row>
              </Descriptions.Item>
              {/* <Descriptions.Item label="Work Status" span={3}>
                <Tag color="red">{record?.workStatus}</Tag>
              </Descriptions.Item> */}
              <Descriptions.Item label="Start to End Date" span={3}>
                <Text code>
                  {moment(record?.startDate).format("DD-MMM-YYYY")}
                </Text>
                to
                <Text code>
                  {moment(record?.endDate).format("DD-MMM-YYYY")}
                </Text>
              </Descriptions.Item>
              {/* <Descriptions.Item label="Remarks:" span={3}>
                {record?.description}
              </Descriptions.Item> */}
            </Descriptions>
          </>
        ),
        rowExpandable: (record) => record?.title !== "Not Expandable",
      }}
    />
  );
};
