
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Space, Table, Tag, Image, Empty } from "antd";

import { getAllTimeline } from "../Requests/index";


import ManageTimelineModal from "../Modal/ManageTimeline";

import { getAccessAuthrorization } from "src/components/access";
import { s3Path } from "src/utils/s3Config/s3upload";

interface DataType {
  id: number;
  name: any;
}
// type DataIndex = keyof DataType;
const TimelineList: React.FC = () => {

  let accessAuth = getAccessAuthrorization("timeline");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["timeline"], () => getAllTimeline(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  // const { data: timelineCategory } = useQuery(
  //   ["timelineCategory"],
  //   async () => getAlltimelineCategories(),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Image",
      dataIndex: "fileUrl",
      key: "fileUrl",
      render: (text: any) =>
        text ? (
          <Image width={100} height={100} src={s3Path + text} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ),
    },
    {
      title: " Title",
      dataIndex: "title",
      key: "title",
    },
    // {
    //   title: "Category",
    //   dataIndex: "timelineCatogiriesId",
    //   key: "timelineCatogiriesId",
    //   render: (text: any) => <p>{text.title}</p>,
    // },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          {/* <TimelineViewModal id={id} /> */}
          {/* <ManageTimelineModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <ManageTimelineModal id={id} />}
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default TimelineList;
