import { path } from "./path";

export const reportRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createTasks",
    module: "task",
    alias: "admin.task.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateTasks/:id",
    module: "task",
    alias: "admin.task.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveTasks",
    module: "task",
    alias: "admin.task.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllTasks",
    module: "task",
    alias: "admin.task.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findUpdateOneTask/:id",
    module: "task",
    alias: "admin.task.update.one",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneTasks/:id",
    module: "task",
    alias: "admin.task.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneTasksTable/:id",
    module: "task",
    alias: "admin.task.table.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findTasksByPersonnel/:id",
    module: "task",
    alias: "admin.task.personnel.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findTasksTableByPersonnel/:id",
    module: "task",
    alias: "admin.task.table.personnel",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findTasksAssignedBy/:id",
    module: "task",
    alias: "admin.task.assigned.by.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findTasksTableAssignedBy/:id",
    module: "task",
    alias: "admin.task.table.assigned",
    isSubDomain: true,
  },
];
