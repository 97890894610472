import * as React from "react";
import {
  Row,
  Col,

  Space,
  Typography,
  Button,


} from "antd";

import { useQuery } from "react-query";
import { squareLogo } from "../../../constants/logo";


import {
  PhoneFilled,
  MailFilled,
  GlobalOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import moment from "moment";



import { getOneTaxInvoiceWithDetail } from "../Requests";
import ReactToPrint from "react-to-print";
import { Divider } from "antd-mobile";
import Table, { ColumnsType } from "antd/es/table";

import { gstStateCode } from "../../../constants/statecode";

interface DataType {
  id: number;
  name: any;
  seq: number;

}
const ShowTaxInvoiceDetailsForm: React.FC<{ id: string, type: string }> = (props) => {
  const [isIGST, setIGST] = React.useState(true);


  const { data: taxInvoice } = useQuery(
    ["taxInvoiceById", props.id],
    async () => getOneTaxInvoiceWithDetail(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );
  console.log(taxInvoice);
  const componentRef = React.useRef(null);
  const column: ColumnsType<DataType> = [

    {
      title: "#",
      dataIndex: "sno",
      key: "sno",
      render: (_: any, i: any) => <Typography.Text strong>{i.sno}</Typography.Text>



    },
    {
      title: "ITEM NAME",
      dataIndex: "particular",
      key: "particular"
    },
    {
      title: "HSN/SAC",
      dataIndex: "hsnCode",
      key: "hsnCode"
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity"
    },

    {
      title: "UNIT PRICE",
      dataIndex: "rate",
      key: "rate"
    },

    {
      title: "DISCOUNT",
      dataIndex: "discount",
      key: "discount"
    },
    {
      title: "TAX.VALUE",
      dataIndex: "taxable",
      key: "taxable"
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst"
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      key: "amount"
    },



  ];

  const getGstStateCode = (str: keyof typeof gstStateCode) => {
    return gstStateCode[str];
  };

  return (
    <>
      <ReactToPrint
        trigger={() => <Button color="primary">Print TaxInvoice</Button>}
        content={() => componentRef.current}
      />
      <div ref={componentRef}>

        <Row style={{ marginRight: 20 }} justify={"end"}>{props.type === "Original" ? <Typography.Text strong style={{ fontSize: 16, fontStyle: "italic" }}>ORIGINAL FOR RECIPIENT</Typography.Text> : <Typography.Text strong style={{ fontSize: 16, fontStyle: "italic" }}>DUPLICATE FOR SUPPLIER</Typography.Text>}</Row>
        <Row style={{ margin: 20 }} justify={"space-between"}>
          <Col>
            <Space.Compact direction="vertical">
              <Typography.Title level={2}>
                INFINITMINDS DIGITAL LAB PVT LTD
              </Typography.Title>
              <Typography.Text>
                <PhoneFilled /> +91-9334680992
              </Typography.Text>
              <Typography.Text>
                <MailFilled /> info@infinitmindsdigital.com
              </Typography.Text>
              <Typography.Text>
                <GlobalOutlined /> www.infinitmindsdigital.com
              </Typography.Text>
              <Typography.Text>
                {" "}
                <ReconciliationOutlined /> 10AAHCI3234J1ZX
              </Typography.Text>
            </Space.Compact>
          </Col>
          <Col>
            <img src={squareLogo} alt="IM" height={175} width={175} />
          </Col>
        </Row>


        <Row justify={"center"}>
          <Typography.Text underline strong style={{ fontSize: 24 }}>
            Tax Invoice
          </Typography.Text>
        </Row>
        <Row justify={"space-between"} style={{ margin: 20 }}>
          <Space.Compact direction="vertical" >
            <Space >
              <Typography.Text strong>Invoice Date  </Typography.Text>
              {" "}
              <Typography.Text>{moment(taxInvoice?.date).format("DD-MMM-YYYY")}
              </Typography.Text>
            </Space>
            <Space >
              <Typography.Text strong>Invoice No.  </Typography.Text>
              {" "}
              <Typography.Text>

                IM/T/{taxInvoice.seq}</Typography.Text>
            </Space>
          </Space.Compact>
          <Space.Compact direction="vertical">
            {/* <Space direction="horizontal">
              <Typography.Text strong>PAN </Typography.Text>
              <Typography.Text>{" "}DUDPK7991C</Typography.Text>
            </Space> */}
            <Space>
              <Typography.Text strong>GSTIN</Typography.Text>
              <Typography.Text>110AAHCI3234J1ZX</Typography.Text>
            </Space>
            <Row justify={"space-between"}>
              <Space>
                <Typography.Text strong>State: {" "}</Typography.Text>
                <Typography.Text>Bihar</Typography.Text>
              </Space>
              <Space>

                <Typography.Text strong>Code:</Typography.Text>
                <Typography.Text>10</Typography.Text>
              </Space>
            </Row>
          </Space.Compact>

        </Row>
        <Row justify={"space-between"} style={{ margin: 20 }} >
          <Space.Compact direction="vertical" style={{ width: "45%" }}>
            <Typography.Text strong underline>
              Bill To Party
            </Typography.Text>
            <Space>
              <Typography.Text strong>PO No.</Typography.Text>
              <Typography.Text>{taxInvoice?.poNumber}</Typography.Text>
            </Space>
            <Typography.Text strong>{taxInvoice?.billTo?.companyName?.toUpperCase()}</Typography.Text>{" "}
            <Typography.Text style={{ textOverflow: "ellipsis" }} >{taxInvoice?.billTo?.address?.toUpperCase()}</Typography.Text>{" "}
            <Row>
              <Typography.Text strong>GSTIN:</Typography.Text>
              <Typography.Text>{taxInvoice?.billTo?.gstInNo?.toUpperCase()}</Typography.Text>
            </Row>
            <Row justify={'space-between'}>
              <Space>
                <Typography.Text strong >State: </Typography.Text>
                <Typography.Text>{getGstStateCode(taxInvoice?.billTo?.gstInNo?.substring(0, 2))?.toUpperCase()}</Typography.Text>
              </Space>
              <Space >
                <Typography.Text strong>Code: </Typography.Text>
                <Typography.Text>{taxInvoice?.billTo?.gstInNo?.substring(0, 2)}</Typography.Text>
              </Space>
            </Row>
          </Space.Compact>
          <Space.Compact direction="vertical" style={{ width: "45%" }}>
            <Typography.Text strong underline>
              Ship To Party
            </Typography.Text>
            <Space>
              <Typography.Text strong>PO No.</Typography.Text>
              <Typography.Text>{taxInvoice?.poNumber}</Typography.Text>
            </Space>
            <Typography.Text strong>{taxInvoice?.shipTo?.companyName?.toUpperCase()}</Typography.Text>{" "}
            <Typography.Text style={{ textOverflow: "ellipsis" }} >{taxInvoice?.shipTo?.address?.toUpperCase()}</Typography.Text>{" "}
            <Row>
              <Typography.Text strong>GSTIN:</Typography.Text>
              <Typography.Text>{taxInvoice?.shipTo?.gstInNo?.toUpperCase()}</Typography.Text>
            </Row>
            <Row justify={'space-between'}>
              <Space>
                <Typography.Text strong >State: </Typography.Text>
                <Typography.Text>{getGstStateCode(taxInvoice?.shipTo?.gstInNo?.substring(0, 2))?.toUpperCase()}</Typography.Text>
              </Space>
              <Space >
                <Typography.Text strong>Code: </Typography.Text>
                <Typography.Text>{taxInvoice?.shipTo?.gstInNo?.substring(0, 2)}</Typography.Text>
              </Space>
            </Row>
          </Space.Compact>
        </Row>
        <Divider />
        <Table size="small" columns={column} dataSource={taxInvoice.items} pagination={false} />
        <br />
        <Row justify={"space-between"}>
          <Space.Compact direction="vertical" style={{ marginLeft: 10, width: "45%" }} >
            <Typography.Text strong>Reverse Charge Aplicable: No </Typography.Text>
          </Space.Compact>


          <Space.Compact direction="vertical" style={{ marginRight: 10, width: "40%" }}>
            <Row justify={'end'}>
              <Col span={18}>
                <Typography.Text strong >TOTAL AMOUNT BEFORE TAX {" "}</Typography.Text>
              </Col>
              <Col span={6} >
                <Typography.Text> ₹ {taxInvoice?.totalTaxable}</Typography.Text>
              </Col>
            </Row>

            {isIGST ? (<Row>
              <Col span={18}> <Typography.Text strong>IGST</Typography.Text></Col>
              <Col span={6}><Typography.Text> ₹ {taxInvoice?.totalTax}</Typography.Text></Col>
            </Row>
            ) : (
              <>
                <Row>
                  <Col span={18}><Typography.Text strong>SGST</Typography.Text></Col>
                  <Col span={6}><Typography.Text> ₹ {taxInvoice?.totalTax / 2}</Typography.Text></Col>
                </Row>
                <Row>
                  <Col span={18}><Typography.Text strong>CGST</Typography.Text></Col>
                  <Col span={6}><Typography.Text> ₹ {taxInvoice?.totalTax / 2} </Typography.Text></Col>
                </Row>
              </>
            )}
            <Row>
              <Col span={18}><Typography.Text strong>TOTAL TAX AMOUNT </Typography.Text></Col>
              <Col span={6}><Typography.Text> ₹ {taxInvoice?.totalTax}</Typography.Text></Col>
            </Row>
            <Row>
              <Col span={18}> <Typography.Text strong>TOTAL AMOUNT AFTER TAX </Typography.Text></Col>
              <Col span={6}> <Typography.Text> ₹ {taxInvoice?.grandTotalAmount}</Typography.Text></Col>
            </Row>
          </Space.Compact>

        </Row>


        <Row style={{ margin: 20 }}>
          <table
            // rules="all"
            style={{ width: "100%", borderStyle: "solid", padding: 10 }}
          >
            <tr>
              <td style={{ width: "55%" }}>
                <Space.Compact direction="vertical">
                  <Typography.Text strong underline>
                    Bank Details
                  </Typography.Text>

                  <Typography.Text>
                    <Typography.Text strong>Banking Name:</Typography.Text>
                    INFINITMINDS DIGITAL LAB PRIVATE LIMITED
                  </Typography.Text>
                  <Typography.Text>
                    <Typography.Text strong>Account No: </Typography.Text>
                    923020048751470
                  </Typography.Text>

                  <Typography.Text>
                    <Typography.Text strong>IFSC/RTGS/NEFT Code: </Typography.Text>
                    UTIB0001664
                  </Typography.Text>

                  <Typography.Text>
                    <Typography.Text strong>MICR Code:</Typography.Text>
                    800211008
                  </Typography.Text>

                  <Typography.Text>
                    <Typography.Text strong>SWIFT Code:</Typography.Text>
                    UTIB0001664
                  </Typography.Text>
                </Space.Compact>
              </td>

              <td style={{ width: "35%" }} >
                <Space.Compact direction="horizontal">
                  <div>
                    {/* <img
                    width={150}
                    
                   
                    src={require("../../../assets/img/Stamp_WWC.png")}
                    alt="Journey Starts Here"
                      /> */}
                  </div>
                  <Row>

                    {/* <img
                    width={80}
                   
                    src={require("../../../assets/img/Sign_WWC.png")}
                    alt="Journey Starts Here"
                      /> */}

                    <Typography.Text strong>Authorised Signatory</Typography.Text>
                  </Row>
                </Space.Compact>

              </td>
            </tr>
          </table>
          <table
            // rules="all"
            style={{
              width: "100%",
              borderStyle: "solid",
              padding: 10,
              borderCollapse: "collapse",
            }}
          >
            <tr>
              <Row justify={"start"}>
                <Typography.Text strong style={{ fontSize: 11 }}>
                  * Computer Generated
                </Typography.Text>
                <Typography.Text style={{ fontSize: 11 }}>
                  In case of payment through RTGS / NEFT / Crediting Direct in
                  <Typography.Text strong italic style={{ fontSize: 11 }}>
                    {" "}
                    INFINITMINDS DIGITAL LAB PRIVATE LIMITED
                  </Typography.Text>{" "}
                  Account, you are requested to inform such deposit/transfer
                  within 24 hours, to enable us to credit your account. In case
                  the information is not received to us well in time the
                  transfer so made by you, you will be kept unallocated and will
                  not credit to your certificate/report.
                </Typography.Text>
              </Row>
            </tr>
          </table>
          <table
            // rules="all"
            style={{
              width: "100%",
              borderStyle: "solid",
              padding: 10,
              borderCollapse: "collapse",
            }}
          >
            <tr style={{ textAlign: "center" }}>
              <Row justify={"center"}>
                <Typography.Text style={{ fontSize: 12 }}>
                  <Typography.Text strong style={{ fontSize: 12 }}>
                    {" "}
                    Redg Office:
                  </Typography.Text>
                  Ground Floor, M-Shivpuri, Ward No- 12, Beur Road, Near Karthik
                  Paradise Apartment, P.S-Gardanibagh, P.O- Anisabad,
                  Patna-800002, Bihar
                </Typography.Text>
              </Row>
            </tr>
          </table>
        </Row>


      </div>
      {/* <Link to="/taxInvoicepdf">"a button inside"</Link> */}
      {/* <Button
        className="no-print"
        type="primary"
        onClick={() => push(`/taxInvoicepdf/${props.id}`)}
        // onClick={() => window.open("/taxInvoicepdf")}
      >
        Print{" "}
      </Button> */}
    </>
  );
};

export default ShowTaxInvoiceDetailsForm;
