import * as React from "react";
import { Row, Col,  Descriptions, Button } from "antd";
import { useQuery } from "react-query";

import { getPersonnelByIdDetail } from "../Requests";



const ShowPersonnelDetailsForm: React.FC<{ id: string }> = (props) => {
  const { data: personnel } = useQuery(
    ["personnel", props.id],
    async () => getPersonnelByIdDetail(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );
  return (
    <Row>
      <Col>
        <Descriptions title="Personal Details">
          <Descriptions.Item label="Name:">
            {personnel.partyId.personName}
          </Descriptions.Item>
          <Descriptions.Item label="Mobile:">
            +91-{personnel.partyId.phoneNo}
          </Descriptions.Item>
          <Descriptions.Item label="Email:">
            {personnel.partyId.email}
          </Descriptions.Item>
          <Descriptions.Item label="Gender:">
            {personnel.gender}
          </Descriptions.Item>
          <Descriptions.Item label="Aadhar No.:">
            {personnel.addharNo}
          </Descriptions.Item>
          <Descriptions.Item label="Pan No.:">
            {personnel.panNo}
          </Descriptions.Item>

          <Descriptions.Item label="Address">
            {personnel.partyId.address}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {personnel.departmentId.departmentName}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {personnel.designationId.designationName}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          bordered
          title="Job Details"
          extra={<Button type="primary">Edit</Button>}
        >
          <Descriptions.Item label="Date of Joining">
            {personnel.jobDetailId.doj}
          </Descriptions.Item>
          <Descriptions.Item label="Qualification">
            {" "}
            {personnel.jobDetailId.qualification}
          </Descriptions.Item>
          <Descriptions.Item label="Experience">
            {" "}
            {personnel.jobDetailId.experience}
          </Descriptions.Item>
          <Descriptions.Item label="Employee type">
            {personnel.jobDetailId.empType}
          </Descriptions.Item>
          <Descriptions.Item label="Shift Time">
            {personnel.jobDetailId.shiftTime}
          </Descriptions.Item>
          <Descriptions.Item label="Job Type">
            {personnel.jobDetailId.jobType}
          </Descriptions.Item>
          <Descriptions.Item label="Bio EMP Card">
            {personnel.jobDetailId.bioEmpCode}
          </Descriptions.Item>
          <Descriptions.Item label="Provident A/C">
            {personnel.jobDetailId.pfAccNo}
          </Descriptions.Item>
          <Descriptions.Item label="ESIC No.">
            {personnel.jobDetailId.esicNo}
          </Descriptions.Item>
      
        </Descriptions>
       
      </Col>
    </Row>
  );
};

export default ShowPersonnelDetailsForm;
