import * as React from "react";
import { Row, Col, Statistic, Divider, Descriptions } from "antd";


const ShowBlogCategoriesDetailsForm: React.FC<{ id: number }> = (props) => {
  //const [parameters, setParameters] = React.useState([]);

  // const {
  //   isLoading,
  //   error,
  //   data: data,
  // } = useQuery(["LeadsRequest", props.id], async () => getLeadsById(props.id));
  // const {

  //   data: Instruments,
  // } = useQuery(['Instruments', data?.LeadsRequest?.instrumentId], async () => getInstrumentById(data?.LeadsRequest?.instrumentId), {
  //   refetchOnWindowFocus: false,
  //   enabled: !!data?.LeadsRequest?.instrumentId,
  //   // keepPreviousData: true,
  //   // staleTime: 5000,
  //   // refetchOnMount: false,
  // });
  // const {

  //   data: company,
  // } = useQuery(['company', data?.LeadsRequest?.userId], async () => getCompanyByUser(data?.LeadsRequest?.userId), {
  //   refetchOnWindowFocus: false,
  //   enabled: !!data?.LeadsRequest?.userId,
  //   // keepPreviousData: true,
  //   // staleTime: 5000,
  //   // refetchOnMount: false,
  // });

  // const {

  //   data: parameters,
  // } = useQuery(['parameters', data?.LeadsRequest?.parameterId], async () => getActiveParameterByInstruments(data?.LeadsRequest?.instrumentId), {
  //   refetchOnWindowFocus: false,
  //   enabled: !!data?.LeadsRequest?.instrumentId,
  //   // keepPreviousData: true,
  //   // staleTime: 5000,
  //   // refetchOnMount: false,
  // });
  //data.parameterId = data?.CalibarationRequest?.parameterId;
  // const {
  //   isLoading,
  //   error,
  //   data: instrument,
  // } = useQuery(['instrument', props.id], async () => getInstrumentById(props.id));
  // console.log(parameters, 'pr');
  // console.log(Instruments, 'in');
  // console.log(data, 'data');
  // console.log(company, 'company');

  // console.log(data, 'data');

  // const modalContext = React.useContext(ShowLeadsDetailsModalContext);
  // const [formLoading, setFormLoading] = React.useState(false);
  // const updateCompanyInstrumentMutation = useMutation(
  //   (values) => request('user.company.Leads.update', { id: props.id }, values),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries('Leads');
  //     },
  //   }
  // );
  // const mutateParameters = useMutation((data.id: number) => getActiveParameterByInstruments(id));

  // // Get Parameters Function
  // const getInstrumentsParameters = async (id: number) => {

  //   console.log(id, "parameters");
  //   //console.log(parameters, "parameters");
  //   mutateParameters
  //     .mutateAsync(id)
  //     .then((payload) => {
  //       setParameters(payload?.InstrumentParameters);
  //     })
  //     .catch((e: any) => {
  //       console.log("error", e);
  //     });
  // };
  //const s = data?.Leads.status;
  // const [currentStatus, setCurrentStatus] = useState(
  //   s === "new"
  //     ? 0
  //     : s === "ongoing"
  //     ? 1
  //     : s === "inReview"
  //     ? 2
  //     : s === "reportGeneration"
  //     ? 3
  //     : s === "completed"
  //     ? 4
  //     : 5
  // );


  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Statistic title="Leads Request No" value={"SKY/REQ/"} />
        </Col>
        <Col span={12}>
          <Statistic title="Requested Date" />
        </Col>
      </Row>
      <Divider orientation="left">Company Info</Divider>

      <Col span={24}>
        <Divider orientation="left">Leads Status</Divider>
      </Col>
      <Divider orientation="left">Instrument Details</Divider>
      <Descriptions bordered size={"small"}></Descriptions>

      <Divider orientation="left">Remarks/Description</Divider>

      <Divider orientation="left">Requested By</Divider>
    </>
  );
};

export default ShowBlogCategoriesDetailsForm;
