import * as React from "react";
import {
  Form,
  Button,
  message,



  Input,

  Select,
} from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import { InfoCircleOutlined } from "@ant-design/icons";
import { queryClient } from "src";
import { ManageCredentialModalContext } from "../Modal/ManageCredential";

import { useQuery } from "react-query";


import { getCredentialById } from "../Requests";

import { getAllActiveWork } from "src/modules/Work/Requests";


const ManageCredentialForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageCredentialModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const manageCredentialMutation = useMutation(
    (values: any) =>
      request("admin.credential.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("credential");
      },
    }
  );
  const {

    data: credential,

  } = useQuery(
    ["credential", props.id],
    async () => getCredentialById(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: work } = useQuery(["work"], async () => getAllActiveWork(), {
    refetchOnWindowFocus: false,
  });

  const onFinish = async (values: any) => {
    setFormLoading(true);

    manageCredentialMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Credential has been successfully managed.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();





  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={credential}
    >
      <Form.Item
        label="Project/Product"
        name="workId"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "work",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Project/Product",
          },
        ]}
        hasFeedback
      >
        <Select placeholder="Select Project/Product">
          {work &&
            work?.map((item: any, i: number) => {
              return (
                <Select.Option value={item?.id} key={i}>
                  {item?.title}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Platform Name"
        name="platformName"
        style={{
          fontWeight: "bold",
        }}
        tooltip={{
          title: "Platform Name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ whitespace: true }, { min: 3 }]}
        hasFeedback
      >
        <Input placeholder="Enter Platform Name" />
      </Form.Item>
      <Form.Item
        label="Platform Link"
        name="platformLink"
        style={{
          fontWeight: "bold",
        }}
        tooltip={{
          title: "Platform Link",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Platform Link",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <Input placeholder="Enter Platform Link" />
      </Form.Item>
      <Form.Item
        label="Username"
        name="username"
        style={{
          fontWeight: "bold",
        }}
        tooltip={{
          title: "User name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter username",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <Input placeholder="Enter username" />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        style={{
          fontWeight: "bold",
        }}
        tooltip={{
          title: "password",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter password",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <Input placeholder="Enter password" />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Update Credential
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageCredentialForm;
