import { Avatar, Card, Row, Space, Typography } from "antd";
import { imLogoIcon } from "../../../../constants/logo";
import { s3Path } from "../../../../utils/s3Config/s3upload";
import {
  ProfileOutlined,
  PhoneOutlined,

  GlobalOutlined,
  MailOutlined,
  FlagOutlined,
  UserOutlined,
  PushpinOutlined,
} from "@ant-design/icons";

import PartyManageModal from "../../Modal/ManageParty";
import ManageConcernedPersonModal from "../../Modal/ManageConcernedPerson";

const PartyCard: React.FC<{ data: any }> = ({ data }) => {
  // console.log(data);

  return (
    <Card size="small">
      <Space.Compact direction="horizontal">
        <Row justify={"space-between"}>
          <Space.Compact direction="vertical">
            <Row>
              <Avatar
                src={
                  // s3Path + data.imageUrl
                  <img
                    src={data.imageUrl ? s3Path + data.imageUrl : imLogoIcon}
                    alt={data?.personName}
                    // style={{ width: "50%", height: "100%" }}
                  />
                }
                // alt="W"
              />
              <Typography.Text strong>{data?.companyName}</Typography.Text>
            </Row>

            <Typography.Text style={{ fontWeight: "bold" }}>
              <UserOutlined /> {data?.personName}
              <Typography.Text type="danger" code>
                {data?.partyType}
              </Typography.Text>
            </Typography.Text>
            {/* <Typography.Text>
          {data?.companyName}
        </Typography.Text> */}
            <Typography.Text>
              <PushpinOutlined />
              {data?.address}
            </Typography.Text>
            <Typography.Text>
              <PhoneOutlined />
              +91-{data?.phoneNo}, <MailOutlined /> {data?.email}
            </Typography.Text>
            <Typography.Text>
              <GlobalOutlined /> {data?.website}
            </Typography.Text>
            <Typography.Text>
              <ProfileOutlined /> {data?.gstInNo}
            </Typography.Text>
          </Space.Compact>

          {/* <Space>
            {data.industry}
          </Space> */}
        </Row>
        <PartyManageModal id={data?._id || data?.id} buttonType={"text"} />
        <Space>
          {data.concernedpersons &&
            data.concernedpersons?.map((item: any, i: number) => {
              return (
                <Card size="small">
                  <Space.Compact direction="vertical">
                    <Typography.Text strong>
                      <UserOutlined />
                      {item?.name}
                      <ManageConcernedPersonModal id={item?.id} />
                    </Typography.Text>
                    <Typography.Text>
                      <FlagOutlined />
                      {item?.designation}
                    </Typography.Text>
                    <Typography.Text>
                      {" "}
                      <PhoneOutlined href={"tel:+91-" + item?.mobile} />
                      +91- {item?.mobile}
                    </Typography.Text>
                    <Typography.Text>
                      <MailOutlined />
                      {item?.email}
                    </Typography.Text>
                  </Space.Compact>
                </Card>
              );
            })}
        </Space>
      </Space.Compact>
    </Card>
  );
};

export default PartyCard;
