import * as React from "react";
import {
  Form,
  Button,
  Select,
  Col,
  Row,
  message,
  Typography,
  Card,

} from "antd";

import { InfoCircleOutlined } from "@ant-design/icons";

import { ManageAssignedPersonModalContext } from "../Modal/assignedPerson";
import LocalStorageService from "../../../utils/local-storage";
import { useMutation, useQuery } from "react-query";
import { request } from "../../../utils/services";
import { queryClient } from "../../..";



import { getLeadById } from "../Requests";

import {
  getAllPersonnel,
  getPersonnelById,
} from "src/modules/HR/Personnel/Requests";

const { Text, Title } = Typography;
const CreateAssignPersonForm: React.FC<{ id?: string }> = (props) => {
  const modalContext = React.useContext(ManageAssignedPersonModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const [isPersonSelected, setIsPersonSelected] = React.useState(false);
  const [personData, setPersonData] = React.useState<Object | any>({});
  const assignPersonMutation = useMutation(
    (values: any) => request("admin.lead.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("lead");
      },
    }
  );

  const {

    data: lead,

  } = useQuery(
    ["lead", props.id],
    async () => getLeadById(props.id ? props.id : "0"),
    {
      refetchOnWindowFocus: false,
    }
  );
  const [isAssigned, setIsAssigned] = React.useState(
    lead.assignedPersonId ? true : false
  );

  const { data: person } = useQuery(
    ["peronnnel"],
    async () => getAllPersonnel(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    values.updatedBy = LocalStorageService.getAccount().id;
    values.leadId = props.id;
    values.leadStatus = "Assigned";

    setFormLoading(true);
    console.log(values);

    assignPersonMutation
      .mutateAsync(values)
      .then((payload: any) => {
        setFormLoading(false);
        message.success(payload.message);
        form.resetFields();
        modalContext.setIsModalVisible(false);
        setIsAssigned(true);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e.message);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const mutatePerson = useMutation((id: string) => getPersonnelById(id));
  const OnPersonSelect = (i: any) => {
    setIsPersonSelected(true);

    mutatePerson
      .mutateAsync(i)
      .then((payload) => {
        setPersonData(payload);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  React.useEffect(() => {
    if (lead.assignedPersonId) {
      OnPersonSelect(lead.assignedPersonId);
      // OnPaymentTermsSelect(occupant.paymentTermsId);
    }
  }, []);
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={lead}
    >
      <Form.Item
        label="Assigned Person"
        style={{ fontWeight: "bold" }}
        name="assignedPersonId"
        tooltip={{
          title: "Person ",
          icon: <InfoCircleOutlined />,
        }}
      >
        {isPersonSelected ? (
          <Card>
            <Row justify={"space-between"} align={"middle"}>
              <Title level={3}>{personData?.partyId?.personName}</Title>
              <Col>
                <Row>
                  <Text code>{personData.partyId?.phoneNo}</Text>
                  <Text code>{personData.partyId?.address}</Text>
                </Row>
              </Col>
              {!isAssigned && (
                <Button
                  onClick={() => {
                    form.setFieldsValue({
                      personId: null,
                    });
                    setIsPersonSelected(false);
                  }}
                  type="dashed"
                >
                  Change Person
                </Button>
              )}
            </Row>
          </Card>
        ) : (
          <Select
            onSelect={OnPersonSelect}
            showSearch
            size="large"
            popupMatchSelectWidth
            // style={{ minHeight: 150 }}
            placeholder="Select Person"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input)
            }
          >
            {person &&
              person?.map((item: any, i: number) => {
                return (
                  <Select.Option
                    value={item?.id}
                    key={i}
                  // disabled={item.availability === 0 ? true : false}
                  >
                    <Row justify={"space-between"} align={"top"}>
                      <h4>{item?.partyId?.personName}</h4>
                      <Col>
                        <Row>
                          <Text code>{item.partyId?.phoneNo}</Text>
                          <Text code> {item.partyId?.address}</Text>
                        </Row>
                      </Col>
                    </Row>
                  </Select.Option>
                );
              })}
          </Select>
        )}
      </Form.Item>


      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Assign Person
        </Button>
      </Form.Item>
      {/* )} */}
    </Form>
  );
};
export default CreateAssignPersonForm;
