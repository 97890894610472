
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Space, Table, Tag } from "antd";

import { getAllService } from "../Requests";
import ServiceViewModal from "../Modal/ShowServiceDetails";
import ManageServiceModal from "../Modal/ManageService";
// import { getAccessAuthrorization } from "src/components/access";

interface DataType {
  id: number;
  name: any;
}
// type DataIndex = keyof DataType;
const ServiceList: React.FC = () => {

  // let accessAuth = getAccessAuthrorization("service");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["service"], () => getAllService(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <ServiceViewModal id={id} />
          <ManageServiceModal id={id} />
          {/* {accessAuth[0].includes("Edit") && <ManageServiceModal id={id} />} */}
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default ServiceList;
