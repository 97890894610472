import {
  Avatar,
  Badge,
  Button,
  Card,
  Checkbox,
  DatePicker,
  Input,
  message,
  Popover,
  Row,
  Segmented,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  Typography,
} from "antd";
import * as React from "react";
import {
  SlackOutlined,
  UserOutlined,
  CalendarOutlined,
  TagsOutlined,
  MinusCircleFilled,
  UsergroupAddOutlined,
  RestOutlined,
} from "@ant-design/icons";
import { useMutation, useQueries, useQuery } from "react-query";
import { request } from "src/utils/services";
import { queryClient } from "src";
import LocalStorageService from "src/utils/local-storage";
import { getTaskTableAssignedBy } from "src/modules/Task/Requests";

import { ColumnsType } from "antd/es/table";

import { s3Path } from "src/utils/s3Config/s3upload";
import imLogo from "src/assets/img/imlogo.png";
import { getAllActivePersonnel } from "src/modules/HR/Personnel/Requests";

import { getAllActiveTaskType } from "src/modules/TaskType/Requests";
import dayjs from "dayjs";
import CreateTaskTypeModal from "src/modules/TaskType/Modal/CreateTaskType";
import { getAllActiveWork } from "src/modules/Work/Requests";
import { getAllLead } from "src/modules/Leads/Requests";
import TasksForPersonnel from "./tasktableForPersonnel";
import TaskManageModal from "src/modules/Task/Modal/ManageTask";
import CardViewTaskModal from "src/modules/Task/Modal/CardViewTask";
import ReplyListByTaskId from "src/modules/Reply/Component/Home/ListByTaskId";

const TasksTable: React.FC = () => {
  const [user, setUser] = React.useState(LocalStorageService.getAccount());
  const inputRefs = React.useRef<(HTMLInputElement | any)[]>([]); // Ref array for inputs
  const [isCheck, setIsCheck] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  
  const [hideColumn, setHideColumn] = React.useState({
    status: false,
    assignedPersonId: false,
    dueDate: false,
    type: false,
    workId: false,
    leadId: false,
  });
  const [hoveredRow, setHoveredRow] = React.useState<number | null>(null);
  const [isClicked, setIsClicked] = React.useState(false);
  const [filteredTByP, setFilteredTByP] = React.useState<[] | any>([]);
  const [selectedFilter, setSelectedFilter] = React.useState<string>("To Do");

  // const [open, setOpen] = useState(false);

  const createTaskMutation = useMutation(
    (values: any) =>
      request(
        values.id ? "admin.task.update" : "admin.task.create",
        values.id ? { id: values.id } : {},
        values
      ),

    {
      onSuccess: () => {
        queryClient.invalidateQueries("task");

        queryClient.invalidateQueries("taskByPersonnel");
        refetch();
      },
    }
  );

  const {
    isLoading,
    isError,
    data: taskByPersonnel,
    refetch,
  } = useQuery(
    ["taskByPersonnel", user.id],
    () => getTaskTableAssignedBy(user.id),
    {
      // refetchOnWindowFocus: true,

      // keepPreviousData: true,
      staleTime: 5000,
      onSuccess: (data) => {
        const filteredData = data?.filter(
          (task: any) =>
            task?.isActive &&
            (selectedFilter === "All" || task?.status === selectedFilter)
        );
        setFilteredTByP([
          ...filteredData,
          ...(selectedFilter === "New" ? [{ title: "" }] : []),
        ]);
      },
    }
  );

  const results = useQueries([
    {
      queryKey: ["personnel"],
      queryFn: getAllActivePersonnel,
    },
    {
      queryKey: ["taskType"],
      queryFn: getAllActiveTaskType,
    },
    {
      queryKey: ["work"],
      queryFn: getAllActiveWork,
    },
    {
      queryKey: ["lead"],
      queryFn: getAllLead,
    },
  ]);
  // Access results
  const [personnel, tag, work, lead] = results;

  const onEnter = async (values: any) => {
    values.createdBy = user.id;

    createTaskMutation
      .mutateAsync(values)
      .then(() => {})
      .catch((e: any) => {
        // setFormLoading(false);
        message.error(e);
      });
  };

  const hide = () => {
    setOpen(false);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  //handle show column for if needed
  const showColumn = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  //handle checkbox for showing column
  const columnCheckbox = (column: string, checked: boolean) => {
    setHideColumn((prev) => ({ ...prev, [column]: checked }));
  };

  //task Title and view column
  const taskTitle = (
    <>
      <Row justify={"space-between"}>
        <Typography.Text> Task Title</Typography.Text>
        <Popover
          placement="rightTop"
          content={
            <Space.Compact direction="vertical">
              <Checkbox
                onClick={(e: any) => columnCheckbox("status", e.target.checked)}
              >
                {" "}
                Status{" "}
              </Checkbox>

              <Checkbox
                onClick={(e: any) =>
                  columnCheckbox("assignedPersonId", e.target.checked)
                }
              >
                {" "}
                Assignee{" "}
              </Checkbox>

              <Checkbox
                onClick={(e: any) =>
                  columnCheckbox("dueDate", e.target.checked)
                }
              >
                {" "}
                Due Date{" "}
              </Checkbox>

              <Checkbox
                onClick={(e: any) => columnCheckbox("type", e.target.checked)}
              >
                {" "}
                Tag{" "}
              </Checkbox>
              <Checkbox
                onClick={(e: any) => columnCheckbox("workId", e.target.checked)}
              >
                {" "}
                Work{" "}
              </Checkbox>
              <Checkbox
                onClick={(e: any) => columnCheckbox("leadId", e.target.checked)}
              >
                {" "}
                lead{" "}
              </Checkbox>
              <a onClick={hide}>Close</a>
            </Space.Compact>
          }
          title="Title"
          trigger="hover"
          open={open}
          onOpenChange={showColumn}
        >
          <Button type="primary" size="small">
            Show Column
          </Button>
        </Popover>
      </Row>
    </>
  );

  // task priority option
  const priorityOption = [
    {
      label: (
        <Tag bordered={false} color={"red-inverse"}>
         Urgent
        </Tag>
      ),
      value: "Urgent",
    },
    {
      label: <Tag color={"orange"}>High</Tag>,
      value: "High",
    },
    {
      label: <Tag color={"purple"}>Medium</Tag>,
      value: "Medium",
    },
    {
      label: <Tag color={"cyan"}>Low</Tag>,
      value: "Low",
    },
    {
      label: <Tag color={"lime"}>Trivial</Tag>,
      value: "Trivial",
    },
  ];

  //task status option
  const statusOption = [
    {
      label: (
        <Tag
          bordered={false}
          style={{
            backgroundColor: "goldenrod",
            fontSize: 14,
            borderRadius: 10,
          }}
          //   color={"volcano"}
        >
          New
        </Tag>
      ),
      value: "New",
      disabled: true,
    },
    {
      label: (
        <Tag
          bordered={false}
          style={{
            backgroundColor: "lightgrey",
            fontSize: 14,
            borderRadius: 10,
          }}
          //   color={"volcano"}
        >
          To Do
        </Tag>
      ),
      value: "To Do",
    },
    {
      label: (
        <Tag
          bordered={false}
          style={{
            fontSize: 14,
            borderRadius: 10,
            backgroundColor: "lightblue",
          }}
        >
          In Progress
        </Tag>
      ),
      value: "In Progress",
    },
    {
      label: (
        <Tag
          bordered={false}
          // color={"yellow"}
          style={{
            fontSize: 14,
            borderRadius: 10,
            backgroundColor: "lightcoral",
          }}
        >
          On Hold
        </Tag>
      ),
      value: "On Hold",
    },
    {
      label: (
        <Tag
          bordered={false}
          style={{
            fontSize: 14,
            borderRadius: 10,
            backgroundColor: "lightsalmon",
          }}
        >
          In Review
        </Tag>
      ),
      value: "In Review",
    },
    {
      label: (
        <Tag
          bordered={false}
          // color={"green"}
          style={{
            fontSize: 14,
            borderRadius: 10,
            backgroundColor: "lightgreen",
          }}
        >
          Done
        </Tag>
      ),
      value: "Done",
    },
  ];
  // console.log(filteredTByP);
  const column = [
    {
      title: "A",
      dataIndex: "isChecked",
      key: "isChecked",
      // fixed: 'left',
      width: "2%",

      render: (text: any, rec: any) => {
        setIsCheck(text);
        return (
          <Space>
            {/* < RestOutlined
                         style={{ fontSize: 16 }}
                         onClick={() => {
                            setIsDelete(!isDelete)
                            onEnter({...rec,isActive:false}) }} /> */}
            <Checkbox
              checked={text ? text : isCheck}
              style={{ fontSize: 16 }}
              onChange={(e) => {
                // const isChecked =e.target.checked
                setIsCheck(!isCheck);
                onEnter({ ...rec, isChecked: e.target.checked });
              }}
            />
          </Space>
        );
      },
    },
    {
      title: taskTitle,
      dataIndex: "title",
      key: "title",
      // fixed: 'left',
      width: "30%",

      render: (text: any, rec: any, i: any) => {
        // let isClicked =false
        return (
          <Space>
            {hoveredRow === rec.id && isClicked ? (
              <Card hoverable size="small" style={{ overflow: "visible" }}>
                <Input
                  variant="borderless"
                  placeholder="New Task"
                  size="middle"
                  style={{
                    padding: 2,
                    textDecoration: rec?.isChecked ? "line-through" : "none",

                    fontWeight: 600,

                    width: "30vw",
                    overflow: "auto",
                  }}
                  defaultValue={rec.title}
                  ref={(ref) => (inputRefs.current[rec.title] = ref)} // Assign ref to the input
                  onBlur={() => setIsClicked(false)}
                  onChange={(e) => {
                    // setIsClicked(false)
                    rec.id && onEnter({ ...rec, title: e.target.value });
                  }}
                  onPressEnter={(e: any) => {
                    if (selectedFilter === "New") {
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      if (e.target.value.trim() && rec.id)
                        setFilteredTByP((prev: any) => {
                          // Update existing task
                          let updatedTasks = prev
                            .map((t: any) =>
                              t.id === rec.id ? { ...t, title: rec.title } : t
                            )
                            .filter((t: any) => t.title !== "");

                          const newTask: any = { title: "" };
                          updatedTasks.push(newTask);

                          setTimeout(
                            () => inputRefs.current[newTask.title]?.focus(),
                            0
                          );
                          return updatedTasks;
                        });
                      if (e.target.value.trim() && !rec.id)
                        setFilteredTByP((prev: any) => {
                          let updatedTasks = prev.map((t: any) =>
                            t.id === rec.id ? { ...t, title: rec.title } : t
                          );

                          const newTask: any = { title: "" };

                          let result = [...updatedTasks, newTask];

                          setTimeout(
                            () => inputRefs.current[newTask.title]?.focus(),
                            0
                          );
                          return result;
                        });
                    }
                  }}
                />
              </Card>
            ) : rec.id ? (
              <Row style={{ overflow: "visible" }}>
                {
                  <Popover trigger={"click"} 
                  content={<Space direction="vertical">
                      {priorityOption.map((ele)=>{
                        return<Button  size="small" type="text" onClick={()=>onEnter({ ...rec, priority: ele.value })}>{ele.label}</Button>})}
                  </Space>} placement="bottom" arrow={false}>
                    <Tag
                      color={
                        rec?.priority === "Urgent"
                          ? "red-inverse"
                          : rec?.priority === "High"
                          ? "orange"
                          : rec?.priority === "Medium"
                          ? "purple"
                          : rec?.priority === "Low"
                          ? "cyan"
                          : "lime"
                      }
                      style={{
                        // fontSize: 13,
                        borderRadius: 7,
                        // fontFamily:"monospace",
                        // fontWeight:"bolder",
                        // fontVariant:"ruby"
                      }}
                    >
                      {rec?.priority && rec?.priority[0]}
                    </Tag>
                  </Popover>
                }
                <Typography.Text
                  onClick={() => {
                    setIsClicked(!isClicked);
                  }}
                  // color={
                  //   rec?.priority === "Urgent"
                  //     ? "red-inverse"
                  //     : rec?.priority === "High"
                  //     ? "orange-inverse"
                  //     : rec?.priority === "Medium"
                  //     ? "yellow-inverse"
                  //     : rec?.priority === "Low"
                  //     ? "lime-inverse"
                  //     : "warning"
                  // }
                  strong
                  style={{
                    textDecoration: rec?.isChecked ? "line-through" : "none",
                    // fontSize: 14,
                  }}
                >
                  {rec?.title}
                </Typography.Text>
                {hoveredRow === rec?.id && <TaskManageModal id={rec.id} />}
                {/* <Tag
                      
                      color={
                        rec?.priority === "Urgent"
                          ?"red-inverse"
                          : rec?.priority === "High"
                          ? "orange"
                          : rec?.priority === "Medium"
                          ? "purple"
                          : rec?.priority === "Low"
                          ? "cyan"
                          : "lime"
                      }
                      
                       style={{
                          fontSize: 13,
                          borderRadius:7,
                          // fontFamily:"monospace",
                          // fontWeight:"bolder",
                          fontVariant:"ruby"
                        }} 
                        >
                          {rec?.priority[0]}
                        </Tag> */}
              </Row>
            ) : (
              <Input
                variant="borderless"
                placeholder="New Task"
                size="middle"
                style={{
                  padding: 2,
                  textDecoration: rec?.isChecked ? "line-through" : "none",

                  fontWeight: 600,

                  width: "30vw",
                  overflow: "auto",
                }}
                defaultValue={rec.title}
                ref={(ref) => (inputRefs.current[rec.title] = ref)} // Assign ref to the input
                onBlur={(e) => {
                  e.target.value && onEnter({ ...rec, title: e.target.value });
                }}
                onPressEnter={(e: any) => {
                  if (selectedFilter === "New") {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    if (e.target.value.trim() && rec.id)
                      setFilteredTByP((prev: any) => {
                        // Update existing task
                        let updatedTasks = prev
                          .map((t: any) =>
                            t.id === rec.id ? { ...t, title: rec.title } : t
                          )
                          .filter((t: any) => t.title !== "");

                        const newTask: any = { title: "" };
                        updatedTasks.push(newTask);

                        setTimeout(
                          () => inputRefs.current[newTask.title]?.focus(),
                          0
                        );
                        return updatedTasks;
                      });
                    if (e.target.value.trim() && !rec.id)
                      setFilteredTByP((prev: any) => {
                        let updatedTasks = prev.map((t: any) =>
                          t.id === rec.id ? { ...t, title: rec.title } : t
                        );

                        const newTask: any = { title: "" };

                        let result = [...updatedTasks, newTask];

                        setTimeout(
                          () => inputRefs.current[newTask.title]?.focus(),
                          0
                        );
                        return result;
                      });
                  }
                }}
              />
            )}

            {rec?.id && <ReplyListByTaskId taskId={rec.id} />}
          </Space>
        );
      },
      filters: [
        { text: "Urgent", value: "Urgent" },
        { text: "High", value: "High" },
        { text: "Medium", value: "Medium" },
        { text: "Low", value: "Low" },
        { text: "Trivial", value: "Trivial" },
      ], // Define filters for priority
      filterSearch: true, // Enables search functionality in filter dropdown
      onFilter: (value: any, record: { priority: any }) =>
        record.priority === value, // Filter logic
    },
    hideColumn.status && {
      title: (
        <>
          <SlackOutlined /> Status
        </>
      ),
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (_: any, rec: any, i: any) =>
        hoveredRow === rec.id ? (
          <Select
            defaultValue={rec.status}
            placeholder={"Select Status"}
            variant="borderless"
            suffixIcon={<></>}
            onChange={(value) => {
              value && onEnter({ ...rec, status: value });
              // You can also update a state or trigger an action with the selected value
            }}
            options={statusOption}
          />
        ) : (
          <Tag
            style={{
              fontSize: 14,
              borderRadius: 10,
              backgroundColor:
                selectedFilter === "New"
                  ? "goldenrod"
                  : selectedFilter === "To Do"
                  ? "lightgrey"
                  : selectedFilter === "In Progress"
                  ? "lightblue"
                  : selectedFilter === "On Hold"
                  ? "lightcoral"
                  : selectedFilter === "In Review"
                  ? "lightsalmon"
                  : "lightgreen",
            }}
          >
            {selectedFilter}
          </Tag>
        ),
    },

    hideColumn.assignedPersonId && {
      title: (
        <>
          <UserOutlined /> Assignee
        </>
      ),
      dataIndex: "assignedPersonId",
      key: "assignedPersonId",
      width: 170,
      filters: personnel.data
        ? personnel.data.map((item: any) => ({
            text: item?.partyId?.personName, // Display name
            value: item?.id, // Unique identifier for filtering
          }))
        : [],
      filterSearch: true, // Enable search in the filter dropdown
      onFilter: (value: any, record: { assignedPersonId: any }) =>
        record?.assignedPersonId === value, // Filtering logic

      render: (_: any, rec: any) => (
        <Space size={10}>
          <Select
            placeholder="Select Assignee"
            variant="borderless"
            defaultValue={_}
            //   virtual={false}
            suffixIcon={<></>}
            //   mode="multiple"

            onChange={(value) => {
              value && onEnter({ ...rec, assignedPersonId: value });
              // You can also update a state or trigger an action with the selected value
            }}
            //   onSelect={(e)=>{console.log(e)}}
            size="large"
          >
            {personnel.data &&
              personnel.data?.map((item: any, i: number) => {
                return (
                  <Select.Option value={item?.id} key={i}>
                    <Tag
                      bordered={false}
                      style={{
                        backgroundColor: "lightgrey",
                        fontSize: 14,
                        borderRadius: 10,
                      }}
                    >
                      <Avatar
                        size={"small"}
                        src={
                          <img
                            src={
                              item?.imageUrl ? s3Path + item?.imageUrl : imLogo
                            }
                            // alt={item?.personName}
                          />
                        }
                      />{" "}
                      {item?.partyId?.personName}
                    </Tag>
                  </Select.Option>
                );
              })}
          </Select>
        </Space>
      ),
    },
    hideColumn.dueDate && {
      title: (
        <>
          <CalendarOutlined /> Due
        </>
      ),
      dataIndex: "dueDate",
      key: "dueDate",
      width: "25%",
      render: (text: any, rec: any) => (
        <DatePicker
          variant="filled"
          onChange={(value) => {
            console.log(value);
            value && onEnter({ ...rec, dueDate: value });
            // You can also update a state or trigger an action with the selected value
          }}
          width={"20%"}
          defaultValue={dayjs(text)}
          format={"MMM DD, YYYY"}
        />
      ),
    },

    hideColumn.type && {
      title: (
        <>
          <TagsOutlined rotate={270} /> Tag
        </>
      ),
      dataIndex: "type",
      key: "type",
      width: 150,
      // fixed:true,
      render: (_: any, rec: any) => (
        <Space size={10}>
          <Select
            placeholder="Select tag"
            variant="borderless"
            defaultValue={_}
            //   virtual={false}
            dropdownRender={(menu) => (
              <>
                <Row justify={"end"}>
                  <CreateTaskTypeModal />
                </Row>
                {menu}
              </>
            )}
            suffixIcon={<></>}
            mode="multiple"
            onChange={(value) => {
              value && onEnter({ ...rec, type: value });
            }}
            size="large"
            style={{ width: "24vh" }}
          >
            {tag.data &&
              tag.data?.map((item: any, i: number) => {
                return (
                  <Select.Option value={item?.id} key={i}>
                    <Tag
                      color={item.color}
                      bordered={false}
                      style={{ borderRadius: 10, fontWeight: "bold" }}
                    >
                      {" "}
                      {item?.title}
                    </Tag>
                  </Select.Option>
                );
              })}
          </Select>
        </Space>
      ),
    },
    hideColumn.workId && {
      title: (
        <>
          <MinusCircleFilled /> Work
        </>
      ),
      dataIndex: "workId",
      key: "workId",
      width: 200,
      // fixed:"right",
      filters: work?.data
        ? work?.data.map((item: any) => ({
            text: item?.title, // Display name
            value: item?.id, // Unique identifier for filtering
          }))
        : [],
      filterSearch: true, // Enable search in the filter dropdown
      onFilter: (value: any, record: { workId: any }) =>
        record?.workId === value, // Filtering logic

      render: (_: any, rec: any) => (
        <Space size={10}>
          <Select
            placeholder="Select Work"
            variant="borderless"
            defaultValue={_}
            //   virtual={false}
            suffixIcon={<></>}
            //   mode="multiple"

            onChange={(value) => {
              value && onEnter({ ...rec, workId: value });
              // You can also update a state or trigger an action with the selected value
            }}
            size="large"
          >
            {work?.data &&
              work?.data?.map((item: any, i: number) => {
                return (
                  <Select.Option value={item?.id} key={i}>
                    <Tag
                      bordered={false}
                      style={{
                        backgroundColor: "wheat",
                        fontSize: 14,
                        borderRadius: 10,
                      }}
                    >
                      {item?.title}
                    </Tag>
                  </Select.Option>
                );
              })}
          </Select>
        </Space>
      ),
    },
    hideColumn.leadId && {
      title: (
        <>
          <UsergroupAddOutlined /> Lead
        </>
      ),
      dataIndex: "leadId",
      key: "leadId",
      width: 200,
      filters: lead?.data
        ? lead?.data.map((item: any) => ({
            text: item?.title, // Display name
            value: item?.id, // Unique identifier for filtering
          }))
        : [],
      filterSearch: true, // Enable search in the filter dropdown
      onFilter: (value: any, record: { leadId: any }) =>
        record?.leadId === value, // Filtering logic

      // hidden:hideColumn.leadId,
      render: (_: any, rec: any) => (
        <Space size={10}>
          <Select
            placeholder="Select Lead"
            variant="borderless"
            defaultValue={_}
            suffixIcon={<></>}
            onChange={(value) => {
              value && onEnter({ ...rec, leadId: value });
              // You can also update a state or trigger an action with the selected value
            }}
            size="large"
          >
            {lead?.data &&
              lead?.data?.map((item: any, i: number) => {
                return (
                  <Select.Option value={item?.id} key={i}>
                    <Tag
                      bordered={false}
                      style={{
                        backgroundColor: "wheat",
                        fontSize: 14,
                        borderRadius: 10,
                      }}
                    >
                      {item?.title}
                    </Tag>
                  </Select.Option>
                );
              })}
          </Select>
        </Space>
      ),
    },
  ].filter(Boolean);

  // console.log({filteredTByP})

  const statusLabels = [
    "New",
    "To Do",
    "In Progress",
    "On Hold",
    "In Review",
    "Done",
  ] as const;

  // Assuming these are your filtered counts for each status
  const activeData = taskByPersonnel?.filter((task: any) => task?.isActive);

  const statusOptions = statusLabels.map((status) => ({
    label: (
      <Badge
        size="small"
        count={activeData.filter((item: any) => item.status === status).length}
      >
        <span>{status}</span>
      </Badge>
    ),
    value: status,
  }));

  // const filteredData = filteredTByP.filter((item:any) => item.status === selectedFilter);

  const operations = (
    <>
      <Segmented
        options={statusOptions}
        value={selectedFilter}
        onChange={(value) => setSelectedFilter(value as string)}
      />
    </>
  );

  React.useEffect(() => {
    const filteredData = taskByPersonnel.filter(
      (task: any) =>
        task.isActive &&
        (selectedFilter === "All" || task.status === selectedFilter)
    );
    selectedFilter === "New"
      ? setFilteredTByP([...filteredData, { title: "" }])
      : setFilteredTByP([...filteredData]);
  }, [isDelete, selectedFilter]);

  if (isLoading || isError) {
    return <div>Loading</div>;
  }

  if (personnel.isLoading || tag.isLoading || work.isLoading) {
    return <div>Loading...</div>;
  }

  if (personnel.isError || tag.isError || work.isError) {
    return <div>Error loading data</div>;
  }
  // const [form] = Form.useForm();
  return (
    <>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: "By me",
            key: "1",
            children: (
              <>
                <Row justify={"end"}>{operations}</Row>
                <br />
                <Table
                  size="small"
                  dataSource={filteredTByP}
                  columns={column as ColumnsType<any>}
                  // virtual
                  scroll={{ y: "70vh", x: "max-content" }}
                  // scroll={{ x: 2000,  }}
                  // sticky={{ offsetHeader: 5 }}
                  // rowKey={(record) => record.title || Math.random().toString()}
                  // rowKey={(record, index) => `${index}`}
                  pagination={false}
                  onRow={(record) => ({
                    onMouseEnter: () => setHoveredRow(record.id), // Set hovered row key
                    onMouseLeave: () => {
                      setHoveredRow(null);
                      setIsClicked(false);
                    }, // Clear hovered row key
                  })}
                />
              </>
            ),
          },
          {
            label: "For Me",
            key: "2",
            children: <TasksForPersonnel />,
          },
        ]}
      />
    </>
  );
};

export default TasksTable;
