

export default class LocalStorageService {
  /**
   * @type {string}
   */
  public static LOCAL_STORAGE_AUTH_TOKEN = "infinit_token";
  public static LOCAL_STORAGE_ACCOUNT = "infinit_account";
  public static LOCAL_STORAGE_ACCESS = "infinit_access";

  static isAuthenticated(): boolean {
    return LocalStorageService.getToken() !== null;
  }

  /**
   * Store package
   * @param {string} token
   * @returns {void}
   */
  public static storeToken(token: string) {
    localStorage.setItem(LocalStorageService.LOCAL_STORAGE_AUTH_TOKEN, token);
  }

  /**
   * Store token
   * @returns {string}
   */
  public static getToken(): string {
    return localStorage.getItem(
      LocalStorageService.LOCAL_STORAGE_AUTH_TOKEN
    ) as string;
  }

  /**
   * Remove token
   * @returns {string}
   */
  public static removeToken(): void {
    return localStorage.removeItem(
      LocalStorageService.LOCAL_STORAGE_AUTH_TOKEN
    );
  }

  /**
   * Remove account
   * @returns {string}
   */
  public static removeAccount(): void {
    return localStorage.removeItem(LocalStorageService.LOCAL_STORAGE_ACCOUNT);
  }

  /**
   * Store account
   * @param {object} accountData
   * @returns void
   */
  public static storeAccount(accountData: object) {
    localStorage.setItem(
      LocalStorageService.LOCAL_STORAGE_ACCOUNT,
      JSON.stringify(accountData)
    );
  }

  /**
   * Get account
   * @returns {object}
   */
  public static getAccount(): any {
    const accountItem = localStorage.getItem(
      LocalStorageService.LOCAL_STORAGE_ACCOUNT
    );
    if (accountItem) {
      return JSON.parse(accountItem);
    }
  }
  /**
   * Store access
   * @param {object} access
   * @returns {void}
   */
  public static storeAccess(access: object) {
    localStorage.setItem(
      LocalStorageService.LOCAL_STORAGE_ACCESS,
      JSON.stringify(access)
    );
  }

  /**
   * Store access
   * @returns {object}
   */
  public static getAccess(): any {
    const accessData = localStorage.getItem(
      LocalStorageService.LOCAL_STORAGE_ACCESS
    );
    if (accessData) {
      return JSON.parse(accessData);
    }
  }

  /**
   * Remove access
   * @returns {string}
   */
  public static removeAccess(): void {
    return localStorage.removeItem(LocalStorageService.LOCAL_STORAGE_ACCESS);
  }
}
