import * as React from "react";
import {  Button, Tooltip, Drawer } from "antd";

import {  RightSquareOutlined } from "@ant-design/icons";
import ManageTaskTableForm from "../Form/ManageTaskTable";
import ManageTaskTableForMeForm from "../Form/ManageTaskTableForMe";

export const ManageTaskModalForMeContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const TaskManageForMeModal: React.FC<{ id: string }> = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ManageTaskModalForMeContext.Provider value={{ setIsModalVisible }}>
      <Tooltip title="Open Task">
        <Button
          onClick={showModal}
          type="text"
          // shape="round"
          icon={<RightSquareOutlined />}
          // color="red"
          // variant="filled"
          // style={{backgroundColor:"gray"}}
          size="small"
        />
      </Tooltip>
      <Drawer width={"60vw"} placement="right" closable={false}  onClose={handleOk} open={isModalVisible}>
      <ManageTaskTableForMeForm id={id} />

      </Drawer>
      {/* <Modal
        title="Manage Task"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <ManageTaskTableForm id={id} />
      </Modal> */}
    </ManageTaskModalForMeContext.Provider>
  );
};

export default TaskManageForMeModal;
