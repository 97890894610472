import * as React from "react";
import {
  Form,
  Button,
  message,
  Select,
  Row,

  Input,
  Col,
  Switch,

} from "antd";
import { request } from "../../../../utils/services";
import { useMutation } from "react-query";

import { queryClient } from "../../../..";
import {
  InfoCircleOutlined,


} from "@ant-design/icons";

import { useQuery } from "react-query";
import TextArea from "antd/lib/input/TextArea";

import LocalStorageService from "../../../../utils/local-storage";
import { CreateBlogCommentModalContext } from "../Modal/CreateBlogComment";

import { getAllBlog } from "../../Blog/Requests";



const CreateBlogComment: React.FC = () => {
  const modalContext = React.useContext(CreateBlogCommentModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const createBlogCommentMutation = useMutation(
    (values: any) => request("admin.blogComment.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("blogComment");
      },
    }
  );
  const { data: blog } = useQuery(["blog"], async () => getAllBlog(), {
    refetchOnWindowFocus: false,
  });


  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;


    setFormLoading(true);

    createBlogCommentMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The BlogComment has been successfully created.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();


  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Row gutter={15}>
        <Col flex={2} span={12}>
          <Form.Item
            label="Name"
            name="name"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Name",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <Input
              placeholder="Enter Name"
            // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
        <Col flex={2} span={12}>
          <Form.Item
            label="Blog"
            style={{ fontWeight: "bold" }}
            name="blogId"
            tooltip={{
              title: "Blog",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Select
              showSearch
              placeholder="Select Blog"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
            >
              {blog &&
                blog?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.id} key={i}>
                      {item?.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Email"
            name="email"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "email",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <Input
              placeholder="Enter Email"
            // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Comment"
            name="comment"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "comment",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <TextArea
              placeholder="Enter comment"
            // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Utm Source"
            name="utmSource"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "utmSource",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <Input
              placeholder="Enter utmSource"
            // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Utm Medium"
            name="utmMedium"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "utmMedium",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <Input
              placeholder="Enter utmMedium"
            // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col flex={2} span={12}>
          <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
          // rules={[{ required: true }]}
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateBlogComment;
