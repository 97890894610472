import * as React from "react";
import {
  Form,
  Input,
 
  Button,
  message,

  Col,
  Row,
 
} from "antd";
import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,

} from "@ant-design/icons";
import { queryClient } from "../../..";
import { ManageConcernedPersonModalContext } from "../Modal/ManageConcernedPerson";
import LocalStorageService from "../../../utils/local-storage";

import {
  
  getConcernedPersonById,
 
} from "../Requests";
const ManageConcernedPersonForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageConcernedPersonModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const manageConcernedPersonMutation = useMutation(
    (values: any) =>
      request("admin.concernedperson.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("concernedperson");
        queryClient.invalidateQueries("partyById");
      },
    }
  );

  // const {
  //   isLoading,
  //   isError,
  //   data: party,
  // } = useQuery(
  //   ["partyById", props.partyId],
  //   () => getPartyById(props.partyId),
  //   {
  //     refetchOnWindowFocus: false,

  //     keepPreviousData: true,
  //     staleTime: 5000,
  //   }
  // );
  const {
    // isLoading,
    // isError,
    data: concernedpersons,
  } = useQuery(
    ["concernedperson", props.id],
    () => getConcernedPersonById(props.id),
    {
      refetchOnWindowFocus: false,

      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const onFinish = async (values: any) => {
    setFormLoading(true);
    values.updatedBy = LocalStorageService.getAccount().id;

    // values.partyId = props.partyId;

    manageConcernedPersonMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The concernedPerson has been successfully created.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  // party.concernedpersons = concernedpersons;
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={concernedpersons}
    >
      <Row>
        {/* <Col span={12}>
          <PartyCard data={party} />
        </Col> */}
        <Col span={12}>
          <Form.Item
            label="Name"
            name="name"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "name",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Mobile No"
            name="mobile"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "mobile",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            style={{ fontWeight: "bold" }}
            name="email"
            // valuePropName="checked"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Designation"
            name="designation"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "designation",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              size="large"
              loading={formLoading}
              type="primary"
              htmlType="submit"
              shape="round"
            >
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ManageConcernedPersonForm;
