import * as React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Switch,
  Select,
  DatePicker,
  Row,
  Typography,
  Upload,
  Avatar,
  Col,
  Radio,
} from "antd";
import { request } from "src/utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,

  UploadOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import { CreateTaskModalContext } from "../Modal/CreateTask";

import { getAllPersonnel } from "src/modules/HR/Personnel/Requests";
import {
  deleteFileToAWS,
  s3Path,
  uploadFileToAWS,
} from "src/utils/s3Config/s3upload";

import LocalStorageService from "src/utils/local-storage";

import "react-quill/dist/quill.snow.css";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import { tinyMCE } from "src/utils/keys";
import { getAllTaskType } from "src/modules/TaskType/Requests";
import CreateTaskTypeModal from "src/modules/TaskType/Modal/CreateTaskType";
import { getAllTaskTemplate } from "src/modules/TaskTemplate/Requests";
import CreateTaskTemplateModal from "src/modules/TaskTemplate/Modal/CreateTaskTemplate";
const CreateTaskForm: React.FC<{ workId?: string }> = (props) => {
  const modalContext = React.useContext(CreateTaskModalContext);
  const [user, setUser] = React.useState(LocalStorageService.getAccount());

  const [formLoading, setFormLoading] = React.useState(false);



  const [imageUrl, setImageUrl] = React.useState<string[]>([]);
  const [remarks, setRemarks] = React.useState<string>();
  const [editorkey, seteditorkey] = React.useState(4);

  const createTaskMutation = useMutation(
    (values: any) => request("admin.task.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("task");
        queryClient.invalidateQueries("notification");
        queryClient.invalidateQueries("workDetails");
        queryClient.invalidateQueries("taskByPersonnel");
        queryClient.invalidateQueries("storyByWork");
      },
    }
  );
  const { data: person } = useQuery(
    ["peronnnel"],
    async () => getAllPersonnel(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: taskType } = useQuery(
    ["taskType"],
    async () => getAllTaskType(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: taskTemplate } = useQuery(
    ["taskTemplate"],
    async () => getAllTaskTemplate(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
    setFormLoading(true);
    values.createdBy = user.id;
    values.remarks = remarks;
    values.workId = props.workId && props.workId;
    values.attachment = imageUrl;
    values.status = "To Do";

    //Notification Values
    values.notificationTitle = "Voila! New Task Assigned";
    values.notificationBody =
      "Task: " + values.title + "!  Click to view this task";
    values.receiverId = [values.assignedPersonId];
    values.senderId = values.assignedBy;

    createTaskMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The task has been successfully created.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
        cleareditor();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const editorRef = React.useRef<TinyMCEEditor | null>(null);

  // const modules = React.useMemo(
  //   () => ({
  //     toolbar: {
  //       container: [
  //         [{ font: [] }],
  //         [{ header: [1, 2, 3, 4, 5, 6, false] }],
  //         ["bold", "italic", "underline", "strike"],
  //         [{ color: [] }, { background: [] }],
  //         [{ script: "sub" }, { script: "super" }],
  //         ["blockquote", "code-block"],
  //         [{ list: "ordered" }, { list: "bullet" }],

  //         [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
  //         [{ direction: "rtl" }],
  //         [{ size: ["small", false, "large", "huge"] }],
  //         ["link", "image", "video"],
  //         ["clean"],
  //       ],

  //       //  handlers: {
  //       //    image: handleClick,
  //       //  },
  //       history: {
  //         delay: 500,
  //         maxStack: 100,
  //         userOnly: true,
  //       },
  //     },
  //   }),
  //   []
  // );
  const handleEditorChange = (content: any, editor: any) => {
    form.setFieldsValue({ remarks: content });
    setRemarks(content);
  };
  const cleareditor = () => {
    const newkey = editorkey * 43;
    seteditorkey(newkey);
  };
  const options = [
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
  ];
  form.setFieldsValue({ assignedBy: user.personnel.id });
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={10}>
        <Col span={8}>
          <Form.Item
            label="Priority"
            name="priority"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Priority",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <Radio.Group defaultValue={"Low"} options={options} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Due Date"
            name="dueDate"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Due Date",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Select Enquiry Date",
              },
            ]}
            hasFeedback
          >
            <DatePicker />
          </Form.Item>
        </Col>

        <Col span={10}>
          <Form.Item
            label="Title "
            name="title"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Title",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter Task Title",
              },
              { message: "Please enter Task Title" },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={6}>
          {" "}
          <Form.Item
            label="Type"
            name="type"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "type",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Select type",
              },
            ]}
            hasFeedback
          >
            <Select
              placeholder="Please Select Type"
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreateTaskTypeModal />
                  </Row>
                  {menu}
                </>
              )}
            >
              {taskType &&
                taskType?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.id} key={i}>
                      <div style={{ backgroundColor: item.color }}>
                        <Typography.Text strong>{item?.title}</Typography.Text>
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          {" "}
          <Form.Item
            label="Value"
            name="value"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "value",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              // {
              //   required: true,
              //   message: "Please enter Type Name",
              // },
              { message: "Please enter Type Name" },
            ]}
            hasFeedback
          >
            <Select placeholder="Allot Value">
              <Select.Option value="Penny">Penny</Select.Option>
              <Select.Option value="Silver">Silver</Select.Option>
              <Select.Option value="Gold">Gold</Select.Option>
              <Select.Option value="Platinum">Platinum</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          {" "}
          <Form.Item
            label="Assigned Person"
            style={{ fontWeight: "bold" }}
            name="assignedPersonId"
            tooltip={{
              title: "Person ",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Select
              // onSelect={OnPersonSelect}
              showSearch
              size="large"
              popupMatchSelectWidth
              // style={{ minHeight: 150 }}
              placeholder="Select Person"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
            >
              {person &&
                person?.map((item: any, i: number) => {
                  return (
                    <Select.Option
                      value={item?.id}
                      key={i}
                    // disabled={item.availability === 0 ? true : false}
                    >
                      <Row justify={"space-between"} align={"middle"}>
                        <Typography.Text strong>
                          {item?.partyId?.personName}
                        </Typography.Text>

                        <Avatar
                          src={
                            <img
                              src={s3Path + item.partyId.imageUrl}
                              alt={item?.partyId?.personName[0]}
                            />
                          }
                        />
                      </Row>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          {" "}
          <Form.Item
            label="Assigned By"
            style={{ fontWeight: "bold" }}
            name="assignedBy"
            tooltip={{
              title: "Person ",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Select
              // onSelect={OnPersonSelect}
              showSearch
              size="large"
              popupMatchSelectWidth
              // style={{ minHeight: 150 }}
              placeholder="Select Person"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
              disabled={true}
            >
              {person &&
                person?.map((item: any, i: number) => {
                  return (
                    <Select.Option
                      value={item?.id}
                      key={i}
                    // disabled={item.availability === 0 ? true : false}
                    >
                      <Row justify={"space-between"} align={"middle"}>
                        <Typography.Text strong>
                          {item?.partyId?.personName}
                        </Typography.Text>

                        <Avatar
                          src={
                            <img
                              src={s3Path + item.partyId.imageUrl}
                              alt={item?.partyId?.personName[0]}
                            />
                          }
                        />
                      </Row>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {/* <Form.Item label="Remarks" name="remarks" style={{ fontWeight: "bold" }}>
        <ReactQuill theme="snow" modules={modules} />
      </Form.Item> */}
      <Editor
        key={editorkey}
        apiKey={tinyMCE.apiKey}
        // ref={( editor) => (editorRef = editor)}
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          plugins: tinyMCE.plugins,
          toolbar: tinyMCE.toolbar,
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          // mergetags_list: [
          //   { value: "First.Name", title: "First Name" },
          //   { value: "Email", title: "Email" },
          // ],

          paste_data_images: true,

          ai_request: (request: any, respondWith: any) =>
            respondWith.string(() =>
              Promise.reject("See docs to implement AI Assistant")
            ),
        }}
        initialValue={form.getFieldsValue().remarks}
        onEditorChange={handleEditorChange}
      // outputFormat="html"
      />

      <Row justify={"space-between"} gutter={10}>
        <Col span={8}>
          <Form.Item
            label="Select Template"
            name="template"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "template",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Select
              onSelect={(value) => {
                if (editorRef.current) {
                  editorRef?.current?.setContent(value);
                }
              }}
              placeholder="Select A Template"
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreateTaskTemplateModal />
                  </Row>
                  {menu}
                </>
              )}
            >
              {taskTemplate &&
                taskTemplate?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.template} key={i}>
                      <Typography.Text strong>{item?.title}</Typography.Text>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Attachment"
            style={{ fontWeight: "bold" }}
            name="attachment"
            valuePropName="checked"
          >
            <Upload
              accept="application/pdf,application/msword, image/png, image/jpeg, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              maxCount={4}
              multiple
              listType="picture"
              action={async (file) => {
                const response: any = await uploadFileToAWS(file, "task").catch(
                  (err: any) => message.error(err?.toString())
                );
                setImageUrl([...imageUrl, response.key]);
                return response.key;
              }}
              beforeUpload={(fileList) => {
                console.log("FILE", fileList.size);
                return fileList.size / 1024 / 1024 <= 2 ? true : false;
              }}
              onRemove={(e) => {
                if (e?.error?.url) {
                  deleteFileToAWS(e.error.url).then((value) =>
                    message.success(value)
                  );
                  var file = imageUrl.filter((item) => item !== e.error.url);
                  setImageUrl(file);
                }
              }}
            >
              {imageUrl.length < 4 && (
                <Button icon={<UploadOutlined />}>Attachment</Button>
              )}
            </Upload>
          </Form.Item>
        </Col>{" "}
        <Col span={4}>
          <Form.Item
            label="Status"
            name="isActive"
            style={{ fontWeight: "bold" }}
            valuePropName="checked"
            required
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateTaskForm;
