import { request } from "src/utils/services";

export const getAllActiveTask = async () => {
  const { data } = await request("admin.task.list.active");
  return data;
};

export const getAllTask = async () => {
  const { data } = await request("admin.task.list");
  return data;
};

export const getTaskById = async (id: string) => {
  const { data } = await request("admin.task.id", { id });
  return data;
};
export const getTaskTableById = async (id: string) => {
  const { data } = await request("admin.task.table.id", { id });
  return data;
};

export const getUpdateOne = async (id: string) => {
  const { data } = await request("admin.task.update.one", { id });
  return data;
};

export const getTaskByPersonnel = async (id: string) => {
  const { data } = await request("admin.task.personnel.id", { id });
  return data;
};
export const getTaskTableByPersonnel = async (id: string) => {
  const { data } = await request("admin.task.table.personnel", { id });
  return data;
};

export const getTaskAssignedBy = async (id: string) => {
  const { data } = await request("admin.task.assigned.by.id", { id });
  return data;
};
export const getTaskTableAssignedBy = async (id: string) => {
  const { data } = await request("admin.task.table.assigned", { id });
  return data;
};
