import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,

  Col,
  Switch,
  Input,
  Divider,
  Upload,
  Select,
} from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import { ManageWebCaseStudyModalContext } from "../Modal/ManageWebCaseStudy";

import { useQuery } from "react-query";


import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import { getCaseStudyById } from "../Requests";
import { deleteFileToAWS, uploadFileToAWS } from "src/utils/s3Config/s3upload";
import { tinyMCE } from "src/utils/keys";
import { Editor } from "@tinymce/tinymce-react";
import { getCategoryByType } from "src/modules/Category/Requests";
import { getAllTechnology } from "src/modules/technology/Requests";

const ManageWebCaseStudyForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageWebCaseStudyModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [remarks, setRemarks] = React.useState<string>();
  const [galley, setGallery] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [fileUrl, setFileUrl] = React.useState<string[]>([]);
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const manageWebCaseStudyMutation = useMutation(
    (values: any) =>
      request("admin.caseStudy.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("caseStudy");
      },
    }
  );
  const {

    data: caseStudy,

  } = useQuery(
    ["caseStudy", props.id],
    async () => getCaseStudyById(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: category } = useQuery(
    ["category", "Case Study"],
    async () => getCategoryByType("Case Study"),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: technology } = useQuery(
    ["technology"],
    async () => getAllTechnology(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  // const beforeUpload = (file: RcFile) => {
  //   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  //   if (!isJpgOrPng) {
  //     message.error("You can only upload JPG/PNG file!");
  //   }
  //   const isLt2M = file.size / 1024 / 1024 < 2;
  //   if (!isLt2M) {
  //     message.error("Image must smaller than 2MB!");
  //   }
  //   return isJpgOrPng && isLt2M;
  // };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const onFinish = async (values: any) => {
    values.description = remarks;
    values.featureImage = galley;
    values.innerImage = fileUrl;
    setFormLoading(true);

    manageWebCaseStudyMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The WebCaseStudy has been successfully managed.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const handleEditorChange = (content: any, editor: any) => {
    // console.log("Content was updated:", content);
    setRemarks(content);
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={caseStudy}
    >
      <Row gutter={20}>
        <Col flex={5} span={12}>
          <Form.Item
            label="Title"
            name="title"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Title",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter title",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input
              placeholder="Enter Title"
              onChange={(e) =>
                form.setFieldsValue({
                  customUrl: e.target.value.toLowerCase().replace(/ /g, "-"),
                })
              }
            // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
        <Col flex={5} span={12}>
          <Form.Item
            label="CaseStudy Category"
            name="caseStudyCategoryId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "caseStudyCategoryId",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <Select placeholder="Select Case Study Category">
              {category &&
                category?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.id} key={i}>
                      {item?.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col flex={2} span={12}>
          <Form.Item
            label="Custom Url"
            name="customUrl"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "customUrl",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter customUrl",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Custom Url" />
          </Form.Item>
        </Col>
        <Col flex={2} span={12}>
          <Form.Item
            label="Technology"
            name="technologyId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Technology",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <Select mode="multiple" placeholder="Please Select technology">
              {technology &&
                technology?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.id} key={i}>
                      {item?.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={2} span={6}>
          <Form.Item
            label="Upload Feature Image"
            style={{ fontWeight: "bold" }}
            name="featureImage"
            valuePropName="checked"
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              accept="image/png, image/jpeg, image/jpg"
              action={async (file) => {
                const response: any = await uploadFileToAWS(
                  file,
                  "webCaseStudy"
                );
                setGallery(response.key);
                return response.key;
              }}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
        </Col>

        <Col flex={2} span={6}>
          <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
          // rules={[{ required: true }]}
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>
      </Row>
      <Editor
        apiKey={tinyMCE.apiKey}
        initialValue={caseStudy.description}
        // onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          plugins: tinyMCE.plugins,
          toolbar: tinyMCE.toolbar,
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          // mergetags_list: [
          //   { value: "First.Name", title: "First Name" },
          //   { value: "Email", title: "Email" },
          // ],

          paste_data_images: true,

          ai_request: (request: any, respondWith: any) =>
            respondWith.string(() =>
              Promise.reject("See docs to implement AI Assistant")
            ),
        }}
        // initialValue="Welcome to TinyMCE!"
        onEditorChange={handleEditorChange}
      // outputFormat="html"
      />
      <br />
      <Row justify={"space-between"}>
        <Form.Item
          label="Inner Image"
          style={{ fontWeight: "bold" }}
          name="innerImage"
          valuePropName="checked"
        >
          <Upload
            accept="application/pdf,application/msword, image/png, image/jpeg, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            maxCount={4}
            multiple
            listType="picture"
            action={async (file) => {
              const response: any = await uploadFileToAWS(
                file,
                "caseStudy inner image"
              ).catch((err: any) => message.error(err?.toString()));
              setFileUrl([...fileUrl, response.key]);
              return response.key;
            }}
            beforeUpload={(fileList) => {
              console.log("FILE", fileList.size);
              return fileList.size / 1024 / 1024 <= 2 ? true : false;
            }}
            onRemove={(e) => {
              if (e?.error?.url) {
                deleteFileToAWS(e.error.url).then((value) =>
                  message.success(value)
                );
                var file = fileUrl.filter((item) => item !== e.error.url);
                setFileUrl(file);
              }
            }}
          >
            {fileUrl.length < 4 && (
              <Button icon={<UploadOutlined />}>Upload Inner Image</Button>
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="Status"
          style={{ fontWeight: "bold" }}
          name="isActive"
          tooltip={{
            title: "status",
            icon: <InfoCircleOutlined />,
          }}
          valuePropName="checked"
        // rules={[{ required: true }]}
        >
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            defaultChecked
          />
        </Form.Item>
      </Row>

      <Divider />

      <h3>For SEO Purpose</h3>
      <Divider />
      <Row gutter={15}>
        <Col flex={3} span={8}>
          <Form.Item
            label="Meta Title"
            name="metaTitle"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Meta Title",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <Input placeholder="Enter Meta Title" />
          </Form.Item>
        </Col>
        <Col flex={3} span={8}>
          <Form.Item
            label="Meta Keyword"
            name="metaKeywords"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Meta Keyword",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <Input placeholder="Enter Meta Keyboard" />
          </Form.Item>
        </Col>
        <Col flex={3} span={8}>
          <Form.Item
            label="Meta Description"
            name="metaDescription"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Meta Description",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <Input placeholder="Enter Meta Description" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageWebCaseStudyForm;
