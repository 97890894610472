import * as React from "react";
import {



  Card,
  Tabs,
} from "antd";
import StoryListByWorkId, {
  StoryTaskList,
} from "../Component/StoryListByWorkId";
import { useQuery } from "react-query";
import { getWorkByIdWithAdminDetail } from "../Requests";
import parse from "html-react-parser";
import {
  CredentialTable,
} from "../Component/CredentialListByWorkId";
import {
  DeploymentTable,
} from "../Component/DeploymentListByWorkId";



const ShowWorkAdminDetailsForm: React.FC<{ id: string }> = (props) => {
  const { data: res } = useQuery(
    ["workAdminDetails", props.id],
    async () => getWorkByIdWithAdminDetail(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );
  return (
    <>
      <Tabs
        centered
        // tabPosition="left"
        defaultActiveKey="2"
        // type="card"
        items={[
          {
            key: "1",
            label: <>Description/Details</>,
            children: res[0].description && parse(res[0].description),
          },
          {
            key: "2",
            label: <>Tasks List</>,
            children: <StoryTaskList data={res[0].tasks} />,
          },
          {
            key: "3",
            label: <>Story List</>,
            children: res[0].story.length > 0 && (
              <StoryListByWorkId data={res[0].story} />
            ),
          },
          {
            key: "4",
            label: <>Credentails List</>,
            children: <CredentialTable data={res[0].credentials} />,
          },
          {
            key: "5",
            label: <>Deployment </>,
            children: (
              <Card style={{ width: "100%" }}>
                <DeploymentTable data={res[0].deployments} />
              </Card>
            ),
          },
        ]}
      />
    </>
  );
};

export default ShowWorkAdminDetailsForm;
