import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,

  Switch,

  Card,
  Space,
  Tag,

  ColorPicker,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,

} from "@ant-design/icons";
import { queryClient } from "src";




import LocalStorageService from "src/utils/local-storage";
import { CreateTaskTypeModalContext } from "../Modal/CreateTaskType";

import { getAllTaskType } from "../Requests";
import ManageTaskTypeModal from "../Modal/ManageTaskType";

import Table, { ColumnsType } from "antd/es/table";
import type { ColorPickerProps, GetProp } from "antd";
interface DataType {
  id: number;
  name: any;
}
type Color = Exclude<GetProp<ColorPickerProps, "value">, string>;
const { Text } = Typography;
const CreateTaskType: React.FC = () => {
  const modalContext = React.useContext(CreateTaskTypeModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const createTaskTypeMutation = useMutation(
    (values: any) => request("admin.taskType.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("taskType");
      },
    }
  );
  // const { data: party } = useQuery(["party"], async () => getAllActiveParty(), {
  //   refetchOnWindowFocus: false,
  // });
  const {

    data: response,
  } = useQuery(["taskType"], () => getAllTaskType(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });


  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    // values.partyId = party[values.partyId].id;


    setFormLoading(true);

    createTaskTypeMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The TaskType has been successfully created.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
        // setImageUrl(undefined);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [form] = Form.useForm();

  const column: ColumnsType<DataType> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (i: any) => (
        <ColorPicker size="small" defaultValue={i} showText disabled />
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          {/* <TaskTypeViewModal id={id} /> */}
          <ManageTaskTypeModal id={id} />
          {/* {accessAuth[0].includes("Edit") && <ManageTaskTypeModal id={id} />} */}
        </Space>
      ),
    },
  ];
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Row gutter={22}>
        <Col flex={5} span={8}>
          <h3>Add TaskType</h3>
          <Card style={{ backgroundColor: "whitesmoke" }} bordered hoverable>
            <Form.Item
              label="Title"
              name="title"
              style={{
                fontWeight: "bold",
              }}
              tooltip={{
                title: "title",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter title",
                },
                { whitespace: true },
                { min: 3 },
              ]}
              hasFeedback
            >
              <Input placeholder="Enter Title" />
            </Form.Item>
            <Form.Item
              label="Pic the Color"
              name="color"
              getValueFromEvent={(color: Color) => color.toHexString()}
              style={{
                fontWeight: "bold",
              }}
              tooltip={{
                title: "Color",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Select Color",
                },
              ]}
              hasFeedback
            >
              <ColorPicker defaultValue="#1677ff" />
            </Form.Item>
            <Row gutter={20}>
              <Col flex={2} span={12}>
                <Form.Item
                  label="Status"
                  style={{ fontWeight: "bold" }}
                  name="isActive"
                  tooltip={{
                    title: "status",
                    icon: <InfoCircleOutlined />,
                  }}
                  valuePropName="checked"
                // rules={[{ required: true }]}
                >
                  <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    defaultChecked
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item style={{ textAlign: "center" }}>
              <Button
                size="large"
                loading={formLoading}
                type="primary"
                htmlType="submit"
                shape="round"
              >
                Submit
              </Button>
            </Form.Item>
          </Card>
        </Col>
        <Col flex={5} span={16}>
          <h3>TaskType List</h3>
          <Card style={{ backgroundColor: "whitesmoke" }} hoverable>
            <Table size="small" columns={column} dataSource={response} />
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default CreateTaskType;
