import * as React from "react"
import { Row, Col, Statistic, Divider, Descriptions } from "antd";


const ShowCandidateDetailsForm: React.FC<{ id: number }> = (props) => {



  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Statistic title="Leads Request No" value={"SKY/REQ/"} />
        </Col>
        <Col span={12}>
          <Statistic title="Requested Date" />
        </Col>
      </Row>
      <Divider orientation="left">Company Info</Divider>

      <Col span={24}>
        <Divider orientation="left">Leads Status</Divider>
      </Col>
      <Divider orientation="left">Instrument Details</Divider>
      <Descriptions bordered size={"small"}></Descriptions>

      <Divider orientation="left">Remarks/Description</Divider>

      <Divider orientation="left">Requested By</Divider>
    </>
  );
};

export default ShowCandidateDetailsForm;
