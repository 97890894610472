import * as React from "react";
import {
  Form,
  Button,
  message,

  Switch,
  Input,

} from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,

} from "@ant-design/icons";
import { queryClient } from "src";
import { ManageTaskTypeModalContext } from "../Modal/ManageTaskType";

import { useQuery } from "react-query";

import { getTaskTypeById } from "../Requests";

import LocalStorageService from "src/utils/local-storage";

import { ColorPicker } from "antd";
import type { ColorPickerProps, GetProp } from "antd";

type Color = Exclude<GetProp<ColorPickerProps, "value">, string>;

const ManageTaskTypeForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageTaskTypeModalContext);
  const [formLoading, setFormLoading] = React.useState(false);



  const manageTaskTypeMutation = useMutation(
    (values: any) => request("admin.taskType.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("taskType");
      },
    }
  );
  const {

    data: taskType,

  } = useQuery(["taskType", props.id], async () => getTaskTypeById(props.id), {
    refetchOnWindowFocus: false,
  });


  const onFinish = async (values: any) => {
    values.updatedBy = LocalStorageService.getAccount().id;

    setFormLoading(true);

    manageTaskTypeMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The TaskType has been successfully managed.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();


  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={taskType}
      form={form}
    >
      <Form.Item
        label="Title"
        name="title"
        style={{
          fontWeight: "bold",
        }}
        tooltip={{
          title: "Type",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Type",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <Input placeholder="Enter Title" />
      </Form.Item>
      <Form.Item
        label="Pic the Color"
        name="color"
        getValueFromEvent={(color: Color) => color.toHexString()}
        style={{
          fontWeight: "bold",
        }}
        tooltip={{
          title: "Color",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Color",
          },
        ]}
        hasFeedback
      >
        <ColorPicker defaultValue="#1677ff" />
      </Form.Item>
      <Form.Item
        label="Status"
        style={{ fontWeight: "bold" }}
        name="isActive"
        tooltip={{
          title: "status",
          icon: <InfoCircleOutlined />,
        }}
        valuePropName="checked"
      // rules={[{ required: true }]}
      >
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageTaskTypeForm;
