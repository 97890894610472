
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Space, Table, Tag, } from "antd";

import { getAllBlogComment } from "../Requests";

import BlogCommentViewModal from "../Modal/ShowBlogCommentDetails";
import ManageBlogCommentModal from "../Modal/ManageBlogComment";

import { getAccessAuthrorization } from "src/components/access";


interface DataType {
  id: number;
  name: any;
}
// type DataIndex = keyof DataType;
const BlogCommentList: React.FC = () => {

  let accessAuth = getAccessAuthrorization("blogComment");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["blogComment"], () => getAllBlogComment(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  // const { data: blogCommentCategory } = useQuery(
  //   ["blogCommentCategory"],
  //   async () => getAllblogCommentCategories(),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: " Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Blog",
      dataIndex: "blogId",
      key: "blogId",
      render: (text: any) => <p>{text?.title}</p>,
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <BlogCommentViewModal id={id} />
          {/* <ManageBlogCommentModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <ManageBlogCommentModal id={id} />}
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default BlogCommentList;
