import * as React from "react";

import { Modal, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CreateTaskType from "../Form/CreateTaskTypeForm";

export const CreateTaskTypeModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const CreateTaskTypeModal: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <CreateTaskTypeModalContext.Provider value={{ setIsModalVisible }}>
      <Button
        onClick={showModal}
        type="primary"
        shape="round"
        size="small"
        icon={<PlusOutlined />}
      />
        {/* Add TaskType
      </Button> */}
      <Modal
        title="Generate TaskType"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
        width={"90%"}
      >
        <CreateTaskType />
      </Modal>
    </CreateTaskTypeModalContext.Provider>
  );
};

export default CreateTaskTypeModal;
