import * as React from "react";

import { Modal, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import ManageConcernedPersonForm from "../Form/ManageConcernedPersonForm";


export const ManageConcernedPersonModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const ManageConcernedPersonModal: React.FC<{
  // partyId: string;
  id: string;
}> = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <ManageConcernedPersonModalContext.Provider value={{ setIsModalVisible }}>
      <Button
        onClick={showModal}
        type="link"
        shape="round"
        icon={<EditOutlined />}
        size="small"
      ></Button>
      <Modal
        title="Manage ConcernedPerson"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
      // width={1000}
      >
        <ManageConcernedPersonForm id={id} />
      </Modal>
    </ManageConcernedPersonModalContext.Provider>
  );
};

export default ManageConcernedPersonModal;
