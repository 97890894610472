import * as React from "react";
import { Modal, Button, Tooltip } from "antd";
import CreateAssignedPersonForm from "../Form/CreateAssignPersonForm";
import { ApiOutlined } from "@ant-design/icons";

export const ManageAssignedPersonModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const AssignedPersonManageModal: React.FC<{ id: string }> = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ManageAssignedPersonModalContext.Provider value={{ setIsModalVisible }}>
      <Tooltip title="Edit/Update">
        <Button
          onClick={showModal}
          type="link"
          shape="round"
          icon={<ApiOutlined />}
        >
          Assign Person
        </Button>
      </Tooltip>
      <Modal
        title="Manage Assign Person"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <CreateAssignedPersonForm id={id} />
      </Modal>
    </ManageAssignedPersonModalContext.Provider>
  );
};

export default AssignedPersonManageModal;
