import * as React from "react";
import {

  Row,

  Card,

  Descriptions,
  Image,
  Space,
  Typography,

} from "antd";

import { useQuery } from "react-query";

import LocalStorageService from "src/utils/local-storage";
import {
  getBankDetailsByPartyId,
  getPartyById,
} from "src/modules/Party/Requests";
import { s3Path } from "src/utils/s3Config/s3upload";

import PartyManageModal from "src/modules/Party/Modal/ManageParty";

import { getUserByPartyId } from "../../User/Requests";

const { Text } = Typography;
const CreateCompanyForm: React.FC<{ id: string }> = (props) => {

  const profileData = LocalStorageService.getAccount();

  // get company details
  const {

    data: party,

  } = useQuery(
    ["company", props.id],
    async () => getPartyById(profileData.partyId),
    {
      refetchOnWindowFocus: false,
      // enabled: false,
    }
  );
  const {
    //isLoading,
    data: user,
    //error,
    //isError,
  } = useQuery(
    ["party", profileData?.partyId],
    async () => getUserByPartyId(profileData?.partyId),
    {
      refetchOnWindowFocus: false,
      // enabled: false,
    }
  );
  const {
    //isLoading,
    data: bankDetails,
    //error,
    //isError,
  } = useQuery(
    ["bankDetails", profileData?.partyId],
    async () => getBankDetailsByPartyId(profileData?.partyId),
    {
      refetchOnWindowFocus: false,
      // enabled: false,
    }
  );


  return (
    <Card>
      <Row gutter={20} justify="space-between">
        <Image src={s3Path + party.imageUrl} height={150} width={150} />

        <Space>
          <Space.Compact direction="horizontal">
            {party?.partyType.map((element: any) => (
              <Text type="success" code>
                {element}
              </Text>
            ))}
            <Text type="warning" code>
              {party?.classification}
            </Text>
          </Space.Compact>
        </Space>
      </Row>
      <Card>
        <Descriptions
          title="User Info"
        // extra={<ManageUserDetailsModal id={party.id} />}
        >
          <Descriptions.Item label="User Name">
            {user[0]?.username}
          </Descriptions.Item>
          <Descriptions.Item label="Roles">
            {/* {user.role} */}
            {user[0]?.roles.map((element: any) => (
              <Text type="success" code>
                {element}
              </Text>
            ))}
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Card>
        <Descriptions
          title="Basic Info"
          extra={<PartyManageModal id={party?.id} />}
        >
          <Descriptions.Item label="Name">
            {party?.personName}
          </Descriptions.Item>
          <Descriptions.Item label="Company">
            {party?.companyName}
          </Descriptions.Item>
          <Descriptions.Item label="Mobile">
            +91-{party?.phoneNo}
          </Descriptions.Item>
          <Descriptions.Item label="Email">{party?.email}</Descriptions.Item>
          <Descriptions.Item label="Website">
            {party?.website}
          </Descriptions.Item>
          <Descriptions.Item label="GST">{party?.gstInNo}</Descriptions.Item>
          <Descriptions.Item label="Address">
            {party?.address}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Location" span={2}>
            <Tooltip placement="bottom" title={text}>
              <Text code>
                {party.localId.localityName}-{party.localId.block}-
                {party.localId.district}-{party.localId.pinCode}-
                {party.localId.state}
              </Text>
            </Tooltip>
          </Descriptions.Item> */}
        </Descriptions>
      </Card>
      <Card>
        <Descriptions
          title="Bank Details"
        // extra={<ManageBankDetailsModal id={party.id} />}
        >
          <Descriptions.Item label="Account No ">
            {bankDetails[0]?.accNo}
          </Descriptions.Item>
          <Descriptions.Item label="Acc. Holder Name">
            {bankDetails[0]?.accHolderName}
          </Descriptions.Item>
          <Descriptions.Item label="UPI ID">
            {bankDetails[0]?.upiId}
          </Descriptions.Item>
          <Descriptions.Item label="IFSC Code">
            {bankDetails[0]?.ifscCode}
          </Descriptions.Item>
          <Descriptions.Item label="Bank Name">
            {bankDetails[0]?.bankName}
          </Descriptions.Item>
          <Descriptions.Item label="Branch">
            {bankDetails[0]?.branch}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </Card>
    // <Form
    //   form={form}
    //   layout="vertical"
    //   onFinish={onFinish}
    //   onFinishFailed={onFinishFailed}
    // >
    //   {/* <Form.Item
    //     label="Type"
    //     style={{ fontWeight: "bold" }}
    //     name="partyType"
    //     valuePropName="checked"
    //   >
    //     <Checkbox.Group options={options} />
    //   </Form.Item> */}
    //   <Card title="Basic Info">
    //     <Row gutter={20} align="middle">
    //       {/* <Col flex={1}>
    //       <Form.Item
    //         label="Classification"
    //         style={{ fontWeight: "bold" }}
    //         name="classification"
    //         tooltip={{
    //           title: "classification",
    //           icon: <InfoCircleOutlined />,
    //         }}
    //       >
    //         <Select>
    //           <Select.Option value="Household">Household</Select.Option>
    //           <Select.Option value="Corporate">Corporate</Select.Option>
    //           <Select.Option value="Government">Government </Select.Option>
    //         </Select>
    //       </Form.Item>
    //     </Col> */}
    //       <Col flex={1}>
    //         <Form.Item
    //           label="Company Logo"
    //           name="logo"
    //           style={{ fontWeight: "bold" }}
    //           tooltip={{
    //             title: "Company Name",
    //             icon: <InfoCircleOutlined />,
    //           }}
    //           // rules={[
    //           //   {
    //           //     required: true,
    //           //     message: "Please enter your Comapany name",
    //           //   },
    //           //   { whitespace: true },
    //           //   { min: 3 },
    //           // ]}
    //           // hasFeedback
    //         >
    //           <Upload
    //             name="avatar"
    //             listType="picture-card"
    //             className="avatar-uploader"
    //             showUploadList={false}
    //             //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
    //             beforeUpload={beforeUpload}
    //             onChange={handleChange}
    //           >
    //             {imageUrl ? (
    //               <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
    //             ) : (
    //               uploadButton
    //             )}
    //           </Upload>
    //         </Form.Item>
    //       </Col>

    //       <Col flex={15}>
    //         <Form.Item
    //           label="Company Name"
    //           name="companyName"
    //           style={{ fontWeight: "bold" }}
    //           tooltip={{
    //             title: "Company Name",
    //             icon: <InfoCircleOutlined />,
    //           }}
    //           rules={[
    //             {
    //               required: true,
    //               message: "Please enter your Comapany name",
    //             },
    //             { whitespace: true },
    //             { min: 3 },
    //           ]}
    //           hasFeedback
    //         >
    //           <Input
    //             addonBefore={<BankOutlined className="site-form-item-icon" />}
    //             maxLength={50}
    //           />
    //         </Form.Item>
    //       </Col>
    //     </Row>
    //     <Row gutter={20}>
    //       <Col flex={5} span={12}>
    //         <Form.Item
    //           label="Person Name"
    //           name="personName"
    //           style={{ fontWeight: "bold" }}
    //           tooltip={{
    //             title: "Person Name",
    //             icon: <InfoCircleOutlined />,
    //           }}
    //           rules={[
    //             {
    //               required: true,
    //               message: "Please enter your name",
    //             },
    //             { whitespace: true },
    //             { min: 3 },
    //           ]}
    //           hasFeedback
    //         >
    //           <Input
    //             addonBefore={<UserOutlined className="site-form-item-icon" />}
    //           />
    //         </Form.Item>
    //       </Col>
    //       <Col flex={4} span={12}>
    //         <Form.Item
    //           label="Phone No"
    //           name="phoneNo"
    //           style={{ fontWeight: "bold" }}
    //           tooltip={{
    //             title: "Official Phone no",
    //             icon: <InfoCircleOutlined />,
    //           }}
    //           rules={[
    //             { required: true, message: "Please input your phone number!" },
    //           ]}
    //         >
    //           <Input
    //             addonBefore="+91-"
    //             addonAfter={<MobileOutlined />}
    //             maxLength={10}
    //           />
    //         </Form.Item>
    //       </Col>
    //     </Row>
    //     <Form.Item
    //       label="Address"
    //       style={{ fontWeight: "bold" }}
    //       name="address"
    //       tooltip={{
    //         title: "address",
    //         icon: <InfoCircleOutlined />,
    //       }}
    //       rules={[{ required: true }]}
    //     >
    //       <Input
    //         addonBefore={<HomeOutlined className="site-form-item-icon" />}
    //       />
    //     </Form.Item>
    //     <Row gutter={20}>
    //       <Col flex={2} span={12}>
    //         <Form.Item
    //           label="Website"
    //           style={{ fontWeight: "bold" }}
    //           name="website"
    //           tooltip={{
    //             title: "Website e.g www.example.com",
    //             icon: <InfoCircleOutlined />,
    //           }}
    //           rules={[{ type: "url", message: "Please enter a valid url" }]}
    //           hasFeedback
    //         >
    //           <Input
    //             addonBefore={<GlobalOutlined className="site-form-item-icon" />}
    //             maxLength={50}
    //           />
    //         </Form.Item>
    //       </Col>
    //       <Col flex={2} span={12}>
    //         <Form.Item
    //           label="Email"
    //           style={{ fontWeight: "bold" }}
    //           name="email"
    //           tooltip={{
    //             title: "email",
    //             icon: <InfoCircleOutlined />,
    //           }}
    //           rules={[
    //             {
    //               required: true,
    //               message: "Please enter your email",
    //             },
    //             { type: "email", message: "Please enter a valid email" },
    //           ]}
    //           hasFeedback
    //         >
    //           <Input
    //             addonBefore={<MailOutlined className="site-form-item-icon" />}
    //           />
    //         </Form.Item>
    //       </Col>
    //     </Row>
    //     <Row gutter={20}>
    //       <Col flex={2} span={12}>
    //         <Form.Item
    //           label="GST No."
    //           style={{ fontWeight: "bold" }}
    //           name="gstInNo"
    //           tooltip={{
    //             title: "GSTIN No",
    //             icon: <InfoCircleOutlined />,
    //           }}
    //           // rules={[{ required: true }]}
    //         >
    //           <Input
    //             addonBefore={<AuditOutlined className="site-form-item-icon" />}
    //             maxLength={20}
    //           />
    //         </Form.Item>
    //       </Col>
    //       {/* <Col flex={2} span={12}>
    //         <Form.Item
    //           label="Status"
    //           style={{ fontWeight: "bold" }}
    //           name="isActive"
    //           tooltip={{
    //             title: "status",
    //             icon: <InfoCircleOutlined />,
    //           }}
    //           valuePropName="checked"
    //           // rules={[{ required: true }]}
    //         >
    //           <Switch
    //             checkedChildren="Active"
    //             unCheckedChildren="Inactive"
    //             defaultChecked
    //           />
    //         </Form.Item>
    //       </Col> */}
    //     </Row>
    //   </Card>
    //   <Card title="Bank Details">
    //     <Row gutter={10}>
    //       <Col span={8}>
    //         <Form.Item
    //           label="Account Number"
    //           style={{ fontWeight: "bold" }}
    //           name="accNo"
    //           tooltip={{
    //             title: "accNo",
    //             icon: <InfoCircleOutlined />,
    //           }}
    //           rules={[{ required: true }]}
    //         >
    //           <Input addonBefore={<AccountBookOutlined />} />
    //         </Form.Item>
    //       </Col>

    //       <Col span={8}>
    //         <Form.Item
    //           label="Account Holder Name"
    //           style={{ fontWeight: "bold" }}
    //           name="accHolderName"
    //           tooltip={{
    //             title: "accHolderName",
    //             icon: <InfoCircleOutlined />,
    //           }}
    //           //rules={[{ required: true }]}
    //         >
    //           <Input addonBefore={<UserOutlined />} />
    //         </Form.Item>
    //       </Col>
    //       <Col span={8}>
    //         <Form.Item
    //           label="UPI ID"
    //           style={{ fontWeight: "bold" }}
    //           name="upiId"
    //           tooltip={{
    //             title: "upiId",
    //             icon: <InfoCircleOutlined />,
    //           }}
    //           rules={[{ required: true }]}
    //         >
    //           <Input addonBefore={<IdcardOutlined />} />
    //         </Form.Item>
    //       </Col>
    //     </Row>
    //     <Row gutter={10}>
    //       <Col span={8}>
    //         <Form.Item
    //           label="IFSC Code"
    //           style={{ fontWeight: "bold" }}
    //           name="ifscCode"
    //           tooltip={{
    //             title: "ifscCode",
    //             icon: <InfoCircleOutlined />,
    //           }}
    //           rules={[{ required: true }]}
    //         >
    //           <Input.Search
    //             onSearch={onIFSCSearch}
    //             addonBefore={<CodepenOutlined />}
    //           />
    //         </Form.Item>
    //       </Col>
    //       <Col span={8}>
    //         <Form.Item
    //           label="Bank Name"
    //           style={{ fontWeight: "bold" }}
    //           name="bankName"
    //           tooltip={{
    //             title: "bankName",
    //             icon: <InfoCircleOutlined />,
    //           }}
    //           //rules={[{ required: true }]}
    //         >
    //           <Input addonBefore={<BankOutlined />} disabled />
    //         </Form.Item>
    //       </Col>
    //       <Col span={8}>
    //         <Form.Item
    //           label="Branch"
    //           style={{ fontWeight: "bold" }}
    //           name="branch"
    //           tooltip={{
    //             title: "branch",
    //             icon: <InfoCircleOutlined />,
    //           }}
    //           rules={[{ required: true }]}
    //         >
    //           <Input addonBefore={<BranchesOutlined />} disabled />
    //         </Form.Item>
    //       </Col>
    //     </Row>
    //   </Card>

    //   <Form.Item style={{ textAlign: "center" }}>
    //     <Button
    //       size="large"
    //       loading={formLoading}
    //       type="primary"
    //       htmlType="submit"
    //       shape="round"
    //     >
    //       Submit
    //     </Button>
    //   </Form.Item>
    // </Form>
  );
};

export default CreateCompanyForm;
