import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import TodoList from "../Component/TodoList";
import { Button, Row, Segmented, Tabs } from "antd";
import {
  AndroidOutlined,
  AppleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import WorkStats from "../Component/workStat";
import MentionList from "../Component/MetionList";
import LocalStorageService from "src/utils/local-storage";
import AdminDashboard from "../Component/AdminDashboard";
import UserDashboard from "../Component/UserDashboard";
import { isMobile } from "react-device-detect";
import TodoMobileList from "../Component/TodoMobileList";
import MobileMentionList from "../Component/MobileMetionList";
import MobileAdminDashboard from "../Component/MobileAdminDashboard";
import MobileFollowupList from "../Component/FollowUpLisr";
import Tasks from "src/components/tasks";
import Tasks1 from "src/components/demo";
import TasksTable from "src/components/tasktable";
import TasksTwo from "src/components/tasksTwo";
import TasksTable2 from "src/components/tasksTwo";
import TasksForPersonnel from "src/components/tasktableForPersonnel";


const DashboardPageHome: React.FC<RouteComponentProps> = () => {
  const [user, setUser] = React.useState(LocalStorageService.getAccount());

  return (
    <>
      {/* <Row justify={"space-between"} align={"middle"}>
        <h2>Welcome To Digital Laboratory</h2>
        {isMobile ? (
          <Button
            icon={<ReloadOutlined />}
            onClick={() => window.location.reload()}
          />
        ) : (
          <Button
            icon={<ReloadOutlined />}
            onClick={() => window.location.reload()}
          >
            Reload Window
          </Button>
        )}
      </Row> */}
      <Tabs
      size="small"
        centered
        defaultActiveKey="3"
        type="card"
        items={[
          {
            key: "1",
            label: (
              <>
                <AndroidOutlined /> Dashboard
              </>
            ),
            children: (
              <>
                {user.roles[0] === "ROLE_ADMIN" ? (
                  isMobile ? (
                    <MobileAdminDashboard />
                  ) : (
                    <AdminDashboard />
                  )
                ) : (
                  <UserDashboard />
                )}
              </>
            ),
          },
          {
            key: "2",
            label: (
              <>
                <AndroidOutlined /> Work
              </>
            ),
            children: <WorkStats />,
            disabled: user.roles[0] !== "ROLE_ADMIN",
          },
          {
            key: "3",
            label: (
              <>
                <AndroidOutlined /> Task
              </>
            ),
            children:
            // <App/>
            //  <Tasks />
            //  <Tasks1 />
            // <TasksTwo/>
           
            <TasksTable/>

            //   isMobile ? (
            //   <TodoMobileList id={user.personnel.id} />
            // ) : (
            //   <TodoList id={user.personnel.id} />
            // ),
          },
          {
            key: "4",
            label: (
              <>
                <AndroidOutlined /> Mentions
              </>
            ),
            children: isMobile ? <MobileMentionList /> : <MentionList />,
          },
          {
            key: "5",
            label: (
              <>
                <AndroidOutlined /> Follow Up
              </>
            ),
            children: <MobileFollowupList />,
            disabled: user.roles[0] !== "ROLE_ADMIN",
          },
        ]}
      />
    </>
  );
};

export default withRouter(DashboardPageHome);
