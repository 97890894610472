import * as React from "react";
import {  Button, Tooltip, Drawer } from "antd";

import {  RightSquareOutlined } from "@ant-design/icons";
import ManageTaskTableForm from "../Form/ManageTaskTable";

export const ManageTaskModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const TaskManageModal: React.FC<{ id: string }> = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ManageTaskModalContext.Provider value={{ setIsModalVisible }}>
      <Tooltip title="Open Task">
        <Button
        style={{marginInline:2}}
        
      
          onClick={showModal}
          
          
          size="small"
        ><RightSquareOutlined />open</Button>
      </Tooltip>
      <Drawer width={"60vw"} placement="right" closable={false}  onClose={handleOk} open={isModalVisible}>
      <ManageTaskTableForm id={id} />

      </Drawer>
      {/* <Modal
        title="Manage Task"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <ManageTaskTableForm id={id} />
      </Modal> */}
    </ManageTaskModalContext.Provider>
  );
};

export default TaskManageModal;
