import * as React from "react";
import {
  Form,
  Button,
  message,
  Select,
  DatePicker,
  Row,
  Typography,
  Col,
  Input,

} from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,

  PlusOutlined,

  MinusCircleOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import { CreateLeadsModalContext } from "../Modal/CreateLeads";
import { useQuery } from "react-query";


import LocalStorageService from "../../../utils/local-storage";


import {
  getAllPersonnel,

} from "src/modules/HR/Personnel/Requests";
import * as XLSX from "xlsx";
import CreatePartyModal from "src/modules/Party/Modal/CreateParty";
import moment from "moment";

const { Text, } = Typography;
const CreateBulkLeadsForm: React.FC = () => {
  const modalContext = React.useContext(CreateLeadsModalContext);
  const [formLoading, setFormLoading] = React.useState(false);


  // var leads: any = { list: [{}] };
  const createLeadMutation = useMutation(
    (values: any) => request("admin.bulk.lead.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("lead");
      },
    }
  );
  // const { data: party } = useQuery(["party"], async () => getAllActiveParty(), {
  //   refetchOnWindowFocus: false,
  // });


  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    // values.partyId = party[values.partyId].id;
    values.leadStatus = "NewLead";
    values.content = `New Lead Created By ${LocalStorageService.getAccount().username
      }`;
    values.description = values.remarks;
    // values.imageUrl = galley;
    //Notification Values
    values.notificationTitle = "Hey! New Lead Assigned";
    values.notificationBody =
      "Don't Forget to Keep in touch with client!  Click to view & take Followup";
    // values.receiverId = [values.assignedPersonId];
    values.senderId = LocalStorageService.getAccount().personnel.id;
    setFormLoading(true);

    createLeadMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The lead has been successfully created.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  // const onPartySelect = (i: any) => {
  //   form.setFieldsValue({ serviceAddress: party[i].address });
  // };

  const { data: person } = useQuery(
    ["peronnnel"],
    async () => getAllPersonnel(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const [items, setItems] = React.useState({});
  // var exceldata: any;
  const readExcel = (file: any) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e: any) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        var data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
        var listdata: any = data;

        for (var i = 0; i < listdata.length; i++) {
          listdata[i].enquiryDate = new Date(
            Math.round((listdata[i].enquiryDate - 25569) * 86400 * 1000)
          );
          // listdata[i].enquiryDate = moment(listdata[i].enquiryDate).format(
          //   "DD-MM-YYYY"
          // );
          listdata[i].enquiryDate = moment(
            listdata[i].enquiryDate,
            "DD-MM-YYYY"
          );
        }
        console.log(listdata, "efxl");

        form.setFieldsValue({ list: listdata });

        console.log(data, "exl");
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d: any) => {
      setItems(d);
    });
  };

  return (
    <Form
      layout="horizontal"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    // initialValues={leads}
    >
      <Form.Item
        label="Upload Excel File"
        // name="destination"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Upload GR Sheet",
          icon: <InfoCircleOutlined />,
        }}
      // rules={[{ required: true }]}
      >
        <input
          type="file"
          onChange={(e: any) => {
            console.log(e.target.files[0], "lop");

            const file = e.target.files[0];
            readExcel(file);
          }}
        />
        {/* Check below upload not working with file type */}
        {/* <Upload
          onChange={(e: any) => {
            //console.log(e);

            const file = e.fileList[0];
            readExcel(file);
          }}
        > 
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>*/}
      </Form.Item>
      {/* <table>
        <tr>
          <th style={{ width: "4%" }}>Sno</th>
          <th style={{ width: "12%" }}>Enq.Date</th>
          <th style={{ width: "15%" }}>Company</th>
          <th style={{ width: "15%" }}>Person Name</th>
          <th style={{ width: "8%" }}>Mobile no.</th>
          <th style={{ width: "12%" }}>Address</th>
          <th style={{ width: "11%" }}>Email</th>
          <th style={{ width: "9%" }}> Website</th>
          <th style={{ width: "8%" }}>Services</th>
          <th style={{ width: "8%" }}>Source</th>
          <th style={{ width: "8%" }}>Remarks</th>
          <th style={{ width: "8%" }}>Assigned to</th>
        </tr>
      </table> */}
      {/* <Space split={<Divider type="vertical" />}> */}
      <Row justify={"space-between"}>
        <Typography.Text strong>Sno</Typography.Text>
        <Typography.Text strong>Enq.Date</Typography.Text>
        <Typography.Text strong>Company</Typography.Text>
        <Typography.Text strong>Person Name</Typography.Text>
        <Typography.Text strong>Mobile</Typography.Text>
        <Typography.Text strong>Address</Typography.Text>
        <Typography.Text strong>Email</Typography.Text>
        <Typography.Text strong>Website</Typography.Text>
        <Typography.Text strong>Services</Typography.Text>
        <Typography.Text strong>Source</Typography.Text>
        <Typography.Text strong>Remarks</Typography.Text>
        <Typography.Text strong>Assigned to</Typography.Text>
      </Row>
      {/* </Space> */}
      <Form.List name="list">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field: any, index: number) => (
              // setSno(index + 1),
              <Row
                //gutter={16}
                key={field.key}
                justify="space-between"
                align="top"
              >
                <Col style={{ width: "3%" }}>
                  <Form.Item
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item {...field} name={[field.name, "sno"]}>
                        <Input placeholder="S.no" />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col style={{ width: "8%" }}>
                  <Form.Item
                    {...field}
                    name={[field.name, "enquiryDate"]}
                    fieldKey={[field.fieldKey, "enquiryDate"]}
                  >
                    <DatePicker format={"DD-MM-YY"} />
                  </Form.Item>
                </Col>
                <Col style={{ width: "12%" }}>
                  <Form.Item
                    {...field}
                    //label="Mobile"
                    name={[field.name, "companyName"]}
                    fieldKey={[field.fieldKey, "companyName"]}
                    rules={[{ required: true, message: "GR No required" }]}
                  >
                    <Input placeholder="companyName" />
                  </Form.Item>
                </Col>
                <Col style={{ width: "12%" }}>
                  <Form.Item
                    {...field}
                    //label="Alt. Mobile"
                    name={[field.name, "personName"]}
                    fieldKey={[field.fieldKey, "personName"]}
                    rules={[{ required: true, message: "personName Required" }]}
                  >
                    <Input placeholder="personName" />
                  </Form.Item>
                </Col>
                <Col style={{ width: "8%" }}>
                  <Form.Item
                    {...field}
                    //  label="Email"
                    name={[field.name, "phoneNo"]}
                    fieldKey={[field.fieldKey, "phoneNo"]}
                  >
                    <Input min={1} max={10} placeholder="phoneNo" />
                  </Form.Item>
                </Col>
                <Col style={{ width: "10%" }}>
                  <Form.Item
                    {...field}
                    //  label="Email"
                    name={[field.name, "address"]}
                    fieldKey={[field.fieldKey, "address"]}
                  >
                    <Input
                      // addonBefore={
                      //   <MailOutlined className="site-form-item-icon" />
                      // }
                      placeholder="address"
                    />
                  </Form.Item>
                </Col>
                <Col style={{ width: "10%" }}>
                  <Form.Item
                    {...field}
                    name={[field.name, "email"]}
                    fieldKey={[field.fieldKey, "email"]}
                  >
                    <Input
                      // addonBefore={
                      //   <MailOutlined className="site-form-item-icon" />
                      // }
                      placeholder="email"
                    />
                  </Form.Item>
                </Col>
                <Col style={{ width: "7%" }}>
                  <Form.Item
                    {...field}
                    //  label="Email"
                    name={[field.name, "website"]}
                    fieldKey={[field.fieldKey, "website"]}
                  >
                    <Input placeholder="website" />
                  </Form.Item>
                </Col>
                <Col style={{ width: "7%" }}>
                  <Form.Item
                    {...field}
                    //  label="Email"
                    name={[field.name, "services"]}
                    fieldKey={[field.fieldKey, "services"]}
                  >
                    <Input placeholder="services" />
                  </Form.Item>
                </Col>
                <Col style={{ width: "6%" }}>
                  <Form.Item
                    {...field}
                    //  label="Email"
                    name={[field.name, "source"]}
                    fieldKey={[field.fieldKey, "source"]}
                  >
                    <Input placeholder="source" />
                  </Form.Item>
                </Col>
                <Col style={{ width: "7%" }}>
                  <Form.Item
                    {...field}
                    //  label="Email"
                    name={[field.name, "remarks"]}
                    fieldKey={[field.fieldKey, "remarks"]}
                  >
                    <Input placeholder="remarks" />
                  </Form.Item>
                </Col>
                <Col style={{ width: "8%" }}>
                  <Form.Item
                    {...field}
                    name={[field.name, "assignedPersonId"]}
                    fieldKey={[field.fieldKey, "assignedPersonId"]}
                    rules={[
                      { required: true, message: "assignedPerson required" },
                    ]}
                  >
                    <Select
                      showSearch
                      // optionFilterProp="children"
                      // filterOption={(input, option) =>
                      //   (option!.children as unknown as string).includes(input)
                      // }
                      // filterSort={(optionA, optionB) =>
                      //   (optionA!.children as unknown as string)
                      //     // .toLowerCase()
                      //     .localeCompare(
                      //       optionB!.children as unknown as string
                      //       //.toLowerCase()
                      //     )
                      // }
                      placeholder="PVT Mark"
                      dropdownRender={(menu) => (
                        <>
                          <CreatePartyModal />
                          {menu}
                        </>
                      )}
                    // onChange={getInstrumentsParameters}
                    >
                      {person &&
                        person?.map((item: any, i: number) => {
                          return (
                            <Select.Option
                              value={item?.id}
                              key={i}
                            // disabled={item.availability === 0 ? true : false}
                            >
                              <Row justify={"space-between"} align={"top"}>
                                <Text strong>{item?.partyId?.personName}</Text>
                              </Row>
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>

                <MinusCircleOutlined onClick={() => remove(field.name)} />
                {/* {isMobile && <Divider />} */}
              </Row>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add More Leads
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateBulkLeadsForm;
