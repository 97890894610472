import * as React from "react";
import {
  Form,
  Button,
  message,
  Select,
  Row,

  Switch,
  Col,
  Input,

} from "antd";
import { request } from "../../../../utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,

} from "@ant-design/icons";
import { queryClient } from "../../../..";
import { ManageBlogCommentModalContext } from "../Modal/ManageBlogComment";

import { useQuery } from "react-query";

import TextArea from "antd/lib/input/TextArea";

import { getBlogCommentById } from "../Requests";
import moment from "moment";


import LocalStorageService from "src/utils/local-storage";

import { getAllBlog } from "../../Blog/Requests";

const ManageBlogCommentForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageBlogCommentModalContext);
  const [formLoading, setFormLoading] = React.useState(false);


  const manageBlogCommentMutation = useMutation(
    (values: any) =>
      request("admin.blogComment.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("BlogComment");
      },
    }
  );
  const {

    data: blogComment,

  } = useQuery(
    ["blogComment", props.id],
    async () => getBlogCommentById(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: blog } = useQuery(["blog"], async () => getAllBlog(), {
    refetchOnWindowFocus: false,
  });

  const onFinish = async (values: any) => {
    // values.fileUrl = galley;
    values.updatedBy = LocalStorageService.getAccount().id;
    setFormLoading(true);

    manageBlogCommentMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The BlogComment has been successfully managed.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();


  blogComment.equiryDate = moment(blogComment.equiryDate, "YYYY-MM-DD");
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={blogComment}
    >
      <Row gutter={15}>
        <Col flex={2} span={12}>
          <Form.Item
            label="Name"
            name="name"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Name",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <Input
              placeholder="Enter Name"
            // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
        <Col flex={2} span={12}>
          <Form.Item
            label="Blog"
            style={{ fontWeight: "bold" }}
            name="blogId"
            tooltip={{
              title: "Blog",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Select
              showSearch
              placeholder="Select Blog"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
            >
              {blog &&
                blog?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.id} key={i}>
                      {item?.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Email"
            name="email"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "email",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <Input
              placeholder="Enter Email"
            // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Comment"
            name="comment"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "comment",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <TextArea
              placeholder="Enter comment"
            // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Utm Source"
            name="utmSource"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "utmSource",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <Input
              placeholder="Enter utmSource"
            // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Utm Medium"
            name="utmMedium"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "utmMedium",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <Input
              placeholder="Enter utmMedium"
            // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col flex={2} span={12}>
          <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
          // rules={[{ required: true }]}
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageBlogCommentForm;
