import { Empty, Row } from "antd";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";
import UserList from "../Component/Home/List";

import { getAccessAuthrorization } from "src/components/access";

const UserPageHome: React.FC<RouteComponentProps> = () => {
  let accessAuth = getAccessAuthrorization("user");

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between">
              <h2>User List</h2>
              {/* <CreateUserModal /> */}
              {/* {accessAuth[0].includes("Create") && <CreateUserModal />} */}
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {/* <UserList /> */}
          {accessAuth[0].includes("View") ? (
            <UserList />
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>You are Not Authorised!</span>}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(UserPageHome);
