import moment from "moment";
import * as React from "react";


import type { ColumnsType } from "antd/lib/table";

import { Table } from "antd";
interface DataType {
  name: any;
}

const AttendanceForm: React.FC = () => {

   console.log(moment().month(5).daysInMonth());

  let abc: any = [];
  for (let i: any = 0; i <= moment().month(0).daysInMonth(); i++) {
    console.log(i);
    abc.push({
      title: i,
      dataIndex: i,
      key: i,
    });
  }
  abc[0] = {
    title: "Employee",
    dataIndex: "name",
    key: "name",
  };
  console.log(abc);
  const column: ColumnsType<DataType> = abc;

  const data: DataType[] = [{ name: "manish" }];

  return <Table size="small" columns={column} dataSource={data} />;
};

export default AttendanceForm;
