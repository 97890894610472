import * as React from "react";
import {
  Form,
  Input,
  
  Button,
  message,
  Switch,

  Col,
  Row,
  Checkbox,
  Upload,
  
} from "antd";
import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  BankOutlined,
  HomeOutlined,
  MobileOutlined,
  GlobalOutlined,
  MailOutlined,
  AuditOutlined,
  UserOutlined,

  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../../../src";
import { CreatePartyModalContext } from "../Modal/CreateParty";
import LocalStorageService from "../../../utils/local-storage";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { uploadFileToAWS } from "../../../utils/s3Config/s3upload";
import { InputRules } from "src/utils/InputRules";


const CreatePartyForm: React.FC = () => {
  const modalContext = React.useContext(CreatePartyModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [galley, setGallery] = React.useState<string>();

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();

  const createPartyMutation = useMutation(
    (values: any) => request("admin.party.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("party");
      },
    }
  );

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    setFormLoading(true);
    values.createdBy = LocalStorageService.getAccount().id;
    // const response = await uploadFileToAWS(selectedFile, "Profile");
    values.imageUrl = galley;

    createPartyMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The party has been successfully created.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const options = [
    { label: "Vendor", value: "Vendor" },
    { label: "Client", value: "Client" },
    { label: "Lead Provider", value: "Lead Provider" },
  ];
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Row align="middle" justify="space-between">
        <Form.Item
          label="Type"
          style={{ fontWeight: "bold" }}
          name="partyType"
          valuePropName="checked"
        >
          <Checkbox.Group options={options} />
        </Form.Item>
      </Row>
      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Company Name"
            name="companyName"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Company Name",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              // {
              //   required: true,
              //   message: "Please enter your Comapany name",
              // },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input
              addonBefore={<BankOutlined className="site-form-item-icon" />}
              maxLength={50}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Upload Image"
            style={{ fontWeight: "bold" }}
            name="imageUrl"
            valuePropName="checked"
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              accept="image/png, image/jpeg"
              action={async (file) => {
                const response: any = await uploadFileToAWS(file, "party");
                setGallery(response.key);
                return response.key;
              }}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={5} span={12}>
          <Form.Item
            label="Person Name"
            name="personName"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Person Name",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter your name",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input
              addonBefore={<UserOutlined className="site-form-item-icon" />}
              maxLength={30}
            />
          </Form.Item>
        </Col>
        <Col flex={4} span={12}>
          <Form.Item
            label="Phone No"
            name="phoneNo"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Official Phone no",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter your Phone Number",
              },
              { whitespace: true },

              ...InputRules.phoneNo,
            ]}
          >
            <Input
              addonBefore="+91-"
              addonAfter={<MobileOutlined />}
              maxLength={10}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={4}>
          <Form.Item
            label="Address"
            style={{ fontWeight: "bold" }}
            name="address"
            tooltip={{
              title: "address",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter your Comapany name",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
          >
            <Input
              addonBefore={<HomeOutlined className="site-form-item-icon" />}
              maxLength={100}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={2} span={12}>
          <Form.Item
            label="Website"
            style={{ fontWeight: "bold" }}
            name="website"
            tooltip={{
              title: "Website e.g www.example.com",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ type: "url", message: "Please enter a valid url" }]}
            hasFeedback
          >
            <Input
              addonBefore={<GlobalOutlined className="site-form-item-icon" />}
              maxLength={50}
            />
          </Form.Item>
        </Col>
        <Col flex={2} span={12}>
          <Form.Item
            label="Email"
            style={{ fontWeight: "bold" }}
            name="email"
            tooltip={{
              title: "email",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              // {
              //   required: true,
              //   message: "Please enter your email",
              // },
              { type: "email", message: "Please enter a valid email" },
            ]}
            hasFeedback
          >
            <Input
              addonBefore={<MailOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={2} span={12}>
          <Form.Item
            label="GST No."
            style={{ fontWeight: "bold" }}
            name="gstInNo"
            tooltip={{
              title: "GSTIN No",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              // {
              //   required: true,
              //   message: "Please enter your Comapany name",
              // },
              { whitespace: true },
              { min: 15 },
            ]}
          >
            <Input
              addonBefore={<AuditOutlined className="site-form-item-icon" />}
              maxLength={15}
            />
          </Form.Item>
        </Col>
        <Col flex={2} span={12}>
          <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
            // rules={[{ required: true }]}
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <Divider />
      <h3>Social Media Link</h3>
      <Divider />
      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Facebook Url"
            style={{ fontWeight: "bold" }}
            name="facebookUrl"
            tooltip={{
              title: "Facebook Url",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[{ required: true }]}
          >
            <Input
              addonBefore={<AuditOutlined className="site-form-item-icon" />}
              maxLength={20}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Twitter Url"
            style={{ fontWeight: "bold" }}
            name="twitterUrl"
            tooltip={{
              title: "Twitter Url",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[{ required: true }]}
          >
            <Input
              addonBefore={<AuditOutlined className="site-form-item-icon" />}
              maxLength={20}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Instagram Url"
            style={{ fontWeight: "bold" }}
            name="instagramUrl"
            tooltip={{
              title: "Instagram Url",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[{ required: true }]}
          >
            <Input
              addonBefore={<AuditOutlined className="site-form-item-icon" />}
              maxLength={20}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Pinterest Url"
            style={{ fontWeight: "bold" }}
            name="pinterestUrl"
            tooltip={{
              title: "Pinterest Url",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[{ required: true }]}
          >
            <Input
              addonBefore={<AuditOutlined className="site-form-item-icon" />}
              maxLength={20}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Youtube Url"
            style={{ fontWeight: "bold" }}
            name="youtubeUrl"
            tooltip={{
              title: "Youtube Url",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[{ required: true }]}
          >
            <Input
              addonBefore={<AuditOutlined className="site-form-item-icon" />}
              maxLength={20}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="LinkedInUrl"
            style={{ fontWeight: "bold" }}
            name="linkedInUrl"
            tooltip={{
              title: "LinkedIn Url",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[{ required: true }]}
          >
            <Input
              addonBefore={<AuditOutlined className="site-form-item-icon" />}
              maxLength={20}
            />
          </Form.Item>
        </Col>
      </Row> */}

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreatePartyForm;
