import * as React from "react";
import {
  Row,
  Col,
  Space,
  Typography,
  Button,
} from "antd";
import { useQuery } from "react-query";
import { squareLogo } from "../../../constants/logo";
import parse from "html-react-parser";

import {
  PhoneFilled,
  MailFilled,
  GlobalOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import moment from "moment";

import { useHistory } from "react-router-dom";
import { getOneQuotationWithDetail } from "../Requests";
import ReactToPrint from "react-to-print";

const ShowQuotationDetailsForm: React.FC<{ id: string }> = (props) => {

  const { data: quotation } = useQuery(
    ["quotationById", props.id],
    async () => getOneQuotationWithDetail(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );
  console.log(quotation);
  const componentRef = React.useRef(null);

  return (
    <>
      <ReactToPrint
  trigger={() => <Button color="primary">Print Quotation</Button>}
  content={() => componentRef.current}
/>
      <div ref={componentRef}>
        <Row style={{ margin: 20 }} justify={"space-between"}>
          <Col>
            <Space.Compact direction="vertical">
              <Typography.Title level={2}>
                INFINITMINDS DIGITAL LAB
              </Typography.Title>
              <Typography.Text>
                <PhoneFilled /> +91-9334680992
              </Typography.Text>
              <Typography.Text>
                <MailFilled /> info@infinitmindsdigital.com
              </Typography.Text>
              <Typography.Text>
                <GlobalOutlined /> www.infinitmindsdigital.com
              </Typography.Text>
              <Typography.Text>
                {" "}
                <ReconciliationOutlined /> 10AAHCI3234J1ZX
              </Typography.Text>
            </Space.Compact>
          </Col>
          <Col>
            <img src={squareLogo} alt="IM" height={175} width={175} />
          </Col>
        </Row>
        <Row justify={"center"}>
          <Typography.Text underline strong style={{ fontSize: 24 }}>
            Quotation/Proposal
          </Typography.Text>
        </Row>
        <Row justify={"space-between"} style={{ margin: 20 }}>
          <Space.Compact direction="vertical">
            <Typography.Text strong>Proposal Date</Typography.Text>
            <Typography.Text>
              {moment(quotation.date).format("DD-MMM-YYYY")}
            </Typography.Text>
          </Space.Compact>
          <Space.Compact direction="vertical">
            <Typography.Text strong>Proposal No</Typography.Text>
            <Typography.Text>IM/Q/{quotation.seq}</Typography.Text>
          </Space.Compact>
        </Row>
        <Row justify={"space-between"} style={{ margin: 20 }}>
          <Space.Compact direction="vertical">
            <Typography.Text strong underline>
              Client Info
            </Typography.Text>
            <Typography.Text>
              <Typography.Text strong>Kind Attention:-</Typography.Text>{" "}
              {quotation?.attention?.name}
            </Typography.Text>
            <Typography.Text>
              <Typography.Text strong>Mobile:-</Typography.Text>{" "}
              {quotation?.attention?.mobile}
            </Typography.Text>
          </Space.Compact>
          <Space.Compact direction="vertical" style={{ width: 250 }}>
            <Typography.Text strong>
              {quotation?.partyId?.companyName}
            </Typography.Text>
            <Typography.Text strong>
              {quotation?.partyId?.address}
            </Typography.Text>
            <Typography.Text>
              <Typography.Text strong>GST No:</Typography.Text>
              {quotation?.partyId?.gstInNo}
            </Typography.Text>
          </Space.Compact>
        </Row>
        <Row style={{ margin: 20 }} >{quotation?.description && parse(quotation.description)}</Row>
        <Row style={{ margin: 20 }}>
          <Typography.Text >
              <Typography.Text strong>Terms & Condition:-</Typography.Text>{" "}
               {quotation?.terms}
            </Typography.Text>
        </Row>
     
        <Row style={{ margin: 20 }}>
          <table
            // rules="all"
            style={{ width: "100%", borderStyle: "solid", padding: 10 }}
          >
            <tr>
              <td style={{ width: "75%" }}>
                <Space.Compact direction="vertical">
                  <Typography.Text strong underline>
                    Bank Details
                  </Typography.Text>

                  <Typography.Text>
                    <Typography.Text strong>Banking Name:</Typography.Text>
                    INFINITMINDS DIGITAL LAB PRIVATE LIMITED
                  </Typography.Text>
                  <Typography.Text>
                    <Typography.Text strong>Account No: </Typography.Text>
                    923020048751470
                  </Typography.Text>

                  <Typography.Text>
                    <Typography.Text strong>IFSC/RTGS/NEFT Code: </Typography.Text>
                    UTIB0001664
                  </Typography.Text>

                  <Typography.Text>
                    <Typography.Text strong>MICR Code:</Typography.Text>
                    800211008
                  </Typography.Text>

                  <Typography.Text>
                    <Typography.Text strong>SWIFT Code:</Typography.Text>
                   UTIB0001664
                  </Typography.Text>
                </Space.Compact>
              </td>
              <td style={{ verticalAlign: "bottom" }}>
                <Typography.Text strong>Authorised Signatory</Typography.Text>
              </td>
            </tr>
          </table>
          <table
            // rules="all"
            style={{
              width: "100%",
              borderStyle: "solid",
              padding: 10,
              borderCollapse: "collapse",
            }}
          >
            <tr>
              <Row justify={"start"}>
                <Typography.Text strong style={{ fontSize: 11 }}>
                  * Computer Generated
                </Typography.Text>
                <Typography.Text style={{ fontSize: 11 }}>
                  In case of payment through RTGS / NEFT / Crediting Direct in
                  <Typography.Text strong italic style={{ fontSize: 11 }}>
                    {" "}
                    INFINITMINDS DIGITAL LAB PRIVATE LIMITED
                  </Typography.Text>{" "}
                  Account, you are requested to inform such deposit/transfer
                  within 24 hours, to enable us to credit your account. In case
                  the information is not received to us well in time the
                  transfer so made by you, you will be kept unallocated and will
                  not credit to your certificate/report.
                </Typography.Text>
              </Row>
            </tr>
          </table>
          <table
            // rules="all"
            style={{
              width: "100%",
              borderStyle: "solid",
              padding: 10,
              borderCollapse: "collapse",
            }}
          >
            <tr style={{ textAlign: "center" }}>
              <Row justify={"center"}>
                <Typography.Text style={{ fontSize: 12 }}>
                  <Typography.Text strong style={{ fontSize: 12 }}>
                    {" "}
                    Redg Office:
                  </Typography.Text>
                  Ground Floor, M-Shivpuri, Ward No- 12, Beur Road, Near Karthik
                  Paradise Apartment, P.S-Gardanibagh, P.O- Anisabad,
                  Patna-800002, Bihar
                </Typography.Text>
              </Row>
            </tr>
          </table>
        </Row>
      </div>
      {/* <Link to="/quotationpdf">"a button inside"</Link> */}
      {/* <Button
        className="no-print"
        type="primary"
        onClick={() => push(`/quotationpdf/${props.id}`)}
        // onClick={() => window.open("/quotationpdf")}
      >
        Print{" "}
      </Button> */}
    </>
  );
};

export default ShowQuotationDetailsForm;
