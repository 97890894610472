import * as React from "react";

import { Modal, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";


import CreateWorkFollowupForm from "../Form/CreateWorkFollowupForm";

export const CreateWorkFollowupModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const CreateWorkFollowupModal: React.FC<{ workId: string }> = ({ workId }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <CreateWorkFollowupModalContext.Provider value={{ setIsModalVisible }}>
      <Button
        onClick={showModal}
        size="small"
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
        style={{
          fontSize: 12,
          backgroundColor: "darkcyan",
        }}
      >
        Add Followup
      </Button>
      <Modal
        title="Create Followup"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        centered
      >
        <CreateWorkFollowupForm workId={workId} />
      </Modal>
    </CreateWorkFollowupModalContext.Provider>
  );
};

export default CreateWorkFollowupModal;
