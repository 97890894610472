
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Space, Table, Tag, } from "antd";

import { getAllCandidate } from "../Requests";

import CandidateViewModal from "../Modal/ShowCandidateDetails";
import ManageCandidateModal from "../Modal/ManageCandidate";

import { getAccessAuthrorization } from "src/components/access";


interface DataType {
  id: number;
  name: any;
}

const CandidateList: React.FC = () => {

  let accessAuth = getAccessAuthrorization("candidate");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["candidate"], () => getAllCandidate(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  // const { data: candidateCategory } = useQuery(
  //   ["candidateCategory"],
  //   async () => getAllcandidateCategories(),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (_: any, text: any) => (
        <Space>
          <Tag>{text.firstName}</Tag>
          <Tag>{text.lastName}</Tag>
        </Space>
      ),
    },
    {
      title: "Phone No",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Job",
      dataIndex: "jobId",
      key: "jobId",
      render: (text: any) => <p>{text.title}</p>,
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <CandidateViewModal id={id} />
          {/* <ManageCandidateModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <ManageCandidateModal id={id} />}
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default CandidateList;
