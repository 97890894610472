import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Upload,
  Switch,

  Select,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { queryClient } from "src";

import LocalStorageService from "src/utils/local-storage";
import { CreateCaseStudyModalContext } from "../Modal/CreateCaseStudy";
import { getAllActiveWork, getWorkById } from "src/modules/Work/Requests";
import { tinyMCE } from "src/utils/keys";
import { Editor } from "@tinymce/tinymce-react";
import { deleteFileToAWS, uploadFileToAWS } from "src/utils/s3Config/s3upload";

const CreateCaseStudy: React.FC<{ id?: string }> = (props) => {
  const modalContext = React.useContext(CreateCaseStudyModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [workData, setWorkData] = React.useState<Object | any>({});
  const [androidUrl, setAndroidUrl] = React.useState<string[]>([]);
  const [pannelUrl, setPannelUrl] = React.useState<string[]>([]);
  const [webUrl, setWebUrl] = React.useState<string[]>([]);

  const [remarks, setRemarks] = React.useState<string>();
  const [editorkey, seteditorkey] = React.useState(4);

  const createCaseStudyMutation = useMutation(
    (values: any) => request("admin.caseStudy.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("caseStudy");
      },
    }
  );
  const { data: work } = useQuery(["work"], async () => getAllActiveWork(), {
    refetchOnWindowFocus: false,
  });

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    values.description = remarks;

    setFormLoading(true);

    createCaseStudyMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The CaseStudy has been successfully created.");
        form.resetFields();
        cleareditor();

        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const mutateWork = useMutation((id: string) => getWorkById(id));

  const OnWorkSelect = (i: any) => {
    // console.log(i);

    mutateWork
      .mutateAsync(i)
      .then((payload) => {
        setWorkData(payload);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  React.useEffect(() => {
    if (props.id) {
      form.setFieldsValue({ workId: props.id });
      OnWorkSelect(props.id);
    }
  }, []);
  const handleEditorChange = (content: any, editor: any) => {
    // console.log("Content was updated:", content);
    setRemarks(content);
  };
  const cleareditor = () => {
    const newkey = editorkey * 43;
    seteditorkey(newkey);
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Form.Item
        label="Project/Product"
        name="workId"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "work",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Project/Product",
          },
        ]}
        hasFeedback
      >
        <Select
          onSelect={OnWorkSelect}
          showSearch
          popupMatchSelectWidth
          // style={{ minHeight: 150 }}
          placeholder="Select Work"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
        >
          {work &&
            work?.map((item: any, i: number) => {
              return (
                <Select.Option value={item?.id} key={i}>
                  {item?.title}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Editor
        key={editorkey}
        apiKey={tinyMCE.apiKey}
        // onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          plugins: tinyMCE.plugins,
          toolbar: tinyMCE.toolbar,
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          // mergetags_list: [
          //   { value: "First.Name", title: "First Name" },
          //   { value: "Email", title: "Email" },
          // ],

          paste_data_images: true,

          ai_request: (request: any, respondWith: any) =>
            respondWith.string(() =>
              Promise.reject("See docs to implement AI Assistant")
            ),
        }}
        // initialValue="Welcome to TinyMCE!"
        onEditorChange={handleEditorChange}
      // outputFormat="html"
      />
      <Row justify={"space-between"}>
        <Form.Item
          label="Android View Image"
          style={{ fontWeight: "bold" }}
          name="androidViewPicUrl"
          valuePropName="checked"
        >
          <Upload
            accept="application/pdf,application/msword, image/png, image/jpeg, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            maxCount={4}
            multiple
            listType="picture"
            action={async (file) => {
              const response: any = await uploadFileToAWS(
                file,
                "caseStudy android image"
              ).catch((err: any) => message.error(err?.toString()));
              setAndroidUrl([...androidUrl, response.key]);
              return response.key;
            }}
            beforeUpload={(fileList) => {
              console.log("FILE", fileList.size);
              return fileList.size / 1024 / 1024 <= 2 ? true : false;
            }}
            onRemove={(e) => {
              if (e?.error?.url) {
                deleteFileToAWS(e.error.url).then((value) =>
                  message.success(value)
                );
                var file = androidUrl.filter((item) => item !== e.error.url);
                setAndroidUrl(file);
              }
            }}
          >
            {androidUrl.length < 4 && (
              <Button icon={<UploadOutlined />}>Upload android Image</Button>
            )}
          </Upload>
        </Form.Item>
      </Row>
      <Row justify={"space-between"}>
        <Form.Item
          label="Pannel Image"
          style={{ fontWeight: "bold" }}
          name="pannelViewPicUrl"
          valuePropName="checked"
        >
          <Upload
            accept="application/pdf,application/msword, image/png, image/jpeg, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            maxCount={4}
            multiple
            listType="picture"
            action={async (file) => {
              const response: any = await uploadFileToAWS(
                file,
                "caseStudy pannel image"
              ).catch((err: any) => message.error(err?.toString()));
              setPannelUrl([...pannelUrl, response.key]);
              return response.key;
            }}
            beforeUpload={(fileList) => {
              console.log("FILE", fileList.size);
              return fileList.size / 1024 / 1024 <= 2 ? true : false;
            }}
            onRemove={(e) => {
              if (e?.error?.url) {
                deleteFileToAWS(e.error.url).then((value) =>
                  message.success(value)
                );
                var file = pannelUrl.filter((item) => item !== e.error.url);
                setPannelUrl(file);
              }
            }}
          >
            {pannelUrl.length < 4 && (
              <Button icon={<UploadOutlined />}>Upload Pannel Image</Button>
            )}
          </Upload>
        </Form.Item>
      </Row>
      <Row justify={"space-between"}>
        <Form.Item
          label="Website Image"
          style={{ fontWeight: "bold" }}
          name="websiteViewPicUrl"
          valuePropName="checked"
        >
          <Upload
            accept="application/pdf,application/msword, image/png, image/jpeg, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            maxCount={4}
            multiple
            listType="picture"
            action={async (file) => {
              const response: any = await uploadFileToAWS(
                file,
                "caseStudy website image"
              ).catch((err: any) => message.error(err?.toString()));
              setWebUrl([...webUrl, response.key]);
              return response.key;
            }}
            beforeUpload={(fileList) => {
              console.log("FILE", fileList.size);
              return fileList.size / 1024 / 1024 <= 2 ? true : false;
            }}
            onRemove={(e) => {
              if (e?.error?.url) {
                deleteFileToAWS(e.error.url).then((value) =>
                  message.success(value)
                );
                var file = webUrl.filter((item) => item !== e.error.url);
                setWebUrl(file);
              }
            }}
          >
            {webUrl.length < 4 && (
              <Button icon={<UploadOutlined />}>Upload Website Image</Button>
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="Status"
          style={{ fontWeight: "bold" }}
          name="isActive"
          tooltip={{
            title: "status",
            icon: <InfoCircleOutlined />,
          }}
          valuePropName="checked"
        // rules={[{ required: true }]}
        >
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            defaultChecked
          />
        </Form.Item>
      </Row>
      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateCaseStudy;
