import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "src/components/Card";

import { Empty } from "antd";

import StoryList from "../Component/List";
import { getAccessAuthrorization } from "src/components/access";

// const onChange = (key: string) => {
//   console.log(key);
// };
const StoryPageHome: React.FC<RouteComponentProps> = () => {
  let accessAuth = getAccessAuthrorization("story");

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            {/* <Row justify="space-between">
              <h2>Story List</h2> */}
            {/* <CreateStoryModal /> */}
            {/* {accessAuth[0].includes("Create") && <CreateStoryModal />}
            </Row> */}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {/* <StoryList /> */}
          {accessAuth[0].includes("View") ? (
            <StoryList />
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>You are Not Authorised!</span>}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(StoryPageHome);
