import * as React from "react";
import {
  Form,
  Input,
  Button,
  message,

  Select,
  Row,

  Col,

  Card,
} from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  UserOutlined,
  KeyOutlined,

  EditOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import { ManageUserDetailsModalContext } from "../Modal/ManageUserDetails";
import { useQuery } from "react-query";

import LocalStorageService from "src/utils/local-storage";

//import { getUserByIFSC } from "src/modules/Company/Requests";
import { getPartyById } from "../Requests";

import { getUserByPartyId } from "src/modules/User/Requests";
import { getAllRoles } from "src/modules/Roles/Requests";

const ManageUserDetailsForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageUserDetailsModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [pwdDisabled, setPwdDisabled] = React.useState(true);
  const [form] = Form.useForm();
  const {
    //isLoading,
    //isError,
    data: roles,
  } = useQuery(["roles"], () => getAllRoles(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  // get company details
  const {

    data: userDetails,

  } = useQuery(["user", props.id], async () => getUserByPartyId(props.id), {
    refetchOnWindowFocus: false,
    // enabled: false,
  });
  const {
    // isLoading,
    data: party,
    //error,
    //isError,
  } = useQuery(["party", props.id], async () => getPartyById(props.id), {
    refetchOnWindowFocus: false,
    // enabled: false,
  });
  const ManageUserDetailsMutation = useMutation(
    (values: any) =>
      userDetails.length > 0
        ? request("admin.user.update", { id: userDetails[0].id }, values)
        : request("admin.user.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("userDetails");
      },
    }
  );
  console.log(party);

  const onFinish = async (values: any) => {
    values.updatedBy = LocalStorageService.getAccount().id;
    values.createdBy = LocalStorageService.getAccount().id;
    setFormLoading(true);

    values.partyId = props.id;
    values.name = party?.personName;
    values.mobile = party?.phoneNo;
    values.email = party?.email;
    console.log(values);

    ManageUserDetailsMutation.mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The userDetails has been successfully Managed.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={userDetails[0]}
    >
      <Card title="User Details">
        <Row gutter={10}>
          <Col span={8}>
            <Form.Item
              label="User Name"
              style={{ fontWeight: "bold" }}
              name="username"
              tooltip={{
                title: "username",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ required: true }]}
            >
              <Input
                addonBefore={<UserOutlined />}
                disabled={userDetails[0]?.username ? true : false}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Password"
              style={{ fontWeight: "bold" }}
              name={pwdDisabled ? "" : "password"}
              tooltip={{
                title: "password",
                icon: <InfoCircleOutlined />,
              }}
            //rules={[{ required: true }]}
            >
              <Input
                type="password"
                addonBefore={<KeyOutlined />}
                addonAfter={
                  <Button
                    onClick={() => setPwdDisabled(!pwdDisabled)}
                    type="text"
                    // shape="round"
                    icon={<EditOutlined />}
                  />
                }
                disabled={pwdDisabled}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Roles"
              style={{ fontWeight: "bold" }}
              name="roles"
              tooltip={{
                title: "roles",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ required: true }]}
            >
              <Select
                // mode="multiple"
                //onChange={getSubCategory}
                showSearch
                placeholder="Select Role"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string).includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA!.children as unknown as string)
                    .toLowerCase()
                    .localeCompare(
                      (optionB!.children as unknown as string).toLowerCase()
                    )
                }
              >
                {roles &&
                  roles?.map((item: any, i: number) => {
                    return (
                      <Select.Option value={item?.id} key={i}>
                        {item?.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Update UserDetails
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageUserDetailsForm;
