import * as React from "react";
import {
  Form,
  Button,
  message,

  Switch,
  Input,

} from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,

} from "@ant-design/icons";
import { queryClient } from "src";
import { ManageTaskTemplateModalContext } from "../Modal/ManageTaskTemplate";

import { useQuery } from "react-query";

import { getTaskTemplateById } from "../Requests";

import LocalStorageService from "src/utils/local-storage";


import { Editor } from "@tinymce/tinymce-react";
import { tinyMCE } from "src/utils/keys";

// type Color = Exclude<GetProp<ColorPickerProps, "value">, string>;

const ManageTaskTemplateForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageTaskTemplateModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const [editorkey, seteditorkey] = React.useState(4);
  const [remarks, setRemarks] = React.useState<string>();

  const manageTaskTemplateMutation = useMutation(
    (values: any) =>
      request("admin.taskTemplate.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("taskTemplate");
      },
    }
  );
  const {

    data: taskTemplate,

  } = useQuery(
    ["taskTemplate", props.id],
    async () => getTaskTemplateById(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );


  const onFinish = async (values: any) => {
    values.updatedBy = LocalStorageService.getAccount().id;
    values.template = remarks;


    setFormLoading(true);

    manageTaskTemplateMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The TaskTemplate has been successfully managed.");
        form.resetFields();
        cleareditor();

        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const handleEditorChange = (content: any, editor: any) => {
    // console.log("Content was updated:", content);
    setRemarks(content);
  };

  const cleareditor = () => {
    const newkey = editorkey * 43;
    seteditorkey(newkey);
  };
  const [form] = Form.useForm();


  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={taskTemplate}
      form={form}
    >
      <Form.Item
        label="Title"
        name="title"
        style={{
          fontWeight: "bold",
        }}
        tooltip={{
          title: "title",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter title",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <Input placeholder="Enter Title" />
      </Form.Item>
      <Editor
        initialValue={taskTemplate.template}
        key={editorkey}
        apiKey={tinyMCE.apiKey}
        // onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          plugins: tinyMCE.plugins,
          toolbar: tinyMCE.toolbar,
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          // mergetags_list: [
          //   { value: "First.Name", title: "First Name" },
          //   { value: "Email", title: "Email" },
          // ],

          paste_data_images: true,

          ai_request: (request: any, respondWith: any) =>
            respondWith.string(() =>
              Promise.reject("See docs to implement AI Assistant")
            ),
        }}
        // initialValue="Welcome to TinyMCE!"
        onEditorChange={handleEditorChange}
      // outputFormat="html"
      />

      <Form.Item
        label="Status"
        style={{ fontWeight: "bold" }}
        name="isActive"
        tooltip={{
          title: "status",
          icon: <InfoCircleOutlined />,
        }}
        valuePropName="checked"
      // rules={[{ required: true }]}
      >
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageTaskTemplateForm;
