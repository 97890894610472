import {
  Avatar,
  Result,
  Tag,
  Image,
  Empty,
  Tabs,
  Row,
  Col,
  Popover,
} from "antd";

import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import PartyManageModal from "../../Modal/ManageParty";

import { Button,  Space,  Table, Typography } from "antd";
import PartyViewModal from "../../Modal/ShowPartyDetails";

import { getAllParty,  } from "../../Requests";
import { s3Path } from "../../../../utils/s3Config/s3upload";

import ManageBankDetailsModal from "../../Modal/ManageBankDetails";
import ManageUserDetailsModal from "../../Modal/ManageUserDetails";
import LocalStorageService from "src/utils/local-storage";
import { getAccessAuthrorization } from "src/components/access";
import { isMobile } from "react-device-detect";
import { CapsuleTabs,  List } from "antd-mobile";



import WhatsappMessage from "src/components/whatsapp";
import PhoneCall from "src/components/call";
import CreateConcernedPersonModal from "../../Modal/CreateConcernedPerson";

const { Text } = Typography;
interface DataType {
  id: number;
  name: string;
  person: string;
  phone: number;
  status: any;
}


const PartyList: React.FC = () => {

  let accessAuth = getAccessAuthrorization("party");
  const {
    isLoading,
    isError,
    data: party,
  } = useQuery(["party"], () => getAllParty(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }

  const column: ColumnsType<DataType> = [
    {
      title: "Profile/Logo ",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (text: any) =>
        text ? (
          <Image width={100} height={100} src={s3Path + text} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ),
    },
    {
      title: "Name",
      dataIndex: "companyName",
      key: "companyName",
      width: 200,
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Text
              style={{
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              {i?.personName}
              <Tag color="magenta">{i?.partyType[0]}</Tag>
            </Text>
            <Text
              style={{
                fontSize: 13,
              }}
              code
            >
              {i?.companyName}
            </Text>
          </Space.Compact>
        </Space>
      ),
    },
    {
      title: "Contacts",
      dataIndex: "personName",
      key: "personName",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Row>
              <Text type="success" code>
                +91-{i?.phoneNo}
              </Text>
              <WhatsappMessage
                phoneNumber={i?.phoneNo}
                message={`Hey  ${i?.personName}!`}
              />
            </Row>
            <Text type="warning" code>
              {i?.email}
            </Text>
            <Text type="danger" code>
              {i?.website}
            </Text>
          </Space.Compact>
        </Space>
      ),
    },

    {
      title: "Concerned Person",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <CreateConcernedPersonModal partyId={id} />
        </Space>
      ),
    },
    {
      title: "Other Details",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <Space>
          <Space.Compact direction="vertical">
            <ManageBankDetailsModal id={id} />
            <ManageUserDetailsModal id={id} />
          </Space.Compact>
        </Space>
      ),
    },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <PartyViewModal id={id} />
          {/* <PartyManageModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <PartyManageModal id={id} />}
        </Space>
      ),
    },
  ];

  let clientList = party.filter(
    (element: any) => element.partyType[0] === "Client"
  );
  let vendorList = party.filter(
    (element: any) => element.partyType[0] === "Vendor"
  );
  let leadList = party.filter(
    (element: any) => element.partyType[0] === "Lead Provider"
  );
  let inhouseEmpList = party.filter(
    (element: any) => element.partyType[0] === "Inhouse Employee"
  );
  let outSourceEmpList = party.filter(
    (element: any) => element.partyType[0] === "Outsource Employee"
  );
  let partnerList = party.filter(
    (element: any) => element.partyType[0] === "Partner"
  );
  const capsuleTabItems = [
    {
      label: "Client",
      key: "1",
      children: <MobilePartyList data={clientList} />,
    },
    {
      label: "Vendor",
      key: "2",
      children: <MobilePartyList data={vendorList} />,

      // disabled: true,
    },
    {
      label: "Lead Provider",
      key: "3",
      children: <MobilePartyList data={leadList} />,
    },

    {
      label: "Inhouse Employee",
      key: "4",
      children: <MobilePartyList data={inhouseEmpList} />,
    },
    {
      label: "Outsource Employee",
      key: "5",
      children: <MobilePartyList data={outSourceEmpList} />,
    },
    {
      label: "Partner",
      key: "6",
      children: <MobilePartyList data={partnerList} />,
    },
    {
      label: "All Party",
      key: "7",
      children: <MobilePartyList data={party} />,
    },
  ];
  const items = [
    {
      label: "Client",
      key: "1",
      children: <Table size="small" columns={column} dataSource={clientList} />,
    },
    {
      label: "Vendor",
      key: "2",
      children: <Table size="small" columns={column} dataSource={vendorList} />,

      // disabled: true,
    },
    {
      label: "Lead Provider",
      key: "3",
      children: <Table size="small" columns={column} dataSource={leadList} />,
    },

    {
      label: "Inhouse Employee",
      key: "4",
      children: (
        <Table size="small" columns={column} dataSource={inhouseEmpList} />
      ),
    },
    {
      label: "Outsource Employee",
      key: "5",
      children: (
        <Table size="small" columns={column} dataSource={outSourceEmpList} />
      ),
    },
    {
      label: "Partner",
      key: "6",
      children: (
        <Table size="small" columns={column} dataSource={partnerList} />
      ),
    },
    {
      label: "All Party",
      key: "7",
      children: <Table size="small" columns={column} dataSource={party} />,
    },
  ];

  return (
    <>
      {!isError ? (
        <>
          {!isMobile && <Tabs defaultActiveKey="1" items={items} />}
          {isMobile && (
            <CapsuleTabs defaultActiveKey="1">
              {capsuleTabItems.map((item) => (
                <CapsuleTabs.Tab
                  title={item.label}
                  key={item.key}
                  style={{ padding: 2 }}
                >
                  {item.children}
                </CapsuleTabs.Tab>
              ))}
              {/* {items} */}
              {/* <CapsuleTabs.Tab title={title}>{items}</CapsuleTabs.Tab> */}
            </CapsuleTabs>
          )}
        </>
      ) : (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={<Button type="primary">Back Home</Button>}
        />
      )}
    </>
  );
};

export default PartyList;

const MobilePartyList: React.FC<{ data: any }> = ({ data }) => {
  console.log(data);
  return (
    <>
      {/* {data &&
        data.map((element: any, i: any) => {
          return (
            <>
              <Card>
                <Row>
                  <Avatar size="large" src={s3Path + element.imageUrl} />
                </Row>
              </Card>
            </>
          );

        })} */}
      <List style={{ padding: 0 }}>
        {/* <AutoSizer disableHeight> */}
        {data &&
          data.map((element: any, i: any) => (
            <List.Item
              style={{ padding: 0 }}
              key={element.personName}
              prefix={<Avatar size="large" src={s3Path + element.imageUrl} />}
              description={
                <>
                  <Space.Compact direction="vertical">
                    <Popover
                      content={
                        <Row>
                          <PhoneCall phoneNumber={element.phoneNo} />
                          <WhatsappMessage
                            phoneNumber={element.phoneNo}
                            message={`Hey  ${element?.personName}!`}
                          />
                        </Row>
                      }
                      trigger="click"
                      placement="bottom"
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                        }}
                        type="danger"
                        code
                      >
                        +91-{element?.phoneNo}
                      </Text>
                    </Popover>

                    <Text>{element?.email}</Text>

                    <Text type="secondary">{element?.companyName}</Text>
                  </Space.Compact>
                </>
              }
            >
              <Row justify={"space-between"}>
                <Col span={18}>
                  <Text strong>{element.personName}</Text>
                </Col>
                <Col span={6}>
                  <Space direction="horizontal">
                    <PartyViewModal id={element.id} />
                    <PartyManageModal id={element.id} />
                  </Space>
                </Col>
              </Row>
            </List.Item>
          ))}
        {/* </AutoSizer> */}
      </List>
    </>
  );
};
